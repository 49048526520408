import SidebarLayout from 'components/SidebarLayout'
import {
  GeneratedQuestion,
  QuizQuestion,
  QuizStatus,
} from 'apis/entities/quiz.entity'
import Spinner from 'views/Spinner'
import { useParams } from 'react-router-dom'
import { StyleUtil } from 'utils/StyleUtil'
import { useCallback, useEffect, useState, KeyboardEvent } from 'react'
import { useApi } from 'providers/ApiProvider'
import { Permission, Role, useAuth } from 'providers/AuthProvider'
import React from 'react'
import QuestionEditView from 'views/QuestionEditView'
import WidgetAddReviewers from 'views/WidgetAddReviewers'
import { ToastUtil } from 'utils/ToastUtil'
import ButtonSpinner from 'views/ButtonSpinner'
import { TextareaAutosize } from '@mui/base'
import Path from 'routes/Path'
import { useNavigate } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import WidgetConfirmation from 'views/WidgetConfirmation'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
  storeQuiz,
  updateNameAndDescription,
  updateQuestions,
  updatePositions,
  deleteQuestion,
  addQuestion,
} from 'app/QuizSlice'
import WidgetQuestionGenerator from 'views/WidgetQuestionGenerator'
import Emitter, { Events } from 'core/emitter'

function QuizDetailsPage() {
  const navigate = useNavigate()
  const {
    isLogged,
    getRole,
    permissions,
    auth0User: user,
    getAuth0UserDetails,
  } = useAuth()
  const { quizId } = useParams()
  const {
    getQuiz,
    updateQuiz,
    approveQuiz,
    batchDeleteQuizzes,
    batchRestoreQuizzes,
    addQuizQuestion,
    deleteQuizQuestion,
    generateNewQuestions,
    generateNewQuestionsV2,
    resendEmailToReviewer,
  } = useApi()
  const [isFetched, setIsFetched] = useState(false)
  const refInputDescription = React.createRef<HTMLTextAreaElement>()
  const refInputName = React.createRef<HTMLTextAreaElement>()
  const [showAddReviewersWidget, setShowAddReviewersWidget] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false) // quiz name and description
  const [isApproving, setIsApproving] = useState(false)
  const [isApproved, setIsApproved] = useState(false)
  const [showApprovingConfirmation, setShowApprovingConfirmation] =
    useState(false)
  const [isClickDelete, setIsClickDelete] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isRestoring, setIsRestoring] = useState<boolean>(false)
  const [deletingQuestion, setDeletingQuestion] = useState<QuizQuestion>()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showRegenerateConfirmation, setShowRegenerateConfirmation] =
    useState(false)
  const [isDeletingQuestion, setIsDeletingQuestion] = useState(false)
  const quiz = useAppSelector((state) => state.quiz.value)
  const dispatch = useAppDispatch()
  const [regenerateQuestionId, setRegenerateQuestionId] = useState<
    string | undefined
  >()
  const [showQuestionGeneratorWidget, setShowQuestionGeneratorWidget] =
    useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [resendingEmails, setResendingEmails] = useState<{
    [key: string]: boolean
  }>({})

  const [editTopicData, setEditTopicData] = useState<{
    quizId: string
    question: QuizQuestion
  }>()

  const isDeleted = quiz?.deletedAt !== null

  // get all reviewers with latest quiz version
  const quizReviewers =
    quiz?.reviewers?.filter((reviewer) => reviewer.version === quiz?.version) ||
    []

  const getActionButtonStyle = (loading: boolean) => {
    const style = StyleUtil.buttonPrimary
    if (loading) {
      return `${style} loading disabled`
    }
    return style
  }

  const closeConfirmRegenerateQuestion = () => {
    setRegenerateQuestionId(undefined)
    setShowRegenerateConfirmation(false)
  }

  const onConfirmRegenerateQuestion = async () => {
    closeConfirmRegenerateQuestion()
    Emitter.emit(Events.RegenerateQuestion, {
      questionId: regenerateQuestionId,
    })
  }

  const onClickDelete = () => {
    setIsClickDelete(true)
  }

  const onConfirmDeleteQuiz = async () => {
    if (!quiz) return
    setIsDeleting(true)
    await batchDeleteQuizzes([quiz.id])
    setIsDeleting(false)
    ToastUtil.success(`Deleted successfully`)
    setIsClickDelete(false)
    // refresh
    await fetchData()
  }

  const onClickRestore = async () => {
    if (!quiz) return
    setIsRestoring(true)
    await batchRestoreQuizzes([quiz.id])
    setIsRestoring(false)
    ToastUtil.success(`Restored successfully`)
    // refresh
    await fetchData()
  }

  const hasPermission = useCallback(
    (permission: string, createdAuth0Id?: string): boolean => {
      // https://horizonsfl.atlassian.net/browse/HLP-1465
      if (getRole() === Role.SuperAdmin || getRole() === Role.Admin) return true
      if (
        permissions &&
        permissions.includes(permission) &&
        user?.sub === createdAuth0Id
      ) {
        // same user
        return true
      }
      // Author can only edit
      if (permissions && permissions.includes(permission)) {
        return true
      }
      return false
    },
    [getRole, permissions, user?.sub],
  )

  const hasPermissionForApproval = (auth0Id: string): boolean => {
    // https://horizonsfl.atlassian.net/browse/HLP-1669
    if (!isLogged) return false
    if (!quiz) return false
    if (quiz.status === QuizStatus.Draft) return false
    if (getRole() === Role.Author && quiz.status !== QuizStatus.Reviewed)
      return false
    // super admin and admin can approve
    if (getRole() === Role.SuperAdmin || getRole() === Role.Admin) return true
    // same user
    // console.log('user?.sub', user?.sub, 'auth0Id', auth0Id)
    if (user?.sub === auth0Id) {
      return true
    }
    return false
  }

  const isLocked = isApproved || quiz?.status === QuizStatus.Sent || isDeleted

  const fetchData = useCallback(async () => {
    if (!isLogged) return
    if (!quizId) return
    setIsFetched(false)
    try {
      const quiz = await getQuiz(quizId)
      // sort quiz positions which is question ids in ascending order
      const positions = quiz.positions
      if (positions) {
        quiz.questions.sort(
          (a, b) => positions.indexOf(a.id) - positions.indexOf(b.id),
        )
      }
      // after sorting, store the quiz
      dispatch(storeQuiz({ quiz }))

      const quizName = quiz.name.trim()
      if (quizName === '') {
        setIsEditMode(true)
      }

      setIsApproved(quiz.approved)
    } catch (error) {
      console.log(error)
    }
    setIsFetched(true)
  }, [dispatch, getQuiz, isLogged, quizId])

  const onClickApprove = useCallback(async () => {
    setShowApprovingConfirmation(true)
  }, [])

  const updateQuizWithUser = useCallback(
    async (
      quizId: string,
      name?: string,
      description?: string,
      position?: string[],
    ) => {
      const user = getAuth0UserDetails()
      const auth0Id = user?.id
      const username = user?.username
      const firstName = user?.firstName
      const email = user?.email
      await updateQuiz(
        quizId,
        name,
        description,
        position,
        auth0Id,
        username,
        firstName,
        email,
      )
    },
    [getAuth0UserDetails, updateQuiz],
  )

  const onClickUnapprove = useCallback(async () => {
    if (!quizId) return
    setIsApproving(true)
    try {
      await approveQuiz(quizId, false)
      setIsApproved(false)
      ToastUtil.success('Unapproved successfully')
    } catch (error) {
      console.log(error)
      ToastUtil.error('Failed to unapprove quiz')
    } finally {
      setIsApproving(false)
      setShowApprovingConfirmation(false)
    }
  }, [approveQuiz, quizId])

  const onConfirmApproval = useCallback(async () => {
    // call approve quiz api
    if (!quizId) return
    setIsApproving(true)
    try {
      await approveQuiz(quizId, true)
      setIsApproved(true)
      ToastUtil.success('Approved successfully')
    } catch (error) {
      console.log(error)
      ToastUtil.error('Failed to approve quiz')
    } finally {
      setIsApproving(false)
      setShowApprovingConfirmation(false)
    }
  }, [approveQuiz, quizId])

  const onCancelApproval = useCallback(async () => {
    setShowApprovingConfirmation(false)
  }, [])

  const onClickSendForReview = useCallback(async () => {
    // check quiz name and quiz description
    if (
      !quiz?.name ||
      !quiz?.description ||
      quiz?.name.trim() === '' ||
      quiz?.description.trim() === ''
    ) {
      ToastUtil.warning('Quiz name and description cannot be empty')
      return
    }

    // check no question is empty string
    console.log('onClickSendForReview::storedQuiz.questions', quiz.questions)
    if (
      quiz.questions.some(
        (q) => !q.text || !q.learningOutcome || q.answers?.some((a) => !a),
      )
    ) {
      ToastUtil.warning('One or more questions is incomplete')
      return
    }

    setShowAddReviewersWidget(true)
  }, [quiz])

  // update quiz name and description
  const onClickSave = useCallback(async () => {
    if (!quizId) {
      ToastUtil.error('Quiz not found')
      return
    }
    const previousName = quiz?.name
    const name = refInputName.current?.value

    const previousDescription = quiz?.description
    const description = refInputDescription.current?.value || ''

    // no empty name
    if (!name || name.trim() === '') {
      ToastUtil.warning('Quiz name cannot be empty')
      return
    }

    // no change
    if (previousName === name && previousDescription === description) {
      setIsEditMode(false)
      return
    }

    try {
      setIsLoading(true)
      await updateQuizWithUser(quizId, name, description)
      ToastUtil.success('Quiz is updated')
      dispatch(
        updateNameAndDescription({
          name,
          description,
        }),
      )
      setIsEditMode(false)
    } catch (error) {
      console.log(error)
      ToastUtil.error('Failed to update quiz')
    } finally {
      setIsLoading(false)
    }
  }, [
    dispatch,
    quiz,
    quizId,
    refInputDescription,
    refInputName,
    updateQuizWithUser,
  ])

  const renderQuizStatus = () => {
    if (!quiz) return
    if (isApproved) {
      return <span> / Approved</span>
    }
    if (quiz.deletedAt) {
      return <span className="text-[#000]"> / Deleted</span>
    }
    if (quiz.status === QuizStatus.Draft) {
      return <span className="text-[#000]"> / Draft</span>
    }
    if (quiz.status === QuizStatus.Reviewed) {
      return <span> / Reviewed</span>
    }
    if (quiz.status === QuizStatus.Sent) {
      return <span> / Reviewed - {getRejectedSummary()}</span>
    }
    return <></>
  }

  const handleKeyDownForQuiz = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      onClickSave()
    } else if (event.key === 'Escape') {
      setIsEditMode(false)
    }
  }

  const setFocusOnInputName = () => {
    setTimeout(() => {
      const input = document.getElementById(
        'quiz-name-input',
      ) as HTMLTextAreaElement | null
      if (!input) return
      input?.focus()
      // fix for cursor not at the end of the text
      input?.setSelectionRange(input.value.length, input.value.length)
    }, 0)
  }

  const handleOnClickEdit = () => {
    setIsEditMode(true)
    setFocusOnInputName()
  }

  const renderStatusSummary = () => {
    // get total questions
    const total = quiz?.questions.length || 0
    const reviewerCount = quizReviewers.length || 0
    let approved = 0
    let rejected = 0
    if (reviewerCount > 0) {
      quiz?.questions.forEach((question) => {
        // get all comments with same version in the question
        const approvedComments =
          question.comments?.filter(
            (comment) =>
              comment.version === quiz.version && comment.approved === true,
          ) || []
        if (approvedComments.length === reviewerCount) approved++
      })
      rejected = total - approved
    }

    const firstPart = `${approved}/${total} questions approved`
    const secondPart = `${rejected}/${total} questions rejected`
    return `${firstPart}, ${secondPart}`
  }

  const getRejectedSummary = (): string => {
    const total = quiz?.questions.length || 0
    const reviewerCount = quizReviewers.length || 0
    let rejected = 0
    if (reviewerCount > 0) {
      quiz?.questions.forEach((question) => {
        // get all comments with same version as the question
        const rejectedComments =
          question.comments?.filter(
            (comment) =>
              comment.version === quiz.version && comment.approved === false,
          ) || []
        if (rejectedComments.length > 0) rejected++
      })
    }
    return `${rejected}/${total} questions rejected`
  }

  const updateQuestionPositions = async (positions: string[]) => {
    if (!quiz) return
    try {
      // update db
      await updateQuizWithUser(quiz.id, undefined, undefined, positions)
      // update stored quiz
      dispatch(updatePositions({ positions }))
    } catch (error) {
      console.error(error)
      ToastUtil.error('Failed to update question positions')
    }
  }

  const onQuestionUp = async (question: QuizQuestion) => {
    console.log('onQuestionUp', question)
    // swap position with previous question by index
    const currentIndex = quiz.questions.findIndex((q) => q.id === question.id)
    if (currentIndex === 0) {
      console.log('currentIndex is 0')
      return
    }
    const previousQuestion = quiz.questions[currentIndex - 1]
    if (!previousQuestion) {
      console.log('previousQuestion not found')
      return
    }
    // swap the the question and previous question in the questions array, don't use position
    const questions = [...quiz.questions]
    const temp = questions[currentIndex]
    questions[currentIndex] = previousQuestion
    questions[currentIndex - 1] = temp

    // update stored quiz
    dispatch(
      updateQuestions({
        questions,
      }),
    )

    // update db, no need to use await
    const positions = questions.map((q) => q.id)
    updateQuestionPositions(positions)
  }

  const onQuestionDown = async (question: QuizQuestion) => {
    console.log('onQuestionDown', question)
    // swap position with next question by index
    const currentIndex = quiz.questions.findIndex((q) => q.id === question.id)
    if (currentIndex === quiz.questions.length - 1) {
      console.log('currentIndex is last')
      return
    }
    const nextQuestion = quiz.questions[currentIndex + 1]
    if (!nextQuestion) {
      console.log('nextQuestion not found')
      return
    }

    // swap the the question and next question in the questions array, don't use position
    const questions = [...quiz.questions]
    const temp = questions[currentIndex]
    questions[currentIndex] = nextQuestion
    questions[currentIndex + 1] = temp

    // update stored quiz
    dispatch(
      updateQuestions({
        questions,
      }),
    )

    // update db, no need to use await
    const positions = questions.map((q) => q.id)
    updateQuestionPositions(positions)
  }

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return
    }
    // expand all questions
    // setMinimized(false)

    const questions = Array.from(quiz.questions || [])
    const [reorderedItem] = questions.splice(result.source.index, 1)
    questions.splice(result.destination.index, 0, reorderedItem)
    // update stored quiz
    dispatch(
      updateQuestions({
        questions,
      }),
    )

    // questions in state are wrong order, use the items array
    const positions = questions.map((q) => q.id)
    // update db, no need to use await
    updateQuestionPositions(positions)
  }

  const onQuestionDelete = async (question: QuizQuestion) => {
    setDeletingQuestion(question)
    setShowDeleteConfirmation(true)
  }

  const onQuestionDeleteClosed = () => {
    setDeletingQuestion(undefined)
    setShowDeleteConfirmation(false)
  }

  const onConfirmDeleteQuestion = async () => {
    if (!quiz || !deletingQuestion) return
    try {
      setIsDeletingQuestion(true)
      const reuslt = await deleteQuizQuestion(quiz.id, deletingQuestion.id)
      if (reuslt.success) {
        onQuestionDeleted(deletingQuestion)
        setShowDeleteConfirmation(false)
        ToastUtil.success('Question deleted')
      } else {
        ToastUtil.error('Failed to delete question')
      }
    } catch (error) {
      console.error(error)
      ToastUtil.error('Failed to delete question')
    } finally {
      setIsDeletingQuestion(false)
    }
  }

  const onQuestionDeleted = async (question: QuizQuestion) => {
    // console.log('onQuestionDeleted', question)
    dispatch(deleteQuestion({ questionId: question.id }))
  }

  const onQuestionMove = () => {
    // console.log('onQuestionMove')
  }

  const onQuestionRegenerate = (question: QuizQuestion, approved: boolean) => {
    setRegenerateQuestionId(question.id)
    if (approved) {
      // show confirmation
      setShowRegenerateConfirmation(true)
    } else {
      setShowQuestionGeneratorWidget(true)
    }
  }

  const onCreateNewQuestion = async () => {
    if (!quiz) return
    // placeholder question
    const question = {
      position: quiz.questions.length + 1,
    } as QuizQuestion
    const newQuestion = await addQuizQuestion(quiz.id, question)
    if (!newQuestion) return
    dispatch(
      addQuestion({
        question: newQuestion,
      }),
    )
  }

  const onGenerateNewQuestion = async () => {
    setShowQuestionGeneratorWidget(true)
  }

  const generateNewQuestion = async (data: {
    documentId: string
    topicId: string
  }) => {
    try {
      const selectedDocumentId = data.documentId
      setIsGenerating(true)

      let generatedQuestions: GeneratedQuestion[] = []
      if (data.documentId) {
        generatedQuestions = await generateNewQuestions(
          quiz.id,
          selectedDocumentId,
        )
      } else if (data.topicId) {
        generatedQuestions = await generateNewQuestionsV2(quiz.id, data.topicId)
      }

      // convert generated questions to quiz questions
      const newQuestions = generatedQuestions.map((q) => {
        return {
          text: q.questionText,
          learningOutcome: q.learningOutcome,
          answers: [q.correctAnswer, q.incorrectAnswer1, q.incorrectAnswer2],
          archTopicId: q.archTopicId,
        } as QuizQuestion
      })
      if (newQuestions.length === 0) {
        ToastUtil.warning('No new questions generated')
        return
      }
      // add to the quiz
      const newQuestion = await addQuizQuestion(quiz.id, newQuestions[0])
      if (!newQuestion) {
        ToastUtil.error('Failed to add new question')
        return
      }
      dispatch(
        addQuestion({
          question: newQuestion,
        }),
      )
      ToastUtil.success('New question added')
    } catch (error) {
      console.error('Failed to generate question', error)
      ToastUtil.error('Failed to generate question')
    } finally {
      setIsGenerating(false)
    }
  }

  const getDateTimeText = (date: string) => {
    // convert 2024-02-27T21:41:34.084Z to format "1:40pm 27 Feb 24"
    const d = new Date(date)
    const hours = d.getHours()
    const minutes = d.getMinutes()
    const ampm = hours >= 12 ? 'pm' : 'am'
    const hours12 = hours % 12 || 12
    const minutesText = minutes < 10 ? `0${minutes}` : minutes
    return `${hours12}:${minutesText}${ampm} ${d.getDate()} ${d.toLocaleString(
      'default',
      { month: 'short' },
    )} ${d.getFullYear().toString().slice(-2)}`
  }

  const onResendEmail = async (reveiewerId: string) => {
    if (!quiz) return
    try {
      setResendingEmails((prev) => {
        return {
          ...prev,
          [reveiewerId]: true,
        }
      })
      const res = await resendEmailToReviewer(quiz.id, reveiewerId)
      if (!res) return
      if (!res.success) {
        ToastUtil.error('Failed to resend email')
        return
      }

      // fetch quiz again and update the reviewers updatedAt
      const newQuiz = await getQuiz(quiz.id)
      if (!newQuiz) return
      dispatch(storeQuiz({ quiz: newQuiz }))

      ToastUtil.success('Email resent')
    } catch (error) {
      console.error(error)
      ToastUtil.error('Failed to resend email')
    } finally {
      setResendingEmails((prev) => {
        return {
          ...prev,
          [reveiewerId]: false,
        }
      })
    }
  }

  const isResendingEmail = (reviewerId: string) => {
    return resendingEmails[reviewerId] || false
  }

  const renderReviewerStatus = () => {
    // find all reviwers and list out the status and reviewed date
    if (!quiz) return
    let reviewers = quiz.reviewers
    if (!reviewers) return
    // filter reviewers with the same version
    reviewers = reviewers.filter(
      (reviewer) => reviewer.version === quiz.version,
    )
    if (reviewers.length === 0) return

    return (
      <div className="flex flex-col gap-4">
        {reviewers.map((reviewer, index) => {
          return (
            <div
              key={index}
              className="flex flex-row items-center justify-center"
            >
              <p className="quiz-review-details-reviewer">
                Review sent to {reviewer.email} on{' '}
                {getDateTimeText(reviewer.updatedAt)}
              </p>
              <div className="grow" />
              {reviewer.version === quiz.version && (
                <button
                  className={StyleUtil.buttonSecondary}
                  onClick={() => onResendEmail(reviewer.id)}
                  disabled={isResendingEmail(reviewer.id)}
                >
                  {isResendingEmail(reviewer.id) ? <ButtonSpinner /> : 'Resend'}
                </button>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  const onShowQuizQuestionEditTopic = (data: {
    quizId: string
    question: any
  }) => {
    setEditTopicData({ quizId: data.quizId, question: data.question })
    setShowQuestionGeneratorWidget(true)
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    Emitter.on(Events.GenerateNewQuestion, generateNewQuestion)
    Emitter.on(Events.ShowQuizQuestionEditTopic, onShowQuizQuestionEditTopic)
    return () => {
      Emitter.off(Events.GenerateNewQuestion, generateNewQuestion)
      Emitter.off(Events.ShowQuizQuestionEditTopic, onShowQuizQuestionEditTopic)
    }
  })

  return (
    <>
      <SidebarLayout>
        <div className={`${StyleUtil.container} flex flex-col pb-[6vh] gap-4`}>
          <div className="flex flex-row items-center justify-center gap-4 pr-[5%]">
            <p className={StyleUtil.headlineSmall}>
              <span
                className="cursor-pointer hover:opacity-80"
                onClick={() => {
                  navigate(`${Path.quizzes.path}`)
                }}
              >
                Mentem quiz tool
              </span>
              {renderQuizStatus()}
            </p>
            <div className="grow" />
            <div className="flex flex-row items-center justify-end">
              <div className="flex flex-row">
                {isFetched &&
                  quiz &&
                  hasPermissionForApproval(quiz.uploadedAuth0Id) &&
                  !showApprovingConfirmation &&
                  !isDeleted &&
                  isApproved && (
                    <button
                      type="button"
                      className={StyleUtil.buttonSecondary}
                      onClick={() => onClickUnapprove()}
                      disabled={isApproving}
                    >
                      {!isApproving && 'Unapprove'}
                      {isApproving && <ButtonSpinner />}
                    </button>
                  )}
                {isFetched &&
                  quiz &&
                  hasPermissionForApproval(quiz.uploadedAuth0Id) &&
                  !showApprovingConfirmation &&
                  !isDeleted &&
                  !isApproved && (
                    <button
                      type="button"
                      className={StyleUtil.buttonPrimary}
                      onClick={() => onClickApprove()}
                      disabled={isApproving}
                    >
                      {!isApproving && 'Approve'}
                      {isApproving && <ButtonSpinner />}
                    </button>
                  )}
              </div>
              {showApprovingConfirmation && (
                <div className="flex flex-row items-center gap-4">
                  <p className="quiz-approving-confirmation">
                    Approve quiz and lock further editing?
                  </p>
                  <button
                    type="button"
                    className={StyleUtil.buttonPrimary}
                    onClick={() => onConfirmApproval()}
                    disabled={isApproving}
                  >
                    {!isApproving && 'Yes'}
                    {isApproving && <ButtonSpinner />}
                  </button>
                  <span
                    data-testid="btn-delete-from-catalogue-no"
                    onClick={() => {
                      onCancelApproval()
                    }}
                    className="cursor-pointer text-[16px] text-blue-800 underline hover:opacity-[0.8]"
                  >
                    Cancel
                  </span>
                </div>
              )}
            </div>
            {isFetched && hasPermission(Permission.DeleteQuizzes) && (
              <div className="flex items-center justify-end h-[20px]">
                {isClickDelete && !isDeleted && (
                  <>
                    <span className="mr-4 text-[16px]">Are you sure?</span>
                    <button
                      data-testid="btn-delete-from-quiz-yes"
                      onClick={onConfirmDeleteQuiz}
                      className={getActionButtonStyle(isDeleting)}
                    >
                      Yes
                    </button>
                    <span
                      data-testid="btn-delete-from-quiz-no"
                      onClick={() => {
                        setIsClickDelete(false)
                        return false
                      }}
                      className="ml-4 cursor-pointer text-[16px] text-blue-800 underline hover:opacity-[0.8]"
                    >
                      Cancel
                    </span>
                  </>
                )}
                {!isClickDelete && !isDeleted && (
                  <div className="flex flex-row gap-4 items-center">
                    <span
                      data-testid="btn-delete"
                      onClick={onClickDelete}
                      className="mentem-link-underline"
                    >
                      Delete quiz
                    </span>
                  </div>
                )}
                {isDeleted && !isRestoring && (
                  <span
                    data-testid="btn-restore"
                    onClick={onClickRestore}
                    className="quiz-restore-text mr-[10px]"
                  >
                    Restore
                  </span>
                )}
                {isRestoring && <Spinner />}
              </div>
            )}
          </div>

          <div className="flex flex-row items-center">
            {isFetched && quiz?.status === QuizStatus.Reviewed && (
              <p className="quiz-review-details-status">
                {renderStatusSummary()}
              </p>
            )}
          </div>

          <div className="flex flex-col mb-6">
            {!isFetched && <Spinner />}
            {isFetched && (
              <div className="flex flex-col justify-center gap-[2px] px-[5%]">
                {isEditMode && (
                  <TextareaAutosize
                    id="quiz-name-input"
                    placeholder="Type quiz name here"
                    className="quiz-name-input"
                    ref={refInputName}
                    defaultValue={quiz?.name?.trim()}
                    onKeyDown={handleKeyDownForQuiz}
                    autoFocus={true}
                  />
                )}
                {!isEditMode && (
                  <div className="flex flex-row items-center">
                    <p className="quiz-name">
                      {quiz?.name?.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </p>
                  </div>
                )}
                <div className="flex flex-col gap-[6px]">
                  {isEditMode && (
                    <TextareaAutosize
                      id="quiz-description-input"
                      placeholder="Type quiz and course description here"
                      className="quiz-description-input"
                      ref={refInputDescription}
                      defaultValue={quiz?.description}
                      onKeyDown={handleKeyDownForQuiz}
                    />
                  )}
                  {!isEditMode && quiz?.description !== '' && (
                    <p className="quiz-description">
                      {
                        // convert \n to <br /> and render
                        quiz?.description?.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                      }
                    </p>
                  )}
                  {!isLocked && (
                    <div className="flex self-end pr-[4px]">
                      <button
                        type="button"
                        className={StyleUtil.buttonPrimary}
                        onClick={() =>
                          isEditMode ? onClickSave() : handleOnClickEdit()
                        }
                        disabled={isLoading}
                      >
                        {!isLoading && (isEditMode ? 'Save' : 'Edit')}
                        {isLoading && <ButtonSpinner />}
                      </button>
                    </div>
                  )}
                </div>
                {quiz && quiz.status === QuizStatus.Sent && (
                  <div className="mt-2 p-[8px]">{renderReviewerStatus()}</div>
                )}
                <div className="flex flex-col mt-4">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {quiz &&
                            quiz.questions.map((question, index) => (
                              <Draggable
                                key={question.id}
                                draggableId={question.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    // {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      // userSelect: 'none',
                                      boxShadow: snapshot.isDragging
                                        ? '0px 16px 24px 0px rgba(13, 13, 13, 0.2)'
                                        : '',
                                    }}
                                    className="mt-2 mb-2"
                                  >
                                    <QuestionEditView
                                      key={question.id}
                                      index={index}
                                      question={question}
                                      isLocked={isLocked}
                                      onQuestionUp={onQuestionUp}
                                      onQuestionDown={onQuestionDown}
                                      onQuestionDelete={onQuestionDelete}
                                      onQuestionDeleted={onQuestionDeleted}
                                      onQuestionMove={onQuestionMove}
                                      onQuestionRegenerate={
                                        onQuestionRegenerate
                                      }
                                      dragHandleProps={provided.dragHandleProps}
                                    />
                                  </li>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            )}
          </div>
          {isFetched && !isLocked && (
            <div className="flex flex-col items-center justify-center gap-4 px-[5%]">
              <div
                className="flex flex-row items-center gap-2 cursor-pointer self-start"
                onClick={onCreateNewQuestion}
              >
                <span className="quiz-create-more">Add more</span>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.25 5.25H6.75V0.75C6.75 0.335786 6.41421 0 6 0C5.58579 0 5.25 0.335786 5.25 0.75V5.25H0.75C0.335786 5.25 0 5.58579 0 6C1.81058e-08 6.41421 0.335786 6.75 0.75 6.75H5.25V11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25V6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58579 11.6642 5.25 11.25 5.25Z"
                    fill="#32374E"
                  />
                </svg>
              </div>
              <div className="self-start">
                <button
                  type="button"
                  className={StyleUtil.buttonPrimary}
                  onClick={onGenerateNewQuestion}
                  disabled={isGenerating}
                >
                  {isGenerating ? <ButtonSpinner /> : 'Generate question'}
                </button>
              </div>
              <button
                type="button"
                className={StyleUtil.buttonPrimary}
                onClick={onClickSendForReview}
              >
                {quiz?.status === QuizStatus.Reviewed &&
                  'Send for review again'}
                {quiz?.status === QuizStatus.Draft && 'Send for review'}
              </button>
            </div>
          )}
        </div>
      </SidebarLayout>
      {showAddReviewersWidget && quiz && (
        <WidgetAddReviewers
          quiz={quiz}
          onClose={(success: boolean) => {
            setShowAddReviewersWidget(false)
            if (success) {
              // back to quiz list
              navigate(`${Path.quizzes.path}`)
            }
          }}
        />
      )}
      {showDeleteConfirmation && (
        <WidgetConfirmation
          id="delete-confirmation"
          title={
            'This question has been approved by reviewers.\nAre you sure you want to delete?'
          }
          isLoading={isDeletingQuestion}
          onConfirm={onConfirmDeleteQuestion}
          onCancel={onQuestionDeleteClosed}
          onClose={onQuestionDeleteClosed}
        />
      )}
      {showRegenerateConfirmation && (
        <WidgetConfirmation
          id="regenerate-confirmation"
          title={
            'This question has been approved by reviewers.\nAre you sure you want to regenerate?'
          }
          isLoading={false}
          onConfirm={onConfirmRegenerateQuestion}
          onCancel={closeConfirmRegenerateQuestion}
          onClose={closeConfirmRegenerateQuestion}
        />
      )}
      {showQuestionGeneratorWidget && (
        <WidgetQuestionGenerator
          multiple={false}
          onGenerated={() => {
            setShowQuestionGeneratorWidget(false)
          }}
          onClose={() => {
            setShowQuestionGeneratorWidget(false)
          }}
          quizId={editTopicData?.quizId || undefined}
          quizQuestion={editTopicData?.question || undefined}
          onSaved={() => {
            setEditTopicData(undefined)
            setShowQuestionGeneratorWidget(false)
          }}
        />
      )}
    </>
  )
}

export default QuizDetailsPage
