/* eslint-disable */
import { Document, DocumentUser } from 'apis/entities/document.entity'
import SidebarLayout from 'components/SidebarLayout'
import { useApi } from 'providers/ApiProvider'
import { Permission, Role, useAuth } from 'providers/AuthProvider'
import {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
  MutableRefObject,
} from 'react'
import { StyleUtil } from 'utils/StyleUtil'
import { ToastUtil } from 'utils/ToastUtil'
import DropDownMenu, { DropDownItem } from 'views/DropDownMenu'
import SearchInput from 'views/SearchInput'
import Spinner from 'views/Spinner'
import sortSvg from 'images/sort.svg'
import sort2Svg from 'images/sort2.svg'
import plusSvg from 'images/quiz-plus.svg'
import minusSvg from 'images/quiz-minus.svg'
import lockSvg from 'images/lock.svg'
import unlockSvg from 'images/unlock.svg'
import close2 from 'images/close2.svg'
import { Tooltip } from 'react-tooltip'
import Switch from '@mui/material/Switch'

import hasNotesSvg from 'images/has-notes.svg'
import noNotesSvg from 'images/no-notes.svg'
import { DatasetSmall, getFamV4, deleteTopic } from '../mocks/MockTopicData'
import { ButtonGroup, Checkbox, styled } from '@mui/material'
import { Button } from '@mui/base'
import React from 'react'
enum SortBy {
  Topic = 'topic',
  PublishedBy = 'publishedUserName',
  DateTime = 'createdAt',
  Industry = 'industry',
  SearchSimilarity = 'searchSimilarity',
}

type Direction = 'ascending' | 'descending' | ''
const PAGE_SIZE = 100
const publishedByOptionLabel = {
  id: '0',
  name: 'Editor',
  value: '0',
  isLabel: true,
}

const publishedByOptionShowAll = {
  id: '1',
  name: 'Show all',
  value: '',
  isLabel: false,
}

const tooltipStyle = {
  backgroundColor: '#fff',
  color: '#000',
  borderRadius: '6px',
}

const tooltipStyleReverse = {
  backgroundColor: '#6b69c1',
  color: '#fff',
  borderRadius: '6px',
}

// const REACT_APP_PUBLISH_URL = 'content.mentem.co'
// const REACT_APP_PREVIEW_URL =
//   's3.ap-southeast-2.amazonaws.com/prd.test-content.mentem.co'
const REACT_APP_PUBLISH_URL = process.env.REACT_APP_PUBLISH_URL
const REACT_APP_PREVIEW_URL = process.env.REACT_APP_PREVIEW_URL

let INIT_DOCS: Document[] = [...DatasetSmall]

function isDebug(): boolean {
  return window.location.search.includes('debug=true')
}

function useFakeData(): boolean {
  return window.location.search.includes('fake=true')
}

function useTopicNesting(): boolean {
  if (window.location.search.includes('nesting=true')) return true
  if (window.location.search.includes('nesting=false')) return false
  return isDebug()
}
function useReverseHistory(): boolean {
  if (window.location.search.includes('reverseHistory=true')) return true
  if (window.location.search.includes('reverseHistory=false')) return false
  return !isDebug()
}
function getAlternatingStyle() {
  let isHeader1 = true
  let isCell1 = true

  return (what: string, startRow = false) => {
    if (what === 'td') {
      if (startRow) isCell1 = true
      else isCell1 = !isCell1
    } else {
      if (startRow) isHeader1 = true
      else isHeader1 = !isHeader1
    }
    if (what === 'td') {
      return isCell1 ? 'mentem-table-cell-2' : 'mentem-table-cell-1'
    }
    return isHeader1
      ? StyleUtil.stickyTableHeader1
      : StyleUtil.stickyTableHeader2
  }
}

const NotesModal = ({
  isOpen,
  onClose,
  initialNotes,
  onSave,
}: {
  isOpen: boolean
  onClose: () => void
  initialNotes: string
  onSave: (notes: string) => void
}) => {
  const [notes, setNotes] = useState(initialNotes)

  const handleSave = () => {
    onSave(notes)
    onClose()
  }

  if (!isOpen) return null

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(255,255,255,0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          position: 'relative', // For absolute positioning of the close button
          backgroundColor: '#fff',
          padding: '20px 24px 12px 24px',
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          boxShadow: `0px 381px 107px 0px rgba(0, 0, 0, 0.00), 
            0px 244px 98px 0px rgba(0, 0, 0, 0.01), 
            0px 137px 82px 0px rgba(0, 0, 0, 0.05), 
            0px 61px 61px 0px rgba(0, 0, 0, 0.09), 
            0px 15px 34px 0px rgba(0, 0, 0, 0.10)`,
        }}
      >
        <div
          style={{
            position: 'absolute', // Position the close button
            top: '2px', // Slightly down from the top edge
            right: '2px', // Slightly in from the right edge
            cursor: 'pointer', // Change cursor to pointer
          }}
          onClick={onClose} // Call onClose when the close button is clicked
        >
          <img src={close2} alt="Close" />
        </div>
        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          style={{
            width: '500px',
            height: '96px',
            border: '1px solid #D5D5DB',
            borderRadius: '3px',
            padding: '6px 0px 36px 12px',
            color: '#92929D',
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontWeight: 600,
            lineHeight: '20px',
            fontStyle: 'normal',
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault() // Prevent the default action to avoid adding a new line
              handleSave() // Call the save function
            }
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
          }}
        >
          <button className={StyleUtil.buttonPrimary} onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

function Topics() {
  const { isLogged, getRole, permissions, auth0User: user } = useAuth()
  const {
    getDocumentsV2,
    getDocumentPublishedBy,
    deleteDocument,
    updateDocument,
    publish,
    getDocument,
  } = useApi()

  // contains ONLY shown topics
  const [tableTopics, setTableTopics] = useState<Document[]>([])
  // contains ALL topics
  const [topicsDB, setTopicsDB] = useState<Document[]>([])
  const [cacheDocuments, setCacheDocuments] = useState<any[]>([]) // for the tooltip
  const [publishedByOptions, setPublishedByOptions] = useState<any>([
    publishedByOptionLabel,
  ])
  const [isClickDelete, setIsClickDelete] = useState<boolean>(false)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const refSearchTextInput = useRef<string>('')
  const refStartDate = useRef<Date | undefined>(undefined)
  const refPublishedBy = useRef<string>('')
  const refPublished = useRef<boolean | null>(isDebug() ? null : true)
  // const refPublished = useRef<boolean | null>(isDebug() ? null : false)
  const refIndustry = useRef<string>('')
  const refClientSpecific = useRef<boolean | null>(isDebug() ? null : false)
  // const refClientSpecific = useRef<boolean | null>(isDebug() ? null : true)
  const refIsTopic = useRef<boolean | null>(isDebug() ? null : true)
  // const refIsTopic = useRef<boolean | null>(isDebug() ? null : false)
  const [expandedIds, setExpandedIds] = useState<string[]>([])
  const [nResults, setNResults] = useState<number>(0)
  const [currPage, setCurrPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(
    parseInt(
      new URLSearchParams(location.search).get('pageSize') ||
        PAGE_SIZE.toString(),
    ),
  )
  const [isFetchedPublishedBy, setIsFetchedPublishedBy] =
    useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [sortConfig, setSortConfig] = useState<{
    key: SortBy
    direction: Direction
  } | null>({ key: SortBy.DateTime, direction: 'descending' })
  const [orderByTitle, setOrderByTitle] = useState<Direction>('')
  const [orderByPublishedBy, setOrderByPublishedBy] = useState<Direction>('')
  const [orderByDateTime, setOrderByDateTime] = useState<Direction>('')
  const [orderByIndustry, setOrderByIndustry] = useState<Direction>('')

  const alternateStyle = getAlternatingStyle()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingDoc, setEditingDoc] = useState<Document | null>(null)
  const [morePopupDocId, setMorePopupDocId] = useState<string>('')

  const fetchDocument = useCallback(
    async (id: string) => {
      // console.log('fetchDocument', id)
      // if exist in cache, return
      if (cacheDocuments.find((d) => d.id === id)) {
        return
      }

      const doc = await getDocument(id)
      if (doc) {
        // add if new or update if existing
        setCacheDocuments((prev) => {
          const index = prev.findIndex((d) => d.id === id)
          if (index === -1) {
            return [...prev, doc]
          } else {
            prev[index] = doc
            return prev
          }
        })
      }
    },
    [cacheDocuments, getDocument],
  )

  const hasArchTopics = (id: string): boolean => {
    const doc = cacheDocuments.find((d) => d.id === id)
    if (!doc) return false
    if (!doc.topics) return false
    return doc.topics.length > 0
  }

  const renderTooltipContentForTopics = useCallback(
    (id: string) => {
      const doc = cacheDocuments.find((d) => d.id === id)
      if (!doc) return <div></div>
      if (!doc.topics) return <div></div>
      const topics = doc.topics
      if (topics.length === 0) return <div></div>
      return (
        <div>
          <ul>
            {topics
              .filter((topic: any) => topic.relationship)
              .map((topic: any, index: any) => (
                <li key={index} className="mb-2">
                  <p>{`Topic ${topic.topicNumber} from ${topic.relationship.intakeName} ${topic.relationship.courseName} for ${topic.relationship.clientName}`}</p>
                </li>
              ))}
          </ul>
        </div>
      )
    },
    [cacheDocuments],
  )

  function getSwitchTheme(ref?: MutableRefObject<boolean | null>) {
    const retVal = styled(Switch)(({ theme }) => ({
      // MuiSwitch-root
      padding: 9,
      width: 54,
      '& .MuiButtonBase-root': {
        '&.Mui-checked': {
          transform: 'translateX(16px)',
        },
        '& .MuiSwitch-thumb': {
          boxShadow: 'none',
          width: 12,
          height: 12,
          margin: 4,
          ...(ref?.current === false && {
            backgroundColor: 'black',
          }),
        },
      },
      '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        ...(ref?.current
          ? {
              background: '#3C415D',
              opacity: '1 !important',
            }
          : {
              border: '1px solid black',
              background: 'white',
              opacity: '1 !important',
            }),
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          width: 16,
          height: 16,
        },
        '&::before': {
          left: 12,
        },
        '&::after': {
          right: 12,
        },
      },
    }))
    return retVal
  }

  const SwitchPublished = getSwitchTheme(refPublished)
  const SwitchIsTopic = getSwitchTheme(refIsTopic)
  const SwitchClientSpecific = getSwitchTheme(refClientSpecific)

  const sortedDocuments = useMemo(() => {
    let sortableItems = tableTopics?.length ? [...tableTopics] : []

    if (sortConfig !== null) {
      if (useReverseHistory()) {
        let items = []
        for (const doc of sortableItems) {
          const rows = [doc, ...(doc.children || [])]
          // sort rows by createdAt
          rows.sort((a, b) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            )
          })
          items.push({
            ...rows[0],
            children: rows.slice(1).map((childDoc) => {
              return { ...childDoc, children: [] }
            }),
          })
        }
        sortableItems = items
      }
      sortableItems.sort((a: Document, b: Document) => {
        if (sortConfig.key === SortBy.SearchSimilarity) {
          if (a.nameSimilarity === b.nameSimilarity) {
            return 0
          } else if (a.nameSimilarity < b.nameSimilarity) {
            return 1
          } else {
            return -1
          }
        } else {
          const valA =
            typeof a[sortConfig.key] === 'string'
              ? a[sortConfig.key].toLowerCase()
              : a[sortConfig.key] || ''
          const valB =
            typeof b[sortConfig.key] === 'string'
              ? b[sortConfig.key].toLowerCase()
              : b[sortConfig.key] || ''

          if (valA < valB) {
            return sortConfig.direction === 'ascending' ? -1 : 1
          }
          if (valA > valB) {
            return sortConfig.direction === 'ascending' ? 1 : -1
          }
          return 0
        }
      })
    }
    return sortableItems
  }, [tableTopics, sortConfig])

  function useOutsideClick(ref: any, callback: any) {
    useEffect(() => {
      function handleClickOutside(event: { target: any }) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback()
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref, callback])
  }

  useEffect(() => {
    const { docs: famDocs, masterDocs } = getFamV4(
      topicsDB,
      // refPublished.current,
      // refStartDate.current,
      // refPublishedBy.current,
      // refIndustry.current,
      // refIsTopic.current,
      // refClientSpecific.current,
      useTopicNesting(),
    )

    // sort masterDocs newest first
    masterDocs.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })
    setTableTopics(masterDocs)
  }, [topicsDB])
  const searchDocuments = useCallback(
    async (_currPage?: number, _pageSize?: number) => {
      _currPage = _currPage || currPage
      _pageSize = _pageSize || pageSize

      if (!isLogged) return
      let filter = `skunkworks=1&publishedBy=${refPublishedBy.current}`
      if (refPublished.current != null)
        filter += `&published=${refPublished.current ? 1 : 0}`
      if (refIndustry.current) filter += `&industry=${refIndustry.current}` // Including the industry filter

      filter += `&clientSpecific=${refClientSpecific.current ? 1 : 0}`
      // if (refIsTopic.current != null)
      filter += `&isTopic=${refIsTopic.current ? 1 : 0}`

      const search = refSearchTextInput.current
      setIsSearching(true)
      let docs: Document[] = []
      if (useFakeData()) {
        const start = (_currPage - 1) * _pageSize
        const end = start + _pageSize
        docs = INIT_DOCS.filter((x) => {
          if (refPublished.current !== x.published) {
            return false
          }
          if (refIsTopic.current !== x.isTopic) {
            return false
          }
          if (refClientSpecific.current !== x.clientSpecific) {
            return false
          }
          return true
        }) as Document[]
        setNResults(docs.length)
        docs = docs.slice(start, end)
      } else {
        const result = await getDocumentsV2(
          filter,
          search,
          refStartDate.current,
          undefined,
          _pageSize,
          _currPage,
        )
        docs = result.items as Document[]
        setNResults(result.totalItems)
      }
      setTopicsDB((x) => {
        return [
          ...docs.map((x) => {
            return {
              ...x,
              _TEMP_PARENT_ID: x.parentIds[0] || null,
              // parents: [],
              // children: [],
            }
          }),
          ...x,
        ]
      })
      setIsSearching(false)
    },
    [getDocumentsV2, isLogged],
  )

  const fetchPublishedByUsers = useCallback(async () => {
    if (!isLogged) return
    setIsFetchedPublishedBy(false)
    const users = await getDocumentPublishedBy()
    let allOptions = [publishedByOptionLabel]
    if (users) {
      let options = users.map((u: DocumentUser, _index: number) => {
        return {
          id: u.publishedAuth0Id,
          name: u.publishedUserName,
          value: u.publishedAuth0Id,
          isLabel: false,
        }
      })
      allOptions = allOptions.concat(options)
      allOptions.push(publishedByOptionShowAll)
      setPublishedByOptions(allOptions)
      setIsFetchedPublishedBy(true)
    }
  }, [getDocumentPublishedBy, isLogged])

  const isFetched = (): boolean => {
    return isFetchedPublishedBy
  }

  const getActionButtonStyle = (loading: boolean) => {
    const style = StyleUtil.buttonPrimary
    if (loading) {
      return `${style} loading disabled`
    }
    return style
  }

  const hasPermission = useCallback(
    (permission: string, publishedAuth0Id?: string): boolean => {
      // https://horizonsfl.atlassian.net/browse/HLP-1465
      if (getRole() === Role.SuperAdmin || getRole() === Role.Admin) return true
      if (
        permissions &&
        permissions.includes(permission) &&
        user?.sub === publishedAuth0Id
      ) {
        // same user
        return true
      }
      return false
    },
    [getRole, permissions, user?.sub],
  )

  const hasPublishColumn = useCallback((): boolean => {
    if (
      (getRole() === Role.SuperAdmin || getRole() === Role.Admin) &&
      refPublished.current === false
    )
      return true
    return false
  }, [getRole])

  const onInputChangedSearch = (value: string) => {
    refSearchTextInput.current = value
  }

  const onEnterSearch = async () => {
    setCurrPage(1)
    setTopicsDB([])
    setSortConfig({ key: SortBy.SearchSimilarity, direction: 'descending' })
    await searchDocuments(1)
  }

  const onSelectDate = async (item: DropDownItem) => {
    const value = item.value
    if (value === undefined) {
      // show all
      refStartDate.current = undefined
    } else if (value > 0) {
      const startDate = new Date()
      // caculate start date by value which is number of days
      startDate.setDate(startDate.getDate() - value)
      refStartDate.current = startDate
    }
    setCurrPage(1)
    setTopicsDB([])
    await searchDocuments(1)
  }

  const onSelectPublishedBy = async (item: DropDownItem) => {
    const value = item.value
    refPublishedBy.current = value
    setCurrPage(1)
    setTopicsDB([])
    await searchDocuments(1)
  }

  const onSelectPublished = async (item: DropDownItem) => {
    debugger
    const value = item.value
    if (value === '__ALL__') {
      refPublished.current = null // Set to null or another distinct value for "All"
    } else {
      refPublished.current = value === 0
    }
    setCurrPage(1)
    setTopicsDB([])
    await searchDocuments(1)
  }

  const onClickDelete = () => {
    if (checkedIds.length === 0) {
      ToastUtil.warning('Please select at least one page')
    } else {
      setIsClickDelete(true)
    }
  }

  const onChangeCheckbox = useCallback(
    (checked: boolean, id: string) => {
      setIsClickDelete(false)
      if (checked) {
        setCheckedIds([...checkedIds, id])
      } else {
        setCheckedIds(checkedIds.filter((y) => y !== id))
      }
    },
    [checkedIds],
  )

  const onConfirmDelete = async () => {
    setIsLoading(true)

    for (const x of checkedIds) {
      if (useFakeData()) {
        INIT_DOCS = deleteTopic(INIT_DOCS as Document[], x) as Document[]
      } else {
        await deleteDocument(x)
      }
    }
    await searchDocuments()
    setIsLoading(false)
    ToastUtil.success(`Deleted successfully`)

    document.querySelectorAll('[data-id="checkbox"]').forEach((x: any) => {
      x.checked = false
    })
    setCheckedIds([])
    setIsClickDelete(false)
  }

  const getPreviewUrl = (doc: Document, dev?: boolean): string => {
    if (dev) {
      if (doc.published) {
        return `https://${process.env.REACT_APP_PUBLISH_URL}/lx/${doc.id}/index.html`
      } else {
        return `https://${process.env.REACT_APP_PREVIEW_URL}/${doc.id}/index.html`
      }
    } else {
      if (doc.published) {
        return `https://${REACT_APP_PUBLISH_URL}/lx/${doc.id}/index.html`
      } else {
        return `https://${REACT_APP_PREVIEW_URL}/${doc.id}/index.html`
      }
    }
  }
  const debugButtonStyle = (isActive: boolean) => ({
    backgroundColor: isActive ? '#1976d2' : undefined,
    color: isActive ? '#fff' : undefined,
  })

  const onClickSort = (key: SortBy) => {
    let ascending = true
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      ascending = false
    }

    switch (key) {
      case SortBy.Topic:
        setOrderByTitle(ascending ? 'ascending' : 'descending')
        break
      case SortBy.PublishedBy:
        setOrderByPublishedBy(ascending ? 'ascending' : 'descending')
        break
      case SortBy.DateTime:
        setOrderByDateTime(ascending ? 'ascending' : 'descending')
        break
      case SortBy.Industry:
        setOrderByIndustry(ascending ? 'ascending' : 'descending')
        break
      default:
        break
    }

    setSortConfig({ key, direction: ascending ? 'ascending' : 'descending' })
  }

  const getCreatedAt = (createdAt: string): string => {
    const x = new Date(createdAt)
    const date = x.getDate()
    const month = x.toLocaleString('default', { month: 'short' })
    const year = x.getFullYear()
    return `${date}\u00A0${month}\u00A0${year}`
  }

  const getCreatedAtTime = (createdAt: string): string => {
    const x = new Date(createdAt)
    const hour = x.getHours()
    const minute = x.getMinutes()
    // add leading zero
    const hourStr = hour < 10 ? `0${hour}` : `${hour}`
    const minuteStr = minute < 10 ? `0${minute}` : `${minute}`
    return `${hourStr}:${minuteStr}`
  }

  const getTooptipStyle = (key: SortBy): any => {
    switch (key) {
      case SortBy.Topic:
        return orderByTitle === 'ascending' ? tooltipStyleReverse : tooltipStyle
      case SortBy.PublishedBy:
        return orderByPublishedBy === 'ascending'
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.DateTime:
        return orderByDateTime === 'ascending'
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.Industry:
        return orderByIndustry === 'ascending'
          ? tooltipStyleReverse
          : tooltipStyle
      default:
        return tooltipStyle
    }
  }

  const getSortingText = (key: SortBy): string => {
    switch (key) {
      case SortBy.Topic:
        if (orderByTitle === 'ascending') return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.PublishedBy:
        if (orderByPublishedBy === 'ascending') return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.DateTime:
        if (orderByDateTime === 'ascending') return 'Sort new to old'
        return 'Sort old to new'
      default:
        return ''
    }
  }

  const onPublish = useCallback(
    async (id: string) => {
      try {
        // set button to loading state
        const btn = document.getElementById(`btn-publish-${id}`)
        if (btn) {
          btn.classList.add('loading')
          btn.classList.add('disabled')
          // remove the button text
          btn.innerHTML = ''
        }
        const data = await publish(id)
        // console.log(data)
        // set button to normal state
        if (btn) {
          btn.classList.remove('loading')
          btn.classList.remove('disabled')
          // restore the button text
          btn.innerHTML = 'Publish'
        }
        await searchDocuments()
        ToastUtil.success(`Published successfully`)
      } catch (error) {
        console.log(error)
        ToastUtil.error(`Publish failed`)
      }
    },
    [publish, searchDocuments],
  )

  const updateDocumentStatus = useCallback(
    async (
      docId: string,
      status: keyof Document,
      value: any,
      successMessage: string,
      errorMessage: string,
    ) => {
      try {
        await updateDocument(docId, { [status]: value })
        ToastUtil.success(successMessage)
        // todo: update topicsDB
        setTableTopics((prevDocuments) => {
          return prevDocuments.map((doc) => {
            // If the updated document is the current one, or if the current document is a parent of the updated one
            if (
              doc.id === docId ||
              doc.children?.some((child) => child.id === docId)
            ) {
              // If it's the directly updated document, just update it
              if (doc.id === docId) {
                return { ...doc, [status]: value }
              } else {
                // If it's a parent of the updated document, update the child
                const updatedChildren = doc.children?.map((child) =>
                  child.id === docId ? { ...child, [status]: value } : child,
                )
                return { ...doc, children: updatedChildren }
              }
            } else {
              return doc
            }
            // return doc.id === docId ? { ...doc, [status]: value } : doc
          }) as Document[]
        })
      } catch (error) {
        console.error(errorMessage, error)
        ToastUtil.error(errorMessage)
      }
    },
    [updateDocument],
  )
  const openModal = (doc: Document) => {
    setEditingDoc(doc)
    setIsModalOpen(true)
  }

  const saveNotes = useCallback(
    async (newNotes: string) => {
      if (!editingDoc) return
      await updateDocument(editingDoc.id, { comments: newNotes })
      // todo: update topicsDB
      setTableTopics((currentDocuments) =>
        currentDocuments.map((doc) =>
          doc.id === editingDoc.id ? { ...doc, comments: newNotes } : doc,
        ),
      )
      ToastUtil.success(`Updated successfully`)
      setIsModalOpen(false)
    },
    [editingDoc, updateDocument],
  )

  const toggleAccordion = (docId: string) => {
    setExpandedIds((prevExpandedIds) => {
      if (prevExpandedIds.includes(docId)) {
        // If already expanded, collapse it
        return prevExpandedIds.filter((id) => id !== docId)
      } else {
        // Otherwise, expand it
        return [...prevExpandedIds, docId]
      }
    })
  }

  const morePopupRef = useRef(null) // Create a ref for the more popup
  useOutsideClick(morePopupRef, () => setMorePopupDocId(''))

  const getTotalChildren = (document: Document): number => {
    let total = document.children ? document.children.length : 0
    if (document.children) {
      document.children.forEach((child) => {
        total += getTotalChildren(child)
      })
    }
    return total
  }

  const toRow = (doc: Document, level: number, nChildren: number) => {
    let isChildRow = doc.parents && doc.parents.length > 0
    if (!useTopicNesting()) {
      isChildRow = level > 0
    }
    return (
      <>
        <tr data-testid="tr-document" key={doc.id}>
          <td className={`${alternateStyle('td', true)}`}>
            <div
              className={`flex flex-row items-center justify-between`}
              style={{ paddingLeft: `${level * 20}px` }}
            >
              <div className="flex-grow">
                {doc.isLocked || !hasPermission(Permission.DeleteCatalog) ? (
                  <div
                    className="self-center"
                    style={{
                      border: '2px lightgrey solid',
                      minWidth: 18,
                      maxWidth: 18,
                      height: 18,
                      borderRadius: 2,
                      display: 'inline-block',
                    }}
                  />
                ) : (
                  <Checkbox
                    className="self-center"
                    checked={checkedIds.includes(doc.id)}
                    onChange={(e) => {
                      onChangeCheckbox(e.target.checked, doc.id)
                    }}
                    sx={{
                      ...(isChildRow && {
                        color: '#BD69FE',
                        '&.Mui-checked': {
                          color: '#BD69FE',
                        },
                      }),
                      display: 'inline-block',
                      verticalAlign: 'super',
                      padding: 0,
                      margin: 0,
                      marginRight: '-3px',
                      marginLeft: '-2px',
                      '&:hover': {
                        backgroundColor: 'transparent', // Maintain transparent background on hover
                      },
                    }}
                  />
                )}

                <Tooltip
                  id={`title-link-${doc.id}`}
                  style={{ zIndex: 2 }}
                  hidden={hasArchTopics(doc.id) ? false : true}
                >
                  {renderTooltipContentForTopics(doc.id)}
                </Tooltip>

                <a
                  className="mentem-link"
                  href={getPreviewUrl(doc)}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    ...(isDebug() && {
                      color: doc.deletedAt
                        ? 'red'
                        : doc.published
                        ? 'green'
                        : '#ffcf61',
                    }),
                    verticalAlign: 'super',
                    paddingLeft: '6px',
                  }}
                  data-tooltip-id={`title-link-${doc.id}`}
                  onMouseEnter={() => {
                    fetchDocument(doc.id)
                  }}
                >
                  {doc.topic || '(No title)'}
                </a>
                {isDebug() && (
                  <span style={{ verticalAlign: 'super' }}>
                    <a
                      href={getPreviewUrl(doc, false)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      P
                    </a>
                    |
                    <a
                      href={getPreviewUrl(doc, true)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      D
                    </a>
                    {/*|{doc.id.substring(0, 4)}|{doc.parentSimilarity?.toFixed(2)}*/}
                    topic = {doc.isTopic ? '✅' : '❌'}| client ={' '}
                    {doc.clientSpecific ? '✅' : '❌'}
                  </span>
                )}
              </div>

              {nChildren ? (
                <div className="flex-none">
                  <button
                    onClick={() => toggleAccordion(doc.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {nChildren} ({getTotalChildren(doc)})
                    {/*{isDebug() ? <>({getTotalChildren(doc)})</> : ''}*/}
                    <div
                      style={{
                        display: 'inline-block',
                        paddingLeft: '4px',
                        minHeight: '16px',
                        minWidth: '16px',
                        verticalAlign: 'sub',
                      }}
                    >
                      <img
                        className="cursor-pointer"
                        src={expandedIds.includes(doc.id) ? minusSvg : plusSvg}
                        alt="expand"
                        style={{
                          paddingTop: expandedIds.includes(doc.id)
                            ? '7px'
                            : '0',
                        }}
                      />
                    </div>
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          </td>
          <td className={`${alternateStyle('td')}`}>
            <div className="flex justify-center">
              {/*commented out because makes page slow*/}
              {/*<Tooltip id={`tt-lock-${doc.id}`} style={{ zIndex: 2 }}></Tooltip>*/}

              <img
                src={doc.isLocked ? lockSvg : unlockSvg}
                data-tooltip-id={`tt-lock-${doc.id}`}
                data-tooltip-content={doc.isLocked ? 'Locked' : 'Unlocked'}
                alt={doc.isLocked ? 'Locked' : 'Unlocked'}
                {...(hasPermission(Permission.UpdateCatalog)
                  ? {
                      onClick: () => {
                        updateDocumentStatus(
                          doc.id,
                          'isLocked',
                          !doc.isLocked,
                          'Updated successfully',
                          doc.isLocked
                            ? 'Topic has linked in architecture tool, cannot be unlocked'
                            : 'Failed to update locked status',
                        )
                        setCheckedIds(checkedIds.filter((y) => y !== doc.id))
                      },
                      style: { cursor: 'pointer' },
                    }
                  : {})}
              />
            </div>
          </td>
          <td className={`${alternateStyle('td')}`}>
            {hasPermission(Permission.UpdateCatalog) ? (
              <>
                <DropDownMenu
                  items={[
                    { id: '0', name: 'Neutral', value: '' },
                    { id: '1', name: 'Banking', value: 'Banking' },
                    { id: '2', name: 'Insurance', value: 'Insurance' },
                  ]}
                  onSelected={async (item) => {
                    if (useFakeData()) {
                      INIT_DOCS = INIT_DOCS.map((d) =>
                        d.id === doc.id ? { ...d, industry: item.value } : d,
                      )
                    } else {
                      updateDocumentStatus(
                        doc.id,
                        'industry',
                        item.value === '' ? null : item.value,
                        'Updated successfully',
                        'Failed to update industry',
                      )
                    }
                  }}
                  value={doc.industry || ''}
                  customHACK={true}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    fontSize: 16,
                  }}
                  className="flex justify-center"
                >
                  {doc.industry || 'None'}
                </div>
              </>
            )}
          </td>
          <td className={`${alternateStyle('td')} w-auto`}>
            <div className="flex items-center justify-center">
              {doc.publishedUserName || 'Unknown'}
            </div>
          </td>
          <td className={`${alternateStyle('td')} w-auto`}>
            <div className="flex items-center justify-center text-center">
              {getCreatedAt(doc.createdAt)}
              <br />
              {getCreatedAtTime(doc.createdAt)}
            </div>
          </td>
          <td className={`${alternateStyle('td')}`}>
            <div className="flex items-center justify-center">
              {/*commented out because makes page slow*/}
              {/*  <Tooltip id={`tt-comments-${doc.id}`}></Tooltip>*/}
              <button
                onClick={() => {
                  if (hasPermission(Permission.UpdateCatalog)) {
                    openModal(doc)
                  } else {
                    ToastUtil.warning('You do not have permission to edit')
                  }
                }}
              >
                <img
                  data-tooltip-id={`tt-comments-${doc.id}`}
                  data-tooltip-content={doc.comments || 'No notes'}
                  src={doc.comments ? hasNotesSvg : noNotesSvg}
                  alt={doc.comments}
                />{' '}
              </button>
            </div>
          </td>
          {hasPublishColumn() && (
            <td className={`${alternateStyle('td')} w-auto`}>
              <div className="flex items-center justify-center">
                <button
                  data-testid={`btn-publish-${doc.id}`}
                  id={`btn-publish-${doc.id}`}
                  className={StyleUtil.buttonPrimary}
                  onClick={() => onPublish(doc.id)}
                >
                  Publish
                </button>
              </div>
            </td>
          )}
          <td className={`${alternateStyle('td')} w-auto`}>
            <div className="flex items-center justify-center">
              <button
                onClick={() => {
                  setMorePopupDocId(doc.id)
                }}
              >
                . . .
              </button>
            </div>

            {morePopupDocId === doc.id && (
              <div ref={morePopupRef} style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    whiteSpace: 'nowrap',
                    backgroundColor: 'white',
                    width: '178px',
                    fontSize: '14px',
                    marginLeft: '-120px',
                    marginTop: '-15px',
                  }}
                >
                  {doc.clientSpecific ? (
                    <button
                      className="moreOptionsButton"
                      onClick={() => {
                        updateDocumentStatus(
                          doc.id,
                          'clientSpecific',
                          false,
                          'Updated successfully',
                          'Failed to update client specific status',
                        )
                      }}
                    >
                      Set as reusable
                    </button>
                  ) : (
                    <button
                      className="moreOptionsButton"
                      onClick={() => {
                        updateDocumentStatus(
                          doc.id,
                          'clientSpecific',
                          true,
                          'Updated successfully',
                          'Failed to update client specific status',
                        )
                      }}
                    >
                      Set as client-IP
                    </button>
                  )}
                  <button
                    className="moreOptionsButton"
                    onClick={() => {
                      const s3 = `https://${process.env.REACT_APP_PUBLISH_URL}/lx`
                      const previewUrl = `${s3}/${doc.id}/index.html`
                      const embed = `<iframe width="100%" height="1000" src="${previewUrl}" title="Course" frameborder="0" allow="web-share;fullscreen;"></iframe>`
                      navigator.clipboard.writeText(embed)
                      ToastUtil.success('Embed code copied to clipboard')
                    }}
                  >
                    Copy embed code
                  </button>
                  {doc.fileUrl && (
                    <button
                      className="moreOptionsButton"
                      onClick={() => {
                        window.open(doc.fileUrl, '_blank')
                      }}
                    >
                      Download Word document
                    </button>
                  )}
                </div>
              </div>
            )}
          </td>
        </tr>
      </>
    )
  }
  const renderRow = useCallback(
    (doc: Document, level = 0): any => {
      // console.log(`renderRow(${doc.id}, ${level})`)
      return (
        <React.Fragment key={doc.id}>
          {toRow(doc, level, doc.children?.length || 0)}
          {expandedIds.includes(doc.id) &&
            doc.children?.map((childDoc) => {
              return renderRow(childDoc, level + 1)
            })}
        </React.Fragment>
      )
    },
    [
      alternateStyle,
      checkedIds,
      hasPermission,
      hasPublishColumn,
      onChangeCheckbox,
      onPublish,
      updateDocumentStatus,
      expandedIds,
    ],
  )

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {}

  useEffect(() => {
    fetchPublishedByUsers()
    searchDocuments()
  }, [searchDocuments, fetchPublishedByUsers])

  return (
    <SidebarLayout>
      <style>{`
        .moreOptionsButton {
          height: 24px;
          padding-left: 6px;
          padding-top: 2px;
          display: block;
              width: 178px;
              text-align: left;
        }
        .moreOptionsButton:hover {
          background-color: #BA61FF;
          color: white;
        }
      `}</style>

      <div
        className={StyleUtil.containerNoScroll}
        style={{
          overflowY: 'auto',
        }}
      >
        <p className={StyleUtil.headline}>Manage topic pages</p>
        {isModalOpen && editingDoc && (
          <NotesModal
            key={`notes-modal-${editingDoc.id}`}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            initialNotes={editingDoc.comments}
            onSave={saveNotes}
          />
        )}
        {!isFetched() && <Spinner />}
        {isFetched() && (
          <div className="flex h-[100%] flex-col pb-10 pt-16">
            <div className="flex flex-none flex-row items-center">
              <div className="w-1/3">
                <SearchInput
                  onInputChanged={onInputChangedSearch}
                  onEnter={onEnterSearch}
                  isSearching={isSearching}
                />
              </div>
              <div className="flex-grow"></div>

              <div className="flex-none">
                <div className="flex gap-[28px]">
                  <DropDownMenu
                    items={[
                      {
                        id: '0',
                        name: 'Date',
                        value: undefined,
                        isLabel: true,
                      },
                      { id: '1', name: 'Last 7 days', value: 7 },
                      { id: '2', name: 'Last 30 days', value: 30 },
                      { id: '3', name: 'Last 6 months', value: 183 },
                      {
                        id: '4',
                        name: 'All dates',
                        value: undefined,
                        default: true,
                        // isShowAllOption: true,
                      },
                    ]}
                    onSelected={onSelectDate}
                    customHACK={true}
                  />
                  <DropDownMenu
                    items={publishedByOptions}
                    onSelected={onSelectPublishedBy}
                    customHACK={true}
                  />
                  {isDebug() && (
                    <>
                      <DropDownMenu
                        items={[
                          { id: '0', name: 'Published', value: 0 },
                          { id: '1', name: 'Preview only', value: 1 },
                          { id: '2', name: 'Show all', value: '__ALL__' },
                        ]}
                        onSelected={onSelectPublished}
                        customHACK={true}
                      />
                    </>
                  )}
                  <DropDownMenu
                    items={[
                      { id: '0', name: 'Industry', value: '', isLabel: true },
                      { id: '1', name: 'Banking', value: 'Banking' },
                      { id: '2', name: 'Insurance', value: 'Insurance' },
                      { id: '3', name: 'Neutral', value: '__IS_NULL__' },
                      {
                        id: '4',
                        name: 'All industries',
                        value: '',
                        isShowAllOptions: true,
                      },
                    ]}
                    onSelected={async (item) => {
                      refIndustry.current = item.value
                      setCurrPage(1)
                      setTopicsDB([])
                      await searchDocuments(1)
                    }}
                    customHACK={true}
                  />
                  <div
                    style={{
                      marginLeft: -9,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-start pt-4">
              {isClickDelete && (
                <>
                  <span className="mr-4 text-[16px]">Are you sure?</span>
                  <button
                    data-testid="btn-delete-from-catalogue-yes"
                    onClick={onConfirmDelete}
                    className={getActionButtonStyle(isLoading)}
                  >
                    Yes
                  </button>
                  <span
                    data-testid="btn-delete-from-catalogue-no"
                    onClick={() => {
                      setIsClickDelete(false)
                      return false
                    }}
                    className="ml-4 cursor-pointer text-[16px] text-blue-800 underline hover:opacity-[0.8]"
                  >
                    Cancel
                  </span>
                </>
              )}
              {!isClickDelete && hasPermission(Permission.DeleteCatalog) && (
                <span
                  data-testid="btn-delete-from-catalogue"
                  onClick={onClickDelete}
                  className="mentem-link-underline"
                >
                  Delete from catalogue
                </span>
              )}
              <div
                style={{
                  flexGrow: 1,
                  // backgroundColor: '#eee',
                }}
              >
                <div style={{}} className="flex items-end justify-end">
                  {!isDebug() && (
                    <label
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        fontWeight: 600,
                        width: 122,
                      }}
                    >
                      <SwitchPublished
                        checked={!!refPublished.current}
                        onChange={(e) => {
                          refPublished.current = e.target.checked
                          setCurrPage(1)
                          setTopicsDB([])
                          searchDocuments(1)
                        }}
                        // inputProps={{ 'aria-label': 'Non-topics' }}
                        color="default"
                      />
                      {refPublished.current ? 'Published' : 'Preview'}
                    </label>
                  )}
                  <label
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontWeight: 600,
                      width: 135,
                    }}
                  >
                    {!isDebug() && (
                      <SwitchIsTopic
                        checked={!!refIsTopic.current}
                        onChange={(e) => {
                          refIsTopic.current = e.target.checked
                          setCurrPage(1)
                          setTopicsDB([])
                          searchDocuments(1)
                        }}
                        // inputProps={{ 'aria-label': 'Non-topics' }}
                        color="default"
                      />
                    )}
                    {refIsTopic.current ? 'Topics' : 'Non - topics'}
                    {isDebug() && (
                      <ButtonGroup
                        variant="outlined"
                        orientation="vertical"
                        aria-label="Non-topics button group"
                      >
                        <Button
                          style={debugButtonStyle(refIsTopic.current === false)}
                          onClick={() => {
                            refIsTopic.current = false
                            setCurrPage(1)
                            setTopicsDB([])
                            searchDocuments(1)
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          style={debugButtonStyle(refIsTopic.current === true)}
                          onClick={() => {
                            refIsTopic.current = true
                            setCurrPage(1)
                            setTopicsDB([])
                            searchDocuments(1)
                          }}
                        >
                          No
                        </Button>
                        <Button
                          style={debugButtonStyle(refIsTopic.current === null)}
                          onClick={() => {
                            refIsTopic.current = null
                            setCurrPage(1)
                            setTopicsDB([])
                            searchDocuments(1)
                          }}
                        >
                          Show All
                        </Button>
                      </ButtonGroup>
                    )}
                  </label>
                  <div className="w-1"></div>
                  <label
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontWeight: 600,
                      width: 120,
                    }}
                  >
                    {!isDebug() && (
                      <SwitchClientSpecific
                        checked={!!refClientSpecific.current}
                        onChange={(e) => {
                          refClientSpecific.current = e.target.checked
                          setCurrPage(1)
                          setTopicsDB([])
                          searchDocuments(1)
                        }}
                        // inputProps={{ 'aria-label': 'Client-specific' }}
                        color="default"
                      />
                    )}
                    {refClientSpecific.current ? 'Client - IP' : 'Reusable'}
                    {isDebug() && (
                      <ButtonGroup
                        variant="outlined"
                        orientation="vertical"
                        aria-label="Client-specific button group"
                      >
                        <Button
                          style={debugButtonStyle(
                            refClientSpecific.current === true,
                          )}
                          onClick={() => {
                            refClientSpecific.current = true
                            setCurrPage(1)
                            setTopicsDB([])
                            searchDocuments(1)
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          style={debugButtonStyle(
                            refClientSpecific.current === false,
                          )}
                          onClick={() => {
                            refClientSpecific.current = false
                            setCurrPage(1)
                            setTopicsDB([])
                            searchDocuments(1)
                          }}
                        >
                          No
                        </Button>
                        <Button
                          style={debugButtonStyle(
                            refClientSpecific.current === null,
                          )}
                          onClick={() => {
                            refClientSpecific.current = null
                            setCurrPage(1)
                            setTopicsDB([])
                            searchDocuments(1)
                          }}
                        >
                          Show All
                        </Button>
                      </ButtonGroup>
                    )}
                  </label>

                  <div className="w-2"></div>
                </div>
              </div>
            </div>
            {hasPermission(Permission.DeleteCatalog) && (
              <div className="h-[24px]"></div>
            )}
            {!hasPermission(Permission.DeleteCatalog) && (
              <div className="h-[8px]"></div>
            )}

            <div className="flex h-[100%] flex-col pt-[24px]">
              {isSearching && <Spinner />}
              {!isSearching && tableTopics.length === 0 && (
                <div className="flex flex-none justify-center">
                  <div className="flex flex-none items-center">
                    <span className="mr-4 text-[16px]">No topic(s) found.</span>
                  </div>
                </div>
              )}
              {!isSearching && tableTopics.length > 0 && (
                <>
                  <div
                    className="flex-1 overflow-y-auto"
                    onScroll={(e) => handleScroll(e)}
                  >
                    <table className="mentem-table mentem-table-highlight">
                      <thead>
                        <tr>
                          <th
                            className={`${alternateStyle(
                              'th',
                              true,
                            )} h-[55px] w-[24%] min-w-[217px]`}
                            style={{
                              zIndex: 2,
                            }}
                          >
                            <div className="flex flex-row items-center">
                              <span className="grow">Topic title</span>
                              <img
                                className="cursor-pointer"
                                src={sortSvg}
                                alt="sort"
                                onClick={() => onClickSort(SortBy.Topic)}
                                data-tooltip-id="tooltip-title"
                              />
                            </div>
                          </th>
                          <th className={`${alternateStyle('th')} w-[1%]`}>
                            Lock
                          </th>
                          <th
                            className={`${alternateStyle(
                              'th',
                            )} w-[9%] min-w-[120px]`}
                            style={{
                              zIndex: 1,
                            }}
                          >
                            <div className="flex flex-row items-center">
                              <span className="grow">Industry</span>
                            </div>
                          </th>
                          <th
                            className={`${alternateStyle(
                              'th',
                            )} w-[1%] min-w-[160px]`}
                          >
                            <div className="flex flex-row items-center">
                              <span className="grow">Editor</span>
                              <img
                                className="cursor-pointer"
                                src={sort2Svg}
                                alt="sort"
                                onClick={() => onClickSort(SortBy.PublishedBy)}
                                data-tooltip-id="tooltip-published-by"
                              />
                            </div>
                          </th>
                          <th
                            className={`${alternateStyle(
                              'th',
                            )} w-[1%] min-w-[149px]`}
                          >
                            <div className="flex flex-row items-center">
                              <span className="grow">Date/Time</span>
                              <img
                                className="cursor-pointer"
                                src={sortSvg}
                                alt="sort"
                                onClick={() => onClickSort(SortBy.DateTime)}
                                data-tooltip-id="tooltip-date-time"
                              />
                            </div>
                          </th>
                          <th className={`${alternateStyle('th')} w-[1%]`}>
                            Notes
                          </th>
                          {hasPublishColumn() && (
                            <th className={`${alternateStyle('th')} w-[1%]`}>
                              Publish
                            </th>
                          )}
                          <th className={`${alternateStyle('th')} w-[1%]`}>
                            More
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedDocuments.map((doc: Document) => {
                          return renderRow(doc, 0)
                        })}
                      </tbody>
                    </table>
                    {!isSearching && nResults > 100 && (
                      <span
                        style={{
                          float: 'right',
                          fontSize: 16,
                          marginTop: 14,
                          marginRight: 20,
                        }}
                      >
                        {nResults === pageSize ? (
                          <button
                            onClick={() => {
                              setCurrPage(1)
                              setTopicsDB([])
                              setPageSize(100)
                              searchDocuments(1, 100)
                            }}
                            style={
                              {
                                // whiteSpace: 'nowrap',
                              }
                            }
                          >
                            Top 100 results
                            <img
                              src={minusSvg}
                              alt="contract"
                              style={{
                                marginLeft: 10,
                                display: 'inline',
                                verticalAlign: 'middle',
                                width: 13,
                              }}
                            />
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setCurrPage(1)
                              setTopicsDB([])
                              setPageSize(nResults)
                              searchDocuments(1, nResults)
                            }}
                          >
                            All results
                            <img
                              src={plusSvg}
                              alt="expand"
                              style={{
                                marginLeft: 10,
                                display: 'inline',
                                verticalAlign: 'baseline',
                                width: 13,
                              }}
                            />
                          </button>
                        )}
                      </span>
                    )}
                    {/* todo: get rid of tooltips below. might not be in active use */}
                    <Tooltip
                      id="tooltip-title"
                      className="mentem-tooltip"
                      style={getTooptipStyle(SortBy.Topic)}
                      place="top"
                      noArrow={true}
                    >
                      {getSortingText(SortBy.Topic)}
                    </Tooltip>
                    <Tooltip
                      id="tooltip-published-by"
                      className="mentem-tooltip"
                      style={getTooptipStyle(SortBy.PublishedBy)}
                      place="top"
                      noArrow={true}
                    >
                      {getSortingText(SortBy.PublishedBy)}
                    </Tooltip>
                    <Tooltip
                      id="tooltip-date-time"
                      className="mentem-tooltip"
                      style={getTooptipStyle(SortBy.DateTime)}
                      place="top"
                      noArrow={true}
                    >
                      {getSortingText(SortBy.DateTime)}
                    </Tooltip>
                    <Tooltip
                      id="tooltip-industry"
                      className="mentem-tooltip"
                      style={getTooptipStyle(SortBy.Industry)}
                      place="top"
                      noArrow={true}
                    >
                      {getSortingText(SortBy.Industry)}
                    </Tooltip>
                  </div>
                </>
              )}
              {!isSearching && (
                <>
                  {/*<div*/}
                  {/*  style={{*/}
                  {/*    fontSize: 24,*/}
                  {/*    fontWeight: 700,*/}
                  {/*    textAlign: 'right',*/}
                  {/*    marginTop: 10,*/}
                  {/*    cursor: 'pointer',*/}
                  {/*  }}*/}
                  {/*  onClick={(x) => {*/}
                  {/*    // set the text to "hello"*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Loaded {Math.min(pageSize * currPage, nResults)} of{' '}*/}
                  {/*  {nResults} results*/}
                  {/*  <br />*/}
                  {/*  currPage = {currPage}*/}
                  {/*  <br />*/}
                  {/*  pageSize = {pageSize}*/}
                  {/*  <br />*/}
                  {/*  topicsDB.length = {topicsDB.length}*/}
                  {/*  <br />*/}
                  {/*  tableTopics.length = {tableTopics.length}*/}
                  {/*  <br />*/}
                  {/*  nResults = {nResults}*/}
                  {/*</div>*/}

                  {/*{nResults <= pageSize * currPage ? (*/}
                  {/*  <div>No more results left</div>*/}
                  {/*) : (*/}
                  {/*  <div*/}
                  {/*    style={{*/}
                  {/*      float: 'right',*/}
                  {/*      fontSize: 24,*/}
                  {/*      fontWeight: 700,*/}
                  {/*      textAlign: 'right',*/}
                  {/*      marginTop: 10,*/}
                  {/*      cursor: 'pointer',*/}
                  {/*    }}*/}
                  {/*    onClick={() => {*/}
                  {/*      setCurrPage(currPage + 1)*/}
                  {/*      searchDocuments(currPage + 1)*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    Load {pageSize} more results*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </SidebarLayout>
  )
}

export default Topics
