import { StyleUtil } from 'utils/StyleUtil'
import closeSvg from '../images/close2.svg'
import Spinner from './Spinner'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useApi } from 'providers/ApiProvider'
import DropDownMenu, { DropDownItem, DropDownMenuRefType } from './DropDownMenu'
import sortSvg from 'images/sort.svg'
import sort2Svg from 'images/sort2.svg'
import { Tooltip } from 'react-tooltip'
import { ToastUtil } from 'utils/ToastUtil'
import { GeneratedQuestion, QuizQuestion } from 'apis/entities/quiz.entity'
import { useAuth } from 'providers/AuthProvider'
import Path from 'routes/Path'
import { useNavigate } from 'react-router-dom'
import { getDateText, getTimeText } from 'utils/StringUtil'
import LargeSpinner from './LargeSpinner'
import Emitter, { Events } from 'core/emitter'
import {
  ArchClient,
  ArchCourse,
  ArchIntake,
  ArchModule,
  ArchProgram,
  ArchTopic,
} from 'apis/entities/architecture.entity'
import { sortIntakeOptionsByCreatedAt } from 'utils/ArchUtil'
import { useArchitectureApi } from 'providers/ArchitectureApiProvider'
import { Direction, DirectionAsc, DirectionDesc } from 'utils/TableUtils'
import ButtonSpinner from './ButtonSpinner'

const ClientOptionLabel = {
  id: '0',
  name: 'Clients',
  value: '0',
  isLabel: true,
}

const ProgramOptionLabel = {
  id: '0',
  name: 'Programs',
  value: '0',
  isLabel: true,
}

const IntakeOptionLabel = {
  id: '0',
  name: 'Intakes',
  value: '0',
  isLabel: true,
}

interface Props {
  multiple: boolean
  onGenerated: () => void
  onClose: () => void
  // for changing the topic of the question
  quizId?: string
  quizQuestion?: QuizQuestion
  onSaved?: () => void
}

export default function WidgetQuestionGenerator({
  multiple,
  onGenerated,
  onClose,
  quizId,
  quizQuestion,
  onSaved,
}: Props): JSX.Element {
  const navigate = useNavigate()
  const { getAuth0UserDetails } = useAuth()
  const [isFetched, setIsFetched] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const { generateMultipleQuestionsV2, createQuiz, updateQuizQuestion } =
    useApi()
  const { getArchClients, getArchClient } = useArchitectureApi()
  const [orderByTopic, setOrderByTopic] = useState<Direction>('')
  const [orderByPublishedBy, setOrderByPublishedBy] = useState<Direction>('')
  const [orderByDateTime, setOrderByDateTime] = useState<Direction>('')
  const refNumberOfQuestions = useRef<number>(30)
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [selectedTopicId, setSelectedTopicId] = useState<string>('')

  const [isFetchingClients, setIsFetchingClients] = useState(false)
  const [clients, setClients] = useState<ArchClient[]>([])

  const [clientOptions, setClientOptions] = useState<any>([ClientOptionLabel])
  const [programOptions, setProgramOptions] = useState<any>([
    ProgramOptionLabel,
  ])
  const [intakeOptions, setIntakeOptions] = useState<any>([IntakeOptionLabel])

  const refDropDownMenuClients = useRef<DropDownMenuRefType>(null)
  const refDropDownMenuPrograms = useRef<DropDownMenuRefType>(null)
  const refDropDownMenuIntakes = useRef<DropDownMenuRefType>(null)

  const [selectedClient, setSelectedClient] = useState<ArchClient | null>(null)
  const [selectedProgram, setSelectedProgram] = useState<ArchProgram | null>(
    null,
  )
  const [selectedIntake, setSelectedIntake] = useState<ArchIntake | null>(null)
  const [selectedCourse, setSelectedCourse] = useState<ArchCourse | null>(null)
  const [selectedModules, setSelectedModules] = useState<ArchModule[]>([])

  // for direct access without state
  const refSelectedClient = useRef<ArchClient | null>(null)
  const refSelectedProgram = useRef<ArchProgram | null>(null)
  const refSelectedIntake = useRef<ArchIntake | null>(null)
  const refSelectedCourse = useRef<ArchCourse | null>(null)
  const refSelectedModules = useRef<ArchModule[]>([])

  const [courses, setCourses] = useState<ArchCourse[]>([])
  const [modules, setModules] = useState<ArchModule[]>([])
  const [topics, setTopics] = useState<ArchTopic[]>([])

  const [isSaving, setIsSaving] = useState(false)
  const isChangingTopic = !!quizQuestion

  const tooltipStyle = {
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '6px',
  }

  const tooltipStyleReverse = {
    backgroundColor: '#6b69c1',
    color: '#fff',
    borderRadius: '6px',
  }

  enum SortBy {
    Topic = 'topic',
    PublishedBy = 'publishedUserName',
    DateTime = 'updatedAt',
  }

  const [sortConfig, setSortConfig] = useState<{
    key: SortBy
    direction: Direction
  } | null>(null)

  const getTooptipStyle = (key: SortBy): any => {
    switch (key) {
      case SortBy.Topic:
        return orderByTopic === DirectionAsc
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.PublishedBy:
        return orderByPublishedBy === DirectionAsc
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.DateTime:
        return orderByDateTime === DirectionAsc
          ? tooltipStyleReverse
          : tooltipStyle
      default:
        return tooltipStyle
    }
  }

  const getSortingText = (key: SortBy): string => {
    switch (key) {
      case SortBy.Topic:
        if (orderByTopic === DirectionAsc) return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.PublishedBy:
        if (orderByPublishedBy === DirectionAsc) return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.DateTime:
        if (orderByDateTime === DirectionAsc) return 'Sort new to old'
        return 'Sort old to new'
      default:
        return ''
    }
  }

  const createQuizByGenerator = useCallback(
    async (questions: GeneratedQuestion[]) => {
      try {
        const user = getAuth0UserDetails()
        const auth0Id = user?.id
        const username = user?.username
        const firstName = user?.firstName
        const email = user?.email

        const quizQuestions = questions.map((q, i) => {
          return {
            text: q.questionText,
            learningOutcome: q.learningOutcome,
            answers: [q.correctAnswer, q.incorrectAnswer1, q.incorrectAnswer2],
            archTopicId: q.archTopicId,
            position: i + 1,
          } as QuizQuestion
        })

        const quiz = await createQuiz(
          ' ', // space for no title
          '',
          auth0Id,
          username,
          firstName,
          email,
          quizQuestions,
        )
        if (quiz) {
          onGenerated()
          navigate(`${Path.quizzes.path}/${quiz.id}`)
        }
      } catch (error) {
        console.error('Failed to create quiz by generator', error)
      } finally {
        setIsGenerating(false)
      }
    },
    [getAuth0UserDetails, createQuiz, onGenerated, navigate],
  )

  const onClickSort = (key: SortBy) => {
    let ascending = true
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === DirectionAsc
    ) {
      ascending = false
    }

    switch (key) {
      case SortBy.Topic:
        setOrderByTopic(ascending ? DirectionAsc : DirectionDesc)
        break
      case SortBy.PublishedBy:
        setOrderByPublishedBy(ascending ? DirectionAsc : DirectionDesc)
        break
      case SortBy.DateTime:
        setOrderByDateTime(ascending ? DirectionAsc : DirectionDesc)
        break
      default:
        break
    }

    setSortConfig({ key, direction: ascending ? DirectionAsc : DirectionDesc })
  }

  const onSelectNumberOfQuestions = (item: DropDownItem) => {
    const value = item.value as number
    refNumberOfQuestions.current = value
  }

  const onClickGenerate = async () => {
    if (multiple) {
      generateMultipleQuestions()
    } else {
      generateNewQuestion()
    }
  }

  const canGenerate = (): boolean => {
    if (multiple) {
      return selectedModules.length > 0 && checkedIds.length > 0
    } else {
      return !!selectedTopicId
    }
  }

  const generateNewQuestion = async () => {
    console.log('selectedTopicId', selectedTopicId)
    if (!selectedTopicId) {
      ToastUtil.warning('Please select a topic')
      return
    }
    Emitter.emit(Events.GenerateNewQuestion, {
      topicId: selectedTopicId,
    })
    onClose()
  }

  const generateMultipleQuestions = async () => {
    const topicIds = checkedIds
    // console.log('topicIds', topicIds)
    const numberOfQuestions = refNumberOfQuestions.current
    console.log('numberOfQuestions', numberOfQuestions)
    if (topicIds.length === 0) {
      ToastUtil.warning('Please select a module which has topics')
      return
    }
    if (!numberOfQuestions) {
      ToastUtil.warning('Please select number of questions')
      return
    }

    try {
      setIsGenerating(true)
      const res = await generateMultipleQuestionsV2(topicIds, numberOfQuestions)
      console.log('res', res)
      const questions = res.questions
      console.log('questions', questions)
      await createQuizByGenerator(questions as GeneratedQuestion[])
    } catch (error) {
      console.error('Failed to generate multiple questions', error)
      ToastUtil.error('Failed to generate questions')
    } finally {
      setIsGenerating(false)
    }
  }

  const updateProgramOptions = () => {
    // set all program options by distinct program names
    const tmpProgramOptions: any[] = []

    const programs = refSelectedClient.current?.programs || []
    programs.forEach((program) => {
      if (!tmpProgramOptions.find((p) => p.name === program.name)) {
        tmpProgramOptions.push({
          id: program.id,
          name: program.name,
          value: program.id,
        })
      }
    })
    // console.log('tmpProgramOptions', tmpProgramOptions)

    // sort program options by alphabet ascending
    tmpProgramOptions.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

    setProgramOptions([ProgramOptionLabel, ...tmpProgramOptions])
  }

  const updateIntakeOptions = () => {
    // set all intake options by distinct intake names
    const tmpIntakeOptions: any[] = []

    // show selected program's intakes
    const program = refSelectedProgram.current
    if (!program) {
      // clear intake options
      setIntakeOptions([IntakeOptionLabel])
      return
    }

    program.intakes.forEach((intake) => {
      if (!tmpIntakeOptions.find((i) => i.name === intake.name)) {
        tmpIntakeOptions.push({
          id: intake.id,
          name: intake.name,
          value: intake.id,
          createdAt: intake.createdAt,
        })
      }
    })

    // sort intake options by date first, otherwise by alphabet ascending
    const sortedTmpIntakeOptions =
      sortIntakeOptionsByCreatedAt(tmpIntakeOptions)

    // console.log('sortedTmpIntakeOptions', sortedTmpIntakeOptions)
    setIntakeOptions([IntakeOptionLabel, ...sortedTmpIntakeOptions])
  }

  const updateCourses = () => {
    setCourses(
      refSelectedIntake.current ? refSelectedIntake.current.courses : [],
    )
    setSelectedCourse(null)
  }

  const updateModules = () => {
    // console.log('updateModules', refSelectedCourse.current?.modules)
    setModules(
      refSelectedCourse.current ? refSelectedCourse.current.modules : [],
    )
    setSelectedModules([])
  }

  const onSelectClient = async (item: DropDownItem) => {
    const value = item.value
    if (value === '0') {
      // label
      return
    } else {
      // selected client
      setIsLoading(true)
      const client = await getArchClient(value)
      setIsLoading(false)

      if (client) {
        setSelectedClient(client)
        refSelectedClient.current = client
        // reset others
        setSelectedProgram(null)
        refSelectedProgram.current = null
        setSelectedIntake(null)
        refSelectedIntake.current = null
        setSelectedCourse(null)
        refSelectedCourse.current = null
        setSelectedModules([])
        refSelectedModules.current = []
      }
    }

    updateProgramOptions()
    updateIntakeOptions()
    updateCourses()
    updateModules()
  }

  const onSelectProgram = (item: DropDownItem) => {
    const value = item.value
    if (value === '0') {
      // label
      return
    } else {
      const program = refSelectedClient.current?.programs.find(
        (p) => p.id === value,
      )
      if (program) {
        setSelectedProgram(program)
        refSelectedProgram.current = program
      }
    }

    // reset intake options
    setSelectedIntake(null)
    // reset dropdown
    refDropDownMenuIntakes.current?.setSelectedItemId(IntakeOptionLabel.id)

    updateIntakeOptions()
    updateCourses()
    updateModules()
  }

  const onSelectIntake = (item: DropDownItem) => {
    const value = item.value
    if (value === '0') {
      // label
      return
    } else {
      const intake = refSelectedClient.current?.programs
        .map((p) => p.intakes)
        .flat()
        .find((i) => i.id === value)
      if (intake) {
        setSelectedIntake(intake)
        refSelectedIntake.current = intake
      }
    }

    updateCourses()
    updateModules()
  }

  const onSelectCourse = (courseId: string) => {
    const course = courses.find((c) => c.id === courseId)
    setSelectedCourse(course || null)
    refSelectedCourse.current = course || null
    // reset selected modules
    setSelectedModules([])
    refSelectedModules.current = []
    updateModules()
    // reset checkedIds
    setCheckedIds([])
  }

  const onSelectMultipleModules = (e: any) => {
    const checked = e.target.checked
    const moduleId = e.target.value
    const module = modules.find((m) => m.id === moduleId)
    const newSelectedModules = []
    if (module) {
      if (checked) {
        // add to selected modules
        newSelectedModules.push(...selectedModules, { ...module })
        setSelectedModules(newSelectedModules)
      } else {
        // remove from selected modules
        newSelectedModules.push(
          ...selectedModules.filter((m) => m.id !== moduleId),
        )
        setSelectedModules(newSelectedModules)
      }
    }

    // console.log('selectedModules', newSelectedModules)
    // get topic ids from topics in selected modules for generating questions
    const topicIds = newSelectedModules
      .map((m) => m.topics)
      .flat()
      .filter((t) => t.id)
      .map((t) => t.id)
    setCheckedIds(topicIds)
  }

  const onSelectSingleModule = (e: any) => {
    const moduleId = e.target.value
    const module = modules.find((m) => m.id === moduleId)
    setSelectedModules(module ? [module] : [])
    setTopics(module ? module.topics : [])
  }

  const renderRow = (topic: ArchTopic, index: number) => {
    return (
      <tr key={index}>
        <td className="mentem-table-cell-2 mentem-title-column">
          <div className="flex flex-row items-center justify-start">
            <input
              type="radio"
              name="topicId"
              className="self-center"
              value={topic.id || ''}
              onChange={(e) => setSelectedTopicId(e.target.value)}
            />
            {topic.link ? (
              <a
                className="mentem-link pl-2 underline"
                href={topic.link}
                target="_blank"
                rel="noreferrer"
              >
                {topic.name || '(No title)'}
              </a>
            ) : (
              <span className="pl-2">{topic.name || '(No title)'}</span>
            )}
          </div>
        </td>
        <td className="mentem-table-cell-1 text-center">
          <div>{topic.document?.publishedUserName || ''}</div>
        </td>
        <td className="mentem-table-cell-2 text-center">
          <div>
            <div className="flex items-center justify-center">
              {topic.document?.updatedAt &&
                getDateText(topic.document.updatedAt)}
            </div>
            <div className="flex items-center justify-center">
              {topic.document?.updatedAt &&
                getTimeText(topic.document.updatedAt)}
            </div>
          </div>
        </td>
      </tr>
    )
  }

  const fetchClients = useCallback(
    async (silent: boolean = false) => {
      if (!silent) {
        setIsFetchingClients(true)
      }
      try {
        setIsLoading(true)
        setIsFetched(false)
        const clients = await getArchClients()
        setClients(clients)

        // Set client options
        const allOptions = clients.map((client) => ({
          id: client.id,
          name: client.name,
          value: client.id,
          default: false,
        }))

        // sort by alphabet
        allOptions.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })

        setClientOptions([ClientOptionLabel, ...allOptions])

        updateProgramOptions()
        updateIntakeOptions()
        updateCourses()
        updateModules()
      } catch (error) {
        ToastUtil.error('Failed to fetch clients')
      } finally {
        setIsFetchingClients(false)
        setIsFetched(true)
        setIsLoading(false)
      }
    },
    [getArchClients],
  )

  const onChangeTopic = async () => {
    if (!quizQuestion || !quizId) {
      ToastUtil.warning('No question to change topic')
      return
    }
    if (!selectedTopicId) {
      ToastUtil.warning('Please select a topic')
      return
    }
    // console.log('onChangeTopic', quizQuestion)
    try {
      setIsSaving(true)
      const result = await updateQuizQuestion(quizId, quizQuestion.id, {
        archTopicId: selectedTopicId,
      })
      ToastUtil.success('Topic updated')

      // emit event to update the topic in the question view
      Emitter.emit(Events.UpdatedQuizQuestionTopic, {
        questionId: quizQuestion.id,
        question: result,
      })

      onSaved && onSaved()
    } catch (error) {
      console.error('Failed to change topic', error)
      ToastUtil.error('Failed to change topic')
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    fetchClients()
  }, [fetchClients])

  return (
    <div className="w-full h-full absolute bg-[#D5D5DBAA] z-[999] flex flex-col items-center justify-center">
      <div className="max-h-[90%] min-h-[50%] w-3/4 flex flex-col bg-white shadow-xl rounded-[12px] p-[24px] gap-[12px] pb-[24px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={onClose}
        />
        {isGenerating && (
          <LargeSpinner text="Generating questions. This may take a couple of minutes, please be patient." />
        )}
        {!isGenerating && isFetched && (
          <>
            <div
              className={`flex flex-col items-start justify-start w-full gap-4 px-[12px] ${
                multiple ? 'max-h-[90%]' : 'max-h-[50%]'
              }`}
            >
              {!isFetchingClients && clients.length > 0 && (
                <>
                  <div className="flex flex-row items-center gap-[24px] w-full">
                    <div className="w-[20%]">
                      <DropDownMenu
                        items={clientOptions}
                        onSelected={onSelectClient}
                        border={true}
                        ref={refDropDownMenuClients}
                      />
                    </div>
                    <div className="w-[60%]">
                      <DropDownMenu
                        items={programOptions}
                        onSelected={onSelectProgram}
                        border={true}
                        ref={refDropDownMenuPrograms}
                      />
                    </div>
                    <div className="w-[20%]">
                      <DropDownMenu
                        items={intakeOptions}
                        onSelected={onSelectIntake}
                        border={true}
                        ref={refDropDownMenuIntakes}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-[12px] w-full overflow-auto max-h-[100%]">
                    {selectedClient && selectedProgram && selectedIntake && (
                      <div className="flex flex-wrap gap-[12px] w-full">
                        {courses?.map((course, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-start gap-[6px] cursor-pointer"
                          >
                            <input
                              id={`course-${course.id}`}
                              type="radio"
                              name="courseId"
                              className="radio-primary"
                              value={course.id}
                              checked={selectedCourse?.id === course.id}
                              onChange={(e) => onSelectCourse(e.target.value)}
                            />
                            <div
                              className="flex flex-row gap-[4px] font-roboto text-sm font-semibold normal-case"
                              onClick={() => {
                                // simulate to select radio button by getting the id
                                // console.log('course.id', course.id)
                                const id = `course-${course.id}`
                                const radio = document.getElementById(id)
                                if (radio) {
                                  radio.click()
                                }
                              }}
                            >
                              <span className="text-[#BA61FF]">
                                Course {(index + 1).toString().padStart(2, '0')}
                                :
                              </span>
                              <span>{course.name}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {selectedCourse && modules && modules.length > 0 && (
                      <div className="flex flex-wrap gap-[12px] w-full">
                        {modules.map((module, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-start gap-[6px] cursor-pointer"
                          >
                            {multiple ? (
                              <input
                                id={`module-${module.id}`}
                                type="checkbox"
                                value={module.id}
                                checked={selectedModules.some(
                                  (m) => m.id === module.id,
                                )}
                                onChange={(e) => {
                                  onSelectMultipleModules(e)
                                }}
                              />
                            ) : (
                              <input
                                id={`module-${module.id}`}
                                type="radio"
                                name="moduleId"
                                className="radio-small"
                                value={module.id}
                                checked={selectedModules.some(
                                  (m) => m.id === module.id,
                                )}
                                onChange={(e) => {
                                  onSelectSingleModule(e)
                                }}
                              />
                            )}
                            <span
                              className="font-roboto text-sm font-semibold normal-case"
                              onClick={() => {
                                // simulate to select input by getting the id
                                // console.log('module.id', module.id)
                                const id = `module-${module.id}`
                                const input = document.getElementById(
                                  id,
                                ) as HTMLInputElement
                                if (input) {
                                  input.click()
                                }
                              }}
                            >
                              Module {(index + 1).toString().padStart(2, '0')}:{' '}
                              {module.name}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              )}

              {isFetched &&
                selectedIntake &&
                courses &&
                courses.length === 0 && (
                  <p className="self-center">No courses available</p>
                )}

              {isFetched &&
                selectedIntake &&
                selectedCourse &&
                modules &&
                modules.length === 0 && (
                  <p className="self-center">No modules available</p>
                )}
            </div>

            {isFetched &&
              !multiple &&
              selectedModules &&
              selectedModules.length > 0 && (
                <div className="my-[6px] overflow-auto min-h-[30%] max-h-[50%] grow">
                  <table className="mentem-table">
                    <thead>
                      <tr>
                        <th
                          className={`${StyleUtil.stickyTableHeader1} h-[55px] w-[50%] min-w-[200px]`}
                        >
                          <div className="flex flex-row items-center px-2">
                            <span className="grow">Existing topics</span>
                            <img
                              className="cursor-pointer"
                              src={sortSvg}
                              alt="sort"
                              onClick={() => onClickSort(SortBy.Topic)}
                              data-tooltip-id="tooltip-topic"
                            />
                          </div>
                        </th>
                        <th
                          className={`${StyleUtil.stickyTableHeader2} w-[25%] min-w-[150px]`}
                        >
                          <div className="flex flex-row items-center px-2">
                            <span className="grow">Created by</span>
                            <img
                              className="cursor-pointer"
                              src={sortSvg}
                              alt="sort"
                              onClick={() => onClickSort(SortBy.PublishedBy)}
                              data-tooltip-id="tooltip-published-by"
                            />
                          </div>
                        </th>
                        <th
                          className={`${StyleUtil.stickyTableHeader1} w-[25%] min-w-[160px]`}
                        >
                          <div className="flex flex-row items-center px-2">
                            <span className="grow">Last updated</span>
                            <img
                              className="cursor-pointer pl-2 pr-2"
                              src={sort2Svg}
                              alt="sort"
                              onClick={() => onClickSort(SortBy.DateTime)}
                              data-tooltip-id="tooltip-date-time"
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {topics.map((topic, index: number) => {
                        return renderRow(topic, index)
                      })}
                    </tbody>
                  </table>
                  <Tooltip
                    id="tooltip-topic"
                    className="mentem-tooltip"
                    style={getTooptipStyle(SortBy.Topic)}
                    place="top"
                    noArrow={true}
                  >
                    {getSortingText(SortBy.Topic)}
                  </Tooltip>
                  <Tooltip
                    id="tooltip-published-by"
                    className="mentem-tooltip"
                    style={getTooptipStyle(SortBy.PublishedBy)}
                    place="top"
                    noArrow={true}
                  >
                    {getSortingText(SortBy.PublishedBy)}
                  </Tooltip>
                  <Tooltip
                    id="tooltip-date-time"
                    className="mentem-tooltip"
                    style={getTooptipStyle(SortBy.DateTime)}
                    place="top"
                    noArrow={true}
                  >
                    {getSortingText(SortBy.DateTime)}
                  </Tooltip>
                </div>
              )}

            <div className="flex flex-row items-center justify-center gap-4 w-full">
              {multiple && (
                <>
                  <p className="mentem-widget-title">
                    Number of questions to generate
                  </p>
                  <DropDownMenu
                    items={[
                      // start from 10 to 30 numbers interval 5
                      ...Array.from({ length: 5 }, (_, i) => i).map((i) => {
                        const value = i * 5 + 10
                        return {
                          id: value.toString(),
                          name: value.toString(),
                          value: value,
                          default: value === 30,
                        }
                      }),
                    ]}
                    onSelected={onSelectNumberOfQuestions}
                  />
                </>
              )}
              <div className="grow" />
              {isChangingTopic ? (
                <button
                  type="button"
                  className={StyleUtil.buttonPrimary}
                  onClick={onChangeTopic}
                  disabled={isSaving}
                >
                  {isSaving ? <ButtonSpinner /> : 'Save'}
                </button>
              ) : (
                <button
                  type="button"
                  className={StyleUtil.buttonPrimary}
                  onClick={onClickGenerate}
                  disabled={isGenerating || !canGenerate()}
                >
                  {isGenerating ? (
                    <ButtonSpinner />
                  ) : multiple ? (
                    'Auto-generate'
                  ) : (
                    'Generate question'
                  )}
                </button>
              )}
            </div>
          </>
        )}
        {isLoading && <Spinner />}
      </div>
    </div>
  )
}
