import { useContext, ReactNode, createContext, useRef } from 'react'
import { BaseResponse } from 'apis/entities/base-response.entity'
import {
  ArchCheckTopicsResponse,
  ArchClient,
  ArchCourse,
  ArchForTableResponse,
  ArchIntake,
  ArchModule,
  ArchProgram,
  ArchSearchResult,
  ArchTopic,
} from 'apis/entities/architecture.entity'

type Props = {
  children: ReactNode
}

interface ApiValue {
  setToken: (token: string | null) => void
  getArchClients(): Promise<ArchClient[]>
  getArchClient(id: string): Promise<ArchClient>
  createArchClient(name: string): Promise<ArchClient>
  updateArchClient(
    id: string,
    name?: string,
    positions?: string[],
  ): Promise<BaseResponse>
  deleteArchClient(id: string): Promise<BaseResponse>
  createArchProgram(
    clientId: string,
    name: string,
    description?: string,
  ): Promise<ArchProgram>
  updateArchProgram(
    programId: string,
    name?: string,
    description?: string,
    positions?: string[],
  ): Promise<BaseResponse>
  deleteArchProgram(programId: string): Promise<BaseResponse>
  createArchIntake(programId: string, name: string): Promise<ArchIntake>
  updateArchIntake(
    intakeId: string,
    name?: string,
    positions?: string[],
  ): Promise<BaseResponse>
  deleteArchIntake(intakeId: string): Promise<BaseResponse>
  createArchCourse(
    intakeId: string,
    name: string,
    description?: string,
  ): Promise<ArchCourse>
  updateArchCourse(
    courseId: string,
    name?: string,
    description?: string,
    positions?: string[],
  ): Promise<BaseResponse>
  deleteArchCourse(courseId: string): Promise<BaseResponse>
  createArchModule(courseId: string, name: string): Promise<ArchModule>
  updateArchModule(
    moduleId: string,
    name?: string,
    positions?: string[],
  ): Promise<BaseResponse>
  deleteArchModule(moduleId: string): Promise<BaseResponse>
  createArchTopic(
    moduleId: string,
    name: string,
    learningOutcome?: string,
    link?: string,
  ): Promise<ArchTopic>
  updateArchTopic(
    topicId: string,
    name?: string,
    learningOutcome?: string,
    link?: string,
  ): Promise<ArchTopic>
  deleteArchTopic(topicId: string): Promise<BaseResponse>
  changeArchClient(programId: string, clientId: string): Promise<BaseResponse>
  changeArchProgram(intakeId: string, programId: string): Promise<BaseResponse>
  changeArchIntake(courseId: string, intakeId: string): Promise<BaseResponse>
  changeArchCourse(moduleId: string, courseId: string): Promise<BaseResponse>
  changeArchModule(topicId: string, moduleId: string): Promise<BaseResponse>
  cloneArchClient(id: string): Promise<ArchClient>
  cloneArchProgram(programId: string, clientId: string): Promise<ArchProgram>
  cloneArchIntake(intakeId: string, programId: string): Promise<ArchIntake>
  cloneArchCourse(courseId: string, intakeId: string): Promise<ArchCourse>
  cloneArchModule(moduleId: string, courseId: string): Promise<ArchModule>
  cloneArchTopic(topicId: string, moduleId: string): Promise<ArchTopic>
  moveArchProgram(programId: string, clientId: string): Promise<BaseResponse>
  moveArchIntake(intakeId: string, programId: string): Promise<BaseResponse>
  moveArchCourse(courseId: string, intakeId: string): Promise<BaseResponse>
  moveArchModule(moduleId: string, courseId: string): Promise<BaseResponse>
  moveArchTopic(topicId: string, moduleId: string): Promise<BaseResponse>
  searchArch(q: string): Promise<ArchSearchResult>
  uploadCourseExcel(courseId: string, file: File): Promise<BaseResponse>
  uploadIntakeExcel(intakeId: string, file: File): Promise<BaseResponse>
  getArchForTable(): Promise<ArchForTableResponse>
  getArchCourse(id: string): Promise<ArchCourse>
  exportArchExcel(
    programId: string,
    intakeId: string,
    courseId?: string,
  ): Promise<any>
  checkTopics(courseId: string): Promise<ArchCheckTopicsResponse>
  getCheckTopicsResults(courseId: string): Promise<ArchCheckTopicsResponse>
  toggleLockedForIntake(
    intakeId: string,
    locked: boolean,
  ): Promise<BaseResponse>
  crawlDocumentUrl(url: string): Promise<{
    title: string
    description: string
    learningOutcomes: string[]
  }>
}

export const ArchitectureApiContext = createContext<ApiValue | null>(null)

export function useArchitectureApi(): ApiValue {
  const state = useContext(ArchitectureApiContext)
  if (!state) {
    throw new Error('useApi must be used within ArchitectureApiProvider')
  }
  return state
}

export function ArchitectureApiProvider({ children }: Props) {
  const headers = { 'Content-Type': 'application/json' }
  const refToken = useRef<string | null>(null)
  const refHeaders = useRef<any>(headers)
  const documentHost = process.env.REACT_APP_DOCUMENT_API_URL_2

  const setToken = (token: string | null) => {
    refToken.current = token
    refHeaders.current = {
      ...headers,
      Authorization: `Bearer ${token}`,
    }
  }

  const getArchClients = async (): Promise<ArchClient[]> => {
    const res = await fetch(`${documentHost}/architectures/clients`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const getArchClient = async (clientId: string): Promise<ArchClient> => {
    const res = await fetch(
      `${documentHost}/architectures/clients/${clientId}`,
      {
        method: 'GET',
        headers: refHeaders.current,
      },
    )
    return res.json()
  }

  const createArchClient = async (name: string): Promise<ArchClient> => {
    const res = await fetch(`${documentHost}/architectures/clients`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ name }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const updateArchClient = async (
    clientId: string,
    name?: string,
    positions?: string[],
  ): Promise<BaseResponse> => {
    const body = {
      ...(name && { name }),
      ...(positions && { positions }),
    }
    const res = await fetch(
      `${documentHost}/architectures/clients/${clientId}`,
      {
        method: 'PATCH',
        headers: refHeaders.current,
        body: JSON.stringify(body),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const deleteArchClient = async (clientId: string): Promise<BaseResponse> => {
    const res = await fetch(
      `${documentHost}/architectures/clients/${clientId}`,
      {
        method: 'DELETE',
        headers: refHeaders.current,
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const createArchProgram = async (
    clientId: string,
    name: string,
    description?: string,
  ): Promise<ArchProgram> => {
    const res = await fetch(`${documentHost}/architectures/programs`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ name, description, clientId }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const updateArchProgram = async (
    programId: string,
    name?: string,
    description?: string,
    positions?: string[],
  ): Promise<BaseResponse> => {
    const body = {
      ...(name && { name }),
      ...(description && { description }),
      ...(positions && { positions }),
    }
    const res = await fetch(
      `${documentHost}/architectures/programs/${programId}`,
      {
        method: 'PATCH',
        headers: refHeaders.current,
        body: JSON.stringify(body),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const deleteArchProgram = async (
    programId: string,
  ): Promise<BaseResponse> => {
    const res = await fetch(
      `${documentHost}/architectures/programs/${programId}`,
      {
        method: 'DELETE',
        headers: refHeaders.current,
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const createArchIntake = async (
    programId: string,
    name: string,
  ): Promise<ArchIntake> => {
    const res = await fetch(`${documentHost}/architectures/intakes`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ name, programId }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const updateArchIntake = async (
    intakeId: string,
    name?: string,
    positions?: string[],
  ): Promise<BaseResponse> => {
    const body = {
      ...(name && { name }),
      ...(positions && { positions }),
    }
    const res = await fetch(
      `${documentHost}/architectures/intakes/${intakeId}`,
      {
        method: 'PATCH',
        headers: refHeaders.current,
        body: JSON.stringify(body),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const deleteArchIntake = async (intakeId: string): Promise<BaseResponse> => {
    const res = await fetch(
      `${documentHost}/architectures/intakes/${intakeId}`,
      {
        method: 'DELETE',
        headers: refHeaders.current,
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const createArchCourse = async (
    intakeId: string,
    name: string,
    description?: string,
  ): Promise<ArchCourse> => {
    const res = await fetch(`${documentHost}/architectures/courses`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ name, description, intakeId }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const updateArchCourse = async (
    courseId: string,
    name?: string,
    description?: string,
    positions?: string[],
  ): Promise<BaseResponse> => {
    const body = {
      ...(name && { name }),
      ...(description && { description }),
      ...(positions && { positions }),
    }
    const res = await fetch(
      `${documentHost}/architectures/courses/${courseId}`,
      {
        method: 'PATCH',
        headers: refHeaders.current,
        body: JSON.stringify(body),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const deleteArchCourse = async (courseId: string): Promise<BaseResponse> => {
    const res = await fetch(
      `${documentHost}/architectures/courses/${courseId}`,
      {
        method: 'DELETE',
        headers: refHeaders.current,
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const createArchModule = async (
    courseId: string,
    name: string,
  ): Promise<ArchModule> => {
    const res = await fetch(`${documentHost}/architectures/modules`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ name, courseId }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const updateArchModule = async (
    moduleId: string,
    name?: string,
    positions?: string[],
  ): Promise<BaseResponse> => {
    const body = {
      ...(name && { name }),
      ...(positions && { positions }),
    }
    const res = await fetch(
      `${documentHost}/architectures/modules/${moduleId}`,
      {
        method: 'PATCH',
        headers: refHeaders.current,
        body: JSON.stringify(body),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const deleteArchModule = async (moduleId: string): Promise<BaseResponse> => {
    const res = await fetch(
      `${documentHost}/architectures/modules/${moduleId}`,
      {
        method: 'DELETE',
        headers: refHeaders.current,
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const createArchTopic = async (
    moduleId: string,
    name: string,
    learningOutcome?: string,
    link?: string,
  ): Promise<ArchTopic> => {
    const res = await fetch(`${documentHost}/architectures/topics`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({
        name,
        learningOutcome,
        link,
        moduleId,
      }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const updateArchTopic = async (
    topicId: string,
    name?: string,
    learningOutcome?: string,
    link?: string,
  ): Promise<ArchTopic> => {
    const res = await fetch(`${documentHost}/architectures/topics/${topicId}`, {
      method: 'PATCH',
      headers: refHeaders.current,
      body: JSON.stringify({ name, learningOutcome, link }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const deleteArchTopic = async (topicId: string): Promise<BaseResponse> => {
    const res = await fetch(`${documentHost}/architectures/topics/${topicId}`, {
      method: 'DELETE',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const changeArchClient = async (programId: string, clientId: string) => {
    const res = await fetch(
      `${documentHost}/architectures/programs/${programId}/change-client`,
      {
        method: 'POST',
        headers: refHeaders.current,
        body: JSON.stringify({ clientId }),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const changeArchProgram = async (intakeId: string, programId: string) => {
    const res = await fetch(
      `${documentHost}/architectures/intakes/${intakeId}/change-program`,
      {
        method: 'POST',
        headers: refHeaders.current,
        body: JSON.stringify({ programId }),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const changeArchIntake = async (courseId: string, intakeId: string) => {
    const res = await fetch(
      `${documentHost}/architectures/courses/${courseId}/change-intake`,
      {
        method: 'POST',
        headers: refHeaders.current,
        body: JSON.stringify({ intakeId }),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const changeArchCourse = async (moduleId: string, courseId: string) => {
    const res = await fetch(
      `${documentHost}/architectures/modules/${moduleId}/change-course`,
      {
        method: 'POST',
        headers: refHeaders.current,
        body: JSON.stringify({ courseId }),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const changeArchModule = async (topicId: string, moduleId: string) => {
    const res = await fetch(
      `${documentHost}/architectures/topics/${topicId}/change-module`,
      {
        method: 'POST',
        headers: refHeaders.current,
        body: JSON.stringify({ moduleId }),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const cloneArchClient = async (id: string): Promise<ArchClient> => {
    const res = await fetch(`${documentHost}/architectures/clone-client`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ id }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const cloneArchProgram = async (
    programId: string,
    clientId: string,
  ): Promise<ArchProgram> => {
    const res = await fetch(`${documentHost}/architectures/clone-program`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ programId, clientId }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const cloneArchIntake = async (
    intakeId: string,
    programId: string,
  ): Promise<ArchIntake> => {
    const res = await fetch(`${documentHost}/architectures/clone-intake`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ intakeId, programId }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const cloneArchCourse = async (
    courseId: string,
    intakeId: string,
  ): Promise<ArchCourse> => {
    const res = await fetch(`${documentHost}/architectures/clone-course`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ courseId, intakeId }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const cloneArchModule = async (
    moduleId: string,
    courseId: string,
  ): Promise<ArchModule> => {
    const res = await fetch(`${documentHost}/architectures/clone-module`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ moduleId, courseId }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const cloneArchTopic = async (
    topicId: string,
    moduleId: string,
  ): Promise<ArchTopic> => {
    const res = await fetch(`${documentHost}/architectures/clone-topic`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ topicId, moduleId }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const moveArchProgram = async (programId: string, clientId: string) => {
    const res = await fetch(
      `${documentHost}/architectures/programs/${programId}`,
      {
        method: 'PATCH',
        headers: refHeaders.current,
        body: JSON.stringify({ clientId }),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const moveArchIntake = async (intakeId: string, programId: string) => {
    const res = await fetch(
      `${documentHost}/architectures/intakes/${intakeId}`,
      {
        method: 'PATCH',
        headers: refHeaders.current,
        body: JSON.stringify({ programId }),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const moveArchCourse = async (courseId: string, intakeId: string) => {
    const res = await fetch(
      `${documentHost}/architectures/courses/${courseId}`,
      {
        method: 'PATCH',
        headers: refHeaders.current,
        body: JSON.stringify({ intakeId }),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const moveArchModule = async (moduleId: string, courseId: string) => {
    const res = await fetch(
      `${documentHost}/architectures/modules/${moduleId}`,
      {
        method: 'PATCH',
        headers: refHeaders.current,
        body: JSON.stringify({ courseId }),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const moveArchTopic = async (topicId: string, moduleId: string) => {
    const res = await fetch(`${documentHost}/architectures/topics/${topicId}`, {
      method: 'PATCH',
      headers: refHeaders.current,
      body: JSON.stringify({ moduleId }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const searchArch = async (q: string): Promise<ArchSearchResult> => {
    const res = await fetch(`${documentHost}/architectures/search?q=${q}`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const uploadCourseExcel = async (
    courseId: string,
    file: File,
  ): Promise<BaseResponse> => {
    const formData = new FormData()
    formData.append('file', file)
    const res = await fetch(
      `${documentHost}/architectures/courses/${courseId}/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${refToken.current}`,
        },
        body: formData,
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const uploadIntakeExcel = async (
    intakeId: string,
    file: File,
  ): Promise<BaseResponse> => {
    const formData = new FormData()
    formData.append('file', file)
    const res = await fetch(
      `${documentHost}/architectures/intakes/${intakeId}/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${refToken.current}`,
        },
        body: formData,
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  // for default table
  const getArchForTable = async (): Promise<ArchForTableResponse> => {
    const res = await fetch(`${documentHost}/architectures`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const getArchCourse = async (id: string): Promise<ArchCourse> => {
    const res = await fetch(`${documentHost}/architectures/courses/${id}`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const exportArchExcel = async (
    programId: string,
    intakeId: string,
    courseId?: string,
  ): Promise<any> => {
    const res = await fetch(`${documentHost}/architectures/export`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ programId, intakeId, courseId }),
    })
    return res.blob()
  }

  const checkTopics = async (
    courseId: string,
  ): Promise<ArchCheckTopicsResponse> => {
    const res = await fetch(
      `${documentHost}/architectures/courses/${courseId}/check-topics?v=2`,
      {
        method: 'POST',
        headers: refHeaders.current,
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const getCheckTopicsResults = async (
    courseId: string,
  ): Promise<ArchCheckTopicsResponse> => {
    const res = await fetch(
      `${documentHost}/architectures/courses/${courseId}/check-topics`,
      {
        method: 'GET',
        headers: refHeaders.current,
      },
    )
    return res.json()
  }

  const toggleLockedForIntake = async (
    intakeId: string,
    locked: boolean,
  ): Promise<BaseResponse> => {
    const res = await fetch(
      `${documentHost}/architectures/intakes/${intakeId}/locked`,
      {
        method: 'PATCH',
        headers: refHeaders.current,
        body: JSON.stringify({ locked }),
      },
    )
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const crawlDocumentUrl = async (
    url: string,
  ): Promise<{
    title: string
    description: string
    learningOutcomes: string[]
  }> => {
    const res = await fetch(`${documentHost}/architectures/topics/crawl`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ url }),
    })
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.message)
    }
    return res.json()
  }

  const providerValue = {
    setToken,
    getArchClients,
    getArchClient,
    createArchClient,
    updateArchClient,
    deleteArchClient,
    createArchProgram,
    updateArchProgram,
    deleteArchProgram,
    createArchIntake,
    updateArchIntake,
    deleteArchIntake,
    createArchCourse,
    updateArchCourse,
    deleteArchCourse,
    createArchModule,
    updateArchModule,
    deleteArchModule,
    createArchTopic,
    updateArchTopic,
    deleteArchTopic,
    changeArchClient,
    changeArchProgram,
    changeArchIntake,
    changeArchCourse,
    changeArchModule,
    cloneArchClient,
    cloneArchProgram,
    cloneArchIntake,
    cloneArchCourse,
    cloneArchModule,
    cloneArchTopic,
    moveArchProgram,
    moveArchIntake,
    moveArchCourse,
    moveArchModule,
    moveArchTopic,
    searchArch,
    uploadCourseExcel,
    uploadIntakeExcel,
    getArchForTable,
    getArchCourse,
    exportArchExcel,
    checkTopics,
    getCheckTopicsResults,
    toggleLockedForIntake,
    crawlDocumentUrl,
  }

  return (
    <ArchitectureApiContext.Provider value={providerValue}>
      {children}
    </ArchitectureApiContext.Provider>
  )
}
