import { useEffect, useMemo, useRef, useState } from 'react'
import { useApi } from '../providers/ApiProvider'
import 'react-toastify/dist/ReactToastify.css'
import { Permission, Role, useAuth } from '../providers/AuthProvider'
import SidebarLayout from 'components/SidebarLayout'
import { ToastUtil } from 'utils/ToastUtil'
import { StyleUtil } from 'utils/StyleUtil'
import sortSvg from 'images/sort.svg'
import sort2Svg from 'images/sort2.svg'
import cogQuestSvg from 'images/cog-question.svg'
import magnifyingGlassSvg from 'images/magnifying-glass.svg'

import { Tooltip } from 'react-tooltip'
import SearchInput from '../views/SearchInput'
import DropDownMenu, { DropDownItem } from '../views/DropDownMenu'
import Spinner from '../views/Spinner'
import { Document, DocumentUser } from '../apis/entities/document.entity'
import tickSvg from '../images/tick.svg'
import * as Sentry from '@sentry/react'

import closeSvg from '../images/close.svg'
import DocModifyVal from '../components/DocModifyVal'
import { getDateText, getTimeText } from 'utils/StringUtil'
import LargeSpinner from 'views/LargeSpinner'

enum ModalState {
  LOADING = 'LOADING',
  EDITING = 'EDITING',
  HIDDEN = 'HIDDEN',
  Right = 'RIGHT',
}
function DocQuestions(props: any) {
  const { getQuestionJob, generateQuestions, docQuestions, setDocQuestions } =
    props

  const [modalBoxData, setModalBoxData] = useState({
    id: '',
    questions: [],
    mode: ModalState.HIDDEN,
  })

  function exitModalAndRun(
    id: string,
    questions: any,
    modalBoxMode: ModalState = ModalState.HIDDEN,
  ) {
    setDocQuestions({
      ...docQuestions,
      ...{ [id]: questions },
    })
    setModalBoxData({
      ...modalBoxData,
      ...{
        id: id,
        questions: questions,
        mode: modalBoxMode,
      },
    })
    return
  }

  const ModalBox: React.FC<any> = ({ onClose, data }) => {
    if (data.mode === 'HIDDEN') {
      return null
    }

    function questionToText(question: any) {
      return `
Question: ${question.questionText} 
    Correct answer: ${question.correctAnswer} 
    Wrong answer 1: ${question.incorrectAnswer1} 
    Wrong answer 2: ${question.incorrectAnswer2}
    Wrong answer 3: ${question.incorrectAnswer3}
`.trim()
    }

    // Function to copy a single question to clipboard
    const copyQuestionToClipboard = (question: any) => {
      navigator.clipboard.writeText(questionToText(question))
      ToastUtil.success('Question copied to clipboard')
    }

    // Function to copy all questions to clipboard
    const copyAllQuestionsToClipboard = (questions: [any]) => {
      const retVal = questions
        .map((question: any) => {
          return questionToText(question)
        })
        .join('\n\n')
      navigator.clipboard.writeText(retVal)
      ToastUtil.success('All questions copied to clipboard')
    }

    return (
      <>
        <div
          className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-40"
          id="rename-file-dialog"
          style={{
            zIndex: 1000,
          }}
        >
          <div
            // className="flex h-screen flex-col rounded-lg bg-white shadow-lg"
            className="flex flex-col rounded-lg bg-white shadow-lg"
            style={{
              // height: '800px',
              width: '600px',
              overflow: 'auto',
              position: 'relative',
              paddingBottom: '36px',
            }}
          >
            <div
              className="sticky top-0 flex justify-end"
              style={{
                paddingRight: '36px',
                paddingTop: '36px',
                paddingBottom: '24px',
              }}
            >
              <button
                className=" transition duration-150 ease-in-out hover:text-gray-900"
                onClick={() =>
                  setModalBoxData({ ...modalBoxData, mode: ModalState.HIDDEN })
                }
              >
                <img src={closeSvg} style={{ width: '20px' }} alt="close" />
              </button>
            </div>

            {data.mode === ModalState.EDITING ? (
              <>
                <div
                  style={{
                    borderBottom: '1px solid #D5D5DB',
                    marginBottom: '60px',
                  }}
                >
                  <div
                    className="overflow-y-auto"
                    style={{
                      height: '600px',
                      marginRight: '0px',
                      marginLeft: '36px',
                      padding: '0 36px 0 12px',
                    }}
                    key={data.id}
                  >
                    <div style={{}}>
                      {data.questions.map((q: any, idx: number) => (
                        <>
                          <div
                            style={{
                              padding: '0 0px 24px',
                              borderBottom: '1px solid #1F1F1F4D',
                              fontSize: '18px',
                              // fontFamily: 'Inter',
                              fontWeight: '700',
                              lineHeight: '25.6px',
                            }}
                            key={idx}
                          >
                            <DocModifyVal
                              what={q.questionText}
                              updateVal={async (newVal: string) => {
                                q.questionText = newVal
                                let questionsToUpdate = [...data.questions]
                                questionsToUpdate[idx] = {
                                  ...q,
                                  questionText: newVal,
                                }
                                await props.saveQuestionJob(
                                  data.id,
                                  questionsToUpdate,
                                )
                                ToastUtil.success('Question updated')
                              }}
                            />
                          </div>
                          <div
                            className="py-3"
                            style={{
                              fontSize: '16px',
                              lineHeight: '25.6px',
                              fontWeight: '400',
                            }}
                          >
                            <div className="flex items-center pb-2">
                              <span className="inline-block px-3">
                                <img
                                  src={tickSvg}
                                  width={26}
                                  alt="correct answer"
                                />
                              </span>
                              <span className="inline-block w-full">
                                <DocModifyVal
                                  what={q.correctAnswer}
                                  updateVal={async (newVal: string) => {
                                    q.correctAnswer = newVal
                                    let questionsToUpdate = [...data.questions]
                                    questionsToUpdate[idx] = {
                                      ...q,
                                      correctAnswer: newVal,
                                    }
                                    await props.saveQuestionJob(
                                      data.id,
                                      questionsToUpdate,
                                    )
                                    ToastUtil.success('Question updated')
                                  }}
                                />
                              </span>
                            </div>
                            <div className="flex items-center pb-2">
                              <span className="inline-block px-3">
                                <img
                                  src={closeSvg}
                                  width={18}
                                  style={{ margin: '4px' }}
                                  alt="wrong answer 1"
                                />
                              </span>
                              <span className="inline-block w-full">
                                <DocModifyVal
                                  what={q.incorrectAnswer1}
                                  updateVal={async (newVal: string) => {
                                    q.incorrectAnswer1 = newVal
                                    let questionsToUpdate = [...data.questions]
                                    questionsToUpdate[idx] = {
                                      ...q,
                                      incorrectAnswer1: newVal,
                                    }
                                    await props.saveQuestionJob(
                                      data.id,
                                      questionsToUpdate,
                                    )
                                    ToastUtil.success('Question updated')
                                  }}
                                />
                              </span>
                            </div>
                            <div className="flex items-center pb-2">
                              <span className="inline-block px-3">
                                <img
                                  src={closeSvg}
                                  width={18}
                                  style={{ margin: '4px' }}
                                  alt="wrong answer 2"
                                />
                              </span>
                              <span className="inline-block w-full">
                                <DocModifyVal
                                  what={q.incorrectAnswer2}
                                  updateVal={async (newVal: string) => {
                                    q.incorrectAnswer2 = newVal
                                    let questionsToUpdate = [...data.questions]
                                    questionsToUpdate[idx] = {
                                      ...q,
                                      incorrectAnswer2: newVal,
                                    }
                                    await props.saveQuestionJob(
                                      data.id,
                                      questionsToUpdate,
                                    )
                                    ToastUtil.success('Question updated')
                                  }}
                                />
                              </span>
                            </div>
                            <div className="flex items-center">
                              <span className="inline-block px-3">
                                <img
                                  src={closeSvg}
                                  width={18}
                                  style={{ margin: '4px' }}
                                  alt="wrong answer 3"
                                />
                              </span>
                              <span className="inline-block w-full">
                                <DocModifyVal
                                  what={q.incorrectAnswer3}
                                  updateVal={async (newVal: string) => {
                                    q.incorrectAnswer3 = newVal
                                    let questionsToUpdate = [...data.questions]
                                    questionsToUpdate[idx] = {
                                      ...q,
                                      incorrectAnswer3: newVal,
                                    }
                                    await props.saveQuestionJob(
                                      data.id,
                                      questionsToUpdate,
                                    )
                                    ToastUtil.success('Question updated')
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div
                            className="flex justify-end"
                            style={{ paddingBottom: '48px' }}
                          >
                            <button
                              className={StyleUtil.buttonSecondary}
                              onClick={() => copyQuestionToClipboard(q)}
                            >
                              Copy
                            </button>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-0 left-0 right-0 mb-9 mt-6 flex justify-end">
                  <button
                    className={StyleUtil.buttonPrimary}
                    style={{ marginRight: '36px' }}
                    onClick={async () => {
                      // Update the mode to show the loading state
                      setModalBoxData((prev) => ({
                        ...prev,
                        mode: ModalState.LOADING,
                      }))

                      try {
                        const resp = await generateQuestions(data.id)
                        // Update the questions and switch back to the 'EDITING' mode
                        setModalBoxData((prev) => ({
                          ...prev,
                          mode: ModalState.EDITING,
                          questions: resp,
                        }))
                      } catch (e: any) {
                        // Handle any errors here
                        if (e.error) {
                          ToastUtil.error(e.error.message)
                        } else {
                          ToastUtil.error(e.message)
                        }
                        // If there's an error, switch back to the 'EDITING' mode
                        setModalBoxData((prev) => ({
                          ...prev,
                          mode: ModalState.EDITING,
                        }))
                      }
                    }}
                  >
                    Regenerate all
                  </button>
                  <button
                    className={StyleUtil.buttonSecondary}
                    style={{ marginRight: '36px' }}
                    onClick={() => copyAllQuestionsToClipboard(data.questions)}
                  >
                    Copy all
                  </button>
                </div>
              </>
            ) : (
              <div>
                <div
                  className="flex justify-center"
                  style={{
                    paddingTop: '125px',
                  }}
                >
                  <div>
                    <svg
                      width="231"
                      height="230"
                      viewBox="0 0 231 230"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M115.25 28.75C162.885 28.75 201.5 67.3655 201.5 115C201.5 162.635 162.885 201.25 115.25 201.25C67.6155 201.25 29 162.635 29 115C29 67.3655 67.6155 28.75 115.25 28.75Z"
                        stroke="#F3F3F5"
                        strokeWidth="23"
                        strokeLinecap="round"
                      />
                      <path
                        d="M115.25 28.75C162.885 28.75 201.5 67.3655 201.5 115"
                        stroke="#BD69FE"
                        strokeWidth="23"
                        strokeLinecap="round"
                      />
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 0 0"
                        to="360 0 0"
                        dur="1s"
                        repeatCount="indefinite"
                      />
                    </svg>
                  </div>
                </div>

                <div
                  className="flex justify-center"
                  style={{
                    paddingTop: '30px',
                    fontSize: '18px',
                    lineHeight: '24px',
                    paddingBottom: '190px',
                  }}
                >
                  <p>Generating questions ...</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <ModalBox data={modalBoxData} />

      <>
        {docQuestions?.[props.id]?.length ? (
          <>
            <img
              className="cursor-pointer"
              src={magnifyingGlassSvg}
              style={{ margin: '0 auto' }}
              alt="Show questions"
              onClick={() => {
                setModalBoxData({
                  ...modalBoxData,
                  ...{
                    id: props.id,
                    mode: ModalState.EDITING,
                    questions: docQuestions[props.id],
                  },
                })
              }}
              data-tooltip-id="tooltip-qview"
            />
          </>
        ) : (
          <>
            <img
              className="cursor-pointer"
              src={cogQuestSvg}
              style={{ margin: '0 auto' }}
              alt="Generate questions"
              data-tooltip-id="tooltip-qgen"
              onClick={async () => {
                setModalBoxData({
                  ...modalBoxData,
                  ...{
                    id: props.id,
                    mode: ModalState.LOADING,
                  },
                })

                // async function waitForever() {
                //   return new Promise((resolve) => {
                //     setTimeout(() => {
                //       resolve(null)
                //     }, 100000000)
                //   })
                // }
                // await waitForever()

                // setIsGeneratingQuestions(true)
                let questionJob = await getQuestionJob(props.id)
                if (questionJob?.status === 'DONE') {
                  ToastUtil.success('Questions already exist')
                  return exitModalAndRun(
                    props.id,
                    questionJob.questions,
                    ModalState.EDITING,
                  )
                }
                if (questionJob?.status === 'IN_PROGRESS') {
                  const past = new Date().getTime()
                  const fiveSeconds = 1000 * 5
                  const fiveSecondsOver = () =>
                    new Date().getTime() - past >= fiveSeconds
                  while (!fiveSecondsOver()) {
                    questionJob = await getQuestionJob(props.id)
                    if (questionJob?.status === 'DONE') {
                      ToastUtil.success('Questions already exist')
                      return exitModalAndRun(
                        props.id,
                        questionJob.questions,
                        ModalState.EDITING,
                      )
                    }
                  }
                }
                try {
                  const resp = await generateQuestions(props.id)
                  ToastUtil.success('Questions generated')
                  return exitModalAndRun(props.id, resp, ModalState.EDITING)
                } catch (e: any) {
                  if (e.error) {
                    ToastUtil.error(e.error.message)
                  } else {
                    ToastUtil.error(e.message)
                  }
                  return exitModalAndRun(props.id, [], ModalState.HIDDEN)
                }
              }}
            />
          </>
        )}
      </>
    </>
  )
}

const tooltipStyle = {
  backgroundColor: '#fff',
  color: '#000',
  borderRadius: '6px',
}

const tooltipStyleReverse = {
  backgroundColor: '#6b69c1',
  color: '#fff',
  borderRadius: '6px',
}

enum SortBy {
  Topic = 'topic',
  PublishedBy = 'publishedUserName',
  DateTime = 'createdAt',
}

type Direction = 'ascending' | 'descending' | ''

const publishedByOptionLabel = {
  id: '0',
  name: 'Published by',
  value: '0',
  isLabel: true,
}

const publishedByOptionShowAll = {
  id: '1',
  name: 'Show all',
  value: '',
  isLabel: false,
}

function QuestionsPage() {
  const { isLogged, getRole, permissions, auth0User: user } = useAuth()
  const {
    getDocuments,
    getQuestionJob,
    getAllQuestionJobs,
    saveQuestionJob,
    generateQuestions,
    generateMultipleQuestionsStart,
    generateMultipleQuestionsStatus,
    getDocumentPublishedBy,
    updateDocument,
  } = useApi()
  const [documents, setDocuments] = useState<any[]>([])
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [isFetchedPublishedBy, setIsFetchedPublishedBy] =
    useState<boolean>(false)
  const [docQuestions, setDocQuestions] = useState<{ [key: string]: any[] }>({})

  const refPublishedBy = useRef<string>('')
  const refPublished = useRef<boolean>(true)
  const refSearchTextInput = useRef<string>('')
  const refStartDate = useRef<Date | undefined>(undefined)
  const [publishedByOptions, setPublishedByOptions] = useState<any>([
    publishedByOptionLabel,
  ])
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [generatingMultipleTopics, setGeneratingMultipleTopics] =
    useState<boolean>(false)
  const [numberOfQuestions, setNumberOfQuestions] = useState(10)

  // Add table sorting code
  const [sortConfig, setSortConfig] = useState<{
    key: SortBy
    direction: Direction
  } | null>(null)

  const [orderByTopic, setOrderByTopic] = useState<Direction>('')
  const [orderByDateTime, setOrderByDateTime] = useState<Direction>('')

  const [globalModalData, setGlobalModalData] = useState({
    // id: '',
    questions: [],
    mode: ModalState.HIDDEN,
  })

  const GlobalModal: React.FC<any> = (props) => {
    if (globalModalData.mode === 'HIDDEN') {
      return null
    }

    function questionToText(question: any) {
      return `
Question: ${question.questionText} 
    Correct answer: ${question.correctAnswer} 
    Wrong answer 1: ${question.incorrectAnswer1} 
    Wrong answer 2: ${question.incorrectAnswer2}
    Wrong answer 3: ${question.incorrectAnswer3}
`.trim()
    }

    // Function to copy a single question to clipboard
    const copyQuestionToClipboard = (question: any) => {
      navigator.clipboard.writeText(questionToText(question))
      ToastUtil.success('Question copied to clipboard')
    }

    // Function to copy all questions to clipboard
    const copyAllQuestionsToClipboard = (questions: [any]) => {
      const retVal = questions
        .map((question: any) => {
          return questionToText(question)
        })
        .join('\n\n')
      navigator.clipboard.writeText(retVal)
      ToastUtil.success('All questions copied to clipboard')
    }

    return (
      <>
        <div
          className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-40"
          id="rename-file-dialog"
          style={{
            zIndex: 1000,
          }}
        >
          <div
            className="flex flex-col rounded-lg bg-white shadow-lg"
            style={{
              // height: '800px',
              width: '600px',
              overflow: 'auto',
              position: 'relative',
              paddingBottom: '36px',
            }}
          >
            <div
              className="sticky top-0 flex justify-end"
              style={{
                paddingRight: '36px',
                paddingTop: '36px',
                paddingBottom: '24px',
              }}
            >
              <button
                className=" transition duration-150 ease-in-out hover:text-gray-900"
                onClick={() =>
                  setGlobalModalData({
                    ...globalModalData,
                    mode: ModalState.HIDDEN,
                  })
                }
              >
                <img src={closeSvg} style={{ width: '20px' }} alt="close" />
              </button>
            </div>

            {globalModalData.mode === ModalState.EDITING ? (
              <>
                <div
                  style={{
                    borderBottom: '1px solid #D5D5DB',
                    marginBottom: '60px',
                  }}
                >
                  <div
                    className="overflow-y-auto"
                    style={{
                      height: '600px',
                      marginRight: '0px',
                      marginLeft: '36px',
                      padding: '0 36px 0 12px',
                    }}
                  >
                    <div style={{}}>
                      {globalModalData.questions.map((q: any, idx: number) => (
                        <>
                          <div
                            style={{
                              padding: '0 0px 24px',
                              borderBottom: '1px solid #1F1F1F4D',
                              fontSize: '18px',
                              // fontFamily: 'Inter',
                              fontWeight: '700',
                              lineHeight: '25.6px',
                            }}
                          >
                            {q.questionText}
                          </div>
                          <div
                            className="py-3"
                            style={{
                              fontSize: '16px',
                              lineHeight: '25.6px',
                              fontWeight: '400',
                            }}
                          >
                            <div className="flex items-center pb-2">
                              <span className="inline-block px-3">
                                <img
                                  src={tickSvg}
                                  width={26}
                                  alt="correct answer"
                                />
                              </span>
                              <span className="inline-block w-full">
                                {q.correctAnswer}
                              </span>
                            </div>
                            <div className="flex items-center pb-2">
                              <span className="inline-block px-3">
                                <img
                                  src={closeSvg}
                                  width={18}
                                  style={{ margin: '4px' }}
                                  alt="wrong answer 1"
                                />
                              </span>
                              <span className="inline-block w-full">
                                {q.incorrectAnswer1}
                              </span>
                            </div>
                            <div className="flex items-center pb-2">
                              <span className="inline-block px-3">
                                <img
                                  src={closeSvg}
                                  width={18}
                                  style={{ margin: '4px' }}
                                  alt="wrong answer 2"
                                />
                              </span>
                              <span className="inline-block w-full">
                                {q.incorrectAnswer2}
                              </span>
                            </div>
                            <div className="flex items-center">
                              <span className="inline-block px-3">
                                <img
                                  src={closeSvg}
                                  width={18}
                                  style={{ margin: '4px' }}
                                  alt="wrong answer 3"
                                />
                              </span>
                              <span className="inline-block w-full">
                                {q.incorrectAnswer3}
                              </span>
                            </div>
                          </div>
                          <div
                            className="flex justify-end"
                            style={{ paddingBottom: '48px' }}
                          >
                            <button
                              className={StyleUtil.buttonSecondary}
                              onClick={() => copyQuestionToClipboard(q)}
                            >
                              Copy
                            </button>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-0 left-0 right-0 mb-9 mt-6 flex justify-end">
                  <button
                    className={StyleUtil.buttonSecondary}
                    style={{ marginRight: '36px' }}
                    onClick={() =>
                      copyAllQuestionsToClipboard(
                        globalModalData.questions as any,
                      )
                    }
                  >
                    Copy all
                  </button>
                </div>
              </>
            ) : (
              <LargeSpinner text="Generating questions ..." />
            )}
          </div>
        </div>
      </>
    )
  }

  const assignJobData = async (docs: any[]) => {
    try {
      docs.sort((a: any, b: any) => {
        return a.id.localeCompare(b.id)
      })

      const jobs = await getAllQuestionJobs()
      // match job[].docId to docs[]._id in docs[].job
      for (const job of jobs) {
        for (const doc of docs) {
          if (job.docId === doc.id) {
            doc.job = job
          }
        }
      }
      setDocQuestions({
        ...docQuestions,
        ...docs.reduce((acc: any, doc: any) => {
          if (doc.job) {
            acc[doc.id] = doc.job.questions
          }
          return acc
        }, {}),
      })
      return docs
    } catch (e: any) {
      Sentry.captureException(e)
      ToastUtil.error('Failed to get question jobs')
      return []
    }
  }
  // eslint-disable-next-line
  useEffect(() => {
    if (!isLogged) return
    getDocumentPublishedBy().then((users) => {
      let allOptions = [publishedByOptionLabel]
      if (users) {
        let options = users.map((u: DocumentUser, _index: number) => {
          return {
            id: u.publishedAuth0Id,
            name: u.publishedUserName,
            value: u.publishedAuth0Id,
            isLabel: false,
          }
        })
        allOptions = allOptions.concat(options)
        allOptions.push(publishedByOptionShowAll)
        setPublishedByOptions(allOptions)
        setIsFetchedPublishedBy(true)
      }
    })

    setIsFetchedPublishedBy(true)
  }, [getDocumentPublishedBy, isLogged])

  useEffect(() => {
    if (!isLogged) return
    const filter = `published=${refPublished.current ? 1 : 0}&publishedBy=${
      refPublishedBy.current
    }`
    const search = refSearchTextInput.current
    setIsSearching(true)
    getDocuments(filter, search, refStartDate.current)
      .then((docs) => {
        if (docs) {
          return assignJobData(docs)
        } else {
          return []
        }
      })
      .then((docs) => {
        setDocuments(docs)
        setIsSearching(false)
      })
    // eslint-disable-next-line
  }, [isLogged, getDocuments])

  const onClickSort = (key: SortBy) => {
    let ascending = true
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      ascending = false
    }

    switch (key) {
      case SortBy.Topic:
        setOrderByTopic(ascending ? 'ascending' : 'descending')
        break
      case SortBy.DateTime:
        setOrderByDateTime(ascending ? 'ascending' : 'descending')
        break
      default:
        break
    }

    setSortConfig({ key, direction: ascending ? 'ascending' : 'descending' })
  }

  const searchDocuments = async () => {
    if (!isLogged) return
    const filter = `published=${refPublished.current ? 1 : 0}&publishedBy=${
      refPublishedBy.current
    }`
    const search = refSearchTextInput.current
    setIsSearching(true)
    const docs = await getDocuments(filter, search, refStartDate.current)
    if (docs) {
      const docsWithJobs = await assignJobData(docs)
      setDocuments(docsWithJobs)
    }
    setIsSearching(false)
  }

  const onSelectPublishedBy = async (item: DropDownItem) => {
    refPublishedBy.current = item.value
    await searchDocuments()
  }

  const onSelectPublished = async (item: DropDownItem) => {
    const value = item.value
    refPublished.current = value === 0
    await searchDocuments()
  }

  const getTooptipStyle = (key: SortBy): any => {
    switch (key) {
      case SortBy.Topic:
        return orderByTopic === 'ascending' ? tooltipStyleReverse : tooltipStyle
      case SortBy.DateTime:
        return orderByDateTime === 'ascending'
          ? tooltipStyleReverse
          : tooltipStyle
      default:
        return tooltipStyle
    }
  }

  const getSortingText = (key: SortBy): string => {
    switch (key) {
      case SortBy.Topic:
        if (orderByTopic === 'ascending') return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.DateTime:
        if (orderByDateTime === 'ascending') return 'Sort new to old'
        return 'Sort old to new'
      default:
        return ''
    }
  }

  const sortedDocuments = useMemo(() => {
    let sortableItems = documents?.length ? [...documents] : []
    if (sortConfig !== null) {
      sortableItems.sort((a: Document, b: Document) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [documents, sortConfig])

  const onSelectDate = async (item: DropDownItem) => {
    const value = item.value
    // console.log('onSelectDate', value)
    if (value === undefined) {
      // show all
      refStartDate.current = undefined
    } else if (value > 0) {
      const startDate = new Date()
      // caculate start date by value which is number of days
      startDate.setDate(startDate.getDate() - value)
      // console.log('startDate', startDate)
      refStartDate.current = startDate
    }
    await searchDocuments()
  }

  const getPreviewUrl = (doc: Document): string => {
    if (doc.published) {
      return `https://${process.env.REACT_APP_PUBLISH_URL}/lx/${doc.id}/index.html`
    } else {
      return `https://${process.env.REACT_APP_PREVIEW_URL}/${doc.id}/index.html`
    }
  }

  const onChangeCheckbox = (checked: boolean, id: string) => {
    // setIsClickDelete(false)
    if (checked) {
      setCheckedIds([...checkedIds, id])
      // If all checkboxes are checked
      if (checkedIds.length === documents.length - 1) {
        // setSelectAll(true)
      }
    } else {
      setCheckedIds(checkedIds.filter((y) => y !== id))
      // If any checkbox is unchecked
      // setSelectAll(false)
    }
  }

  const isPublished = (): boolean => {
    return refPublished.current === true
  }

  const hasPermission = (
    permission: string,
    publishedAuth0Id?: string,
  ): boolean => {
    // https://horizonsfl.atlassian.net/browse/HLP-1465
    if (getRole() === Role.SuperAdmin || getRole() === Role.Admin) return true
    if (
      permissions &&
      permissions.includes(permission) &&
      user?.sub === publishedAuth0Id
    ) {
      // same user
      return true
    }
    return false
  }

  return (
    <SidebarLayout>
      <div className={StyleUtil.container}>
        <p className={StyleUtil.headline}>Question generator</p>
        <main className="flex h-[100%] flex-col pb-10 pt-16">
          {!isFetchedPublishedBy && <Spinner />}
          {isFetchedPublishedBy && (
            <>
              <div className="flex flex-none flex-row items-center">
                <div className="w-1/3">
                  <SearchInput
                    onInputChanged={(value: string) => {
                      refSearchTextInput.current = value
                    }}
                    onEnter={async () => {
                      await searchDocuments()
                    }}
                    isSearching={isSearching}
                  />
                </div>

                <div className="w-10"></div>
                <DropDownMenu
                  items={[
                    {
                      id: '0',
                      name: 'Date',
                      value: undefined,
                      isLabel: true,
                    },
                    { id: '1', name: 'Last 7 days', value: 7 },
                    { id: '2', name: 'Last 30 days', value: 30 },
                    { id: '3', name: 'Last 6 months', value: 183 },
                    { id: '4', name: 'Show all', value: undefined },
                  ]}
                  onSelected={onSelectDate}
                />
                <div className="w-20"></div>
                <DropDownMenu
                  items={publishedByOptions}
                  onSelected={onSelectPublishedBy}
                />
                <div className="w-20"></div>
                <DropDownMenu
                  items={[
                    { id: '0', name: 'Published', value: 0 },
                    { id: '1', name: 'Preview only', value: 1 },
                  ]}
                  onSelected={onSelectPublished}
                />
              </div>
              <div
                className="flex h-[100%] flex-col"
                style={{ paddingTop: '44px' }}
              >
                {isSearching && <Spinner />}
                {!isSearching && documents.length === 0 && (
                  <div className="flex flex-none justify-center">
                    <div className="flex flex-none items-center">
                      <span className="mr-4 text-[16px]">No result.</span>
                    </div>
                  </div>
                )}

                {!isSearching && documents.length > 0 && (
                  <div className="flex-1 overflow-y-auto">
                    <GlobalModal />
                    {checkedIds.length > 2 && (
                      <div className="pb-10">
                        {generatingMultipleTopics && <Spinner />}
                        {!generatingMultipleTopics && (
                          <div className="flex flex-none flex-row items-center">
                            Generate questions from&nbsp;
                            <b>{checkedIds.length}</b>&nbsp;topics
                            <div className="w-10"></div>
                            <DropDownMenu
                              items={[
                                {
                                  id: '0',
                                  name: 'Number of questions to generate',
                                  value: undefined,
                                  isLabel: true,
                                },
                                { id: '10', name: '10', value: '10' },
                                { id: '20', name: '20', value: '20' },
                                { id: '30', name: '30', value: '30' },
                                { id: '40', name: '40', value: '40' },
                                { id: '50', name: '50', value: '50' },
                              ]}
                              onSelected={(item) =>
                                setNumberOfQuestions(item.value)
                              }
                            />
                            <div className="w-10"></div>
                            <button
                              className={StyleUtil.buttonSecondary}
                              onClick={async () => {
                                function listenForTaskCompletion(
                                  jobId: string,
                                ) {
                                  const eventSource =
                                    generateMultipleQuestionsStatus(jobId)
                                  eventSource.onmessage = (event) => {
                                    const eventData = JSON.parse(event.data)
                                    if (eventData.isComplete) {
                                      console.log(`Task ${jobId} is complete.`)
                                      eventSource.close()
                                      if (eventData.error) {
                                        console.log(
                                          `Task ${jobId} failed: ${eventData.error}`,
                                        )
                                        ToastUtil.error(eventData.error)
                                        Sentry.captureException(eventData.error)
                                      } else {
                                        console.log(`Task ${jobId} succeeded.`)

                                        setGlobalModalData({
                                          ...globalModalData,
                                          ...{
                                            mode: ModalState.EDITING,
                                            questions: eventData.questions,
                                          },
                                        })

                                        ToastUtil.success('Questions generated')
                                      }
                                    } else {
                                      console.log(
                                        `Task ${jobId} is still running.`,
                                      )
                                    }
                                  }
                                }

                                setGlobalModalData({
                                  ...globalModalData,
                                  ...{
                                    mode: ModalState.LOADING,
                                  },
                                })
                                setGeneratingMultipleTopics(true)
                                try {
                                  const response =
                                    await generateMultipleQuestionsStart(
                                      checkedIds,
                                      numberOfQuestions,
                                    )
                                  console.log(response)
                                  listenForTaskCompletion(response.jobId)

                                  //   console.log(response)
                                  //   setGeneratedQuestions(response.results) // Store the fetched questions in state
                                  //   ToastUtil.success('Questions generated')
                                } catch (e: any) {
                                  //   if (e.error) {
                                  //     ToastUtil.error(e.error.message)
                                  //   } else {
                                  //     ToastUtil.error(e.message)
                                  //   }
                                } finally {
                                  setGeneratingMultipleTopics(false)
                                }
                              }}
                            >
                              GO!
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    <table className="mentem-table">
                      <style>
                        {`
    .accordion-toggle {
      background-color: #f7f7f7;
      border: none;
      padding: 10px;
      text-align: left;
      cursor: pointer;
      width: 100%;
      border-top: 1px solid #ddd;
      transition: background-color 0.2s ease;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .accordion-toggle:hover {
      background-color: #eeeeee;
    }
    
    .accordion-toggle::after {
      content: '';
      background-image: url(accordion-arrow.svg); /* Replace with a valid image URL */
      background-repeat: no-repeat;
      background-position: center;
      width: 24px;
      height: 24px;
      transition: transform 0.2s ease;
    }
    
    .accordion-toggle.open::after {
      transform: rotate(180deg);
    }
    
    .accordion-content {
      padding: 20px;
      border-top: none;
    }
    
    .accordion-section.open .accordion-content {
      display: block;
    }

    .question-list {
      list-style-type: none;
      padding-left: 0;
    }

    .question-list li {
      background-color: #fff;
      margin-bottom: 10px;
      border: 1px solid #e7e7e7;
      padding: 15px;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgb(0 0 0 / 5%);
    }

    .question-header {
      font-weight: 700;
      margin-bottom: 10px;
    }

    .answer-list {
      list-style-type: none;
      padding-left: 20px;
    }

    .answer-list li {
      margin-bottom: 5px;
    }
  `}
                      </style>
                      <thead>
                        <tr>
                          <th
                            className={`${StyleUtil.stickyTableHeader1} h-[55px] w-[24%] min-w-[217px]`}
                          >
                            <div className="flex flex-row items-center px-2">
                              <span className="grow">Topic title</span>
                              <img
                                className="cursor-pointer"
                                src={sortSvg}
                                alt="sort"
                                onClick={() => onClickSort(SortBy.Topic)}
                                data-tooltip-id="tooltip-topic"
                              />
                            </div>
                          </th>
                          <th
                            className={`${StyleUtil.stickyTableHeader2} w-[22%] min-w-[200px]`}
                          >
                            Notes
                          </th>
                          <th
                            className={`${StyleUtil.stickyTableHeader1} w-[17%] min-w-[160px]`}
                          >
                            <div className="flex flex-row items-center px-2">
                              <span className="grow">
                                {isPublished() && 'Published by'}
                                {!isPublished() && 'Created by'}
                              </span>
                              <img
                                className="cursor-pointer"
                                src={sortSvg}
                                alt="sort"
                                onClick={() => onClickSort(SortBy.PublishedBy)}
                                data-tooltip-id="tooltip-published-by"
                              />
                            </div>
                          </th>
                          <th
                            className={`${StyleUtil.stickyTableHeader2} w-[17%] min-w-[149px]`}
                          >
                            <div className="flex flex-row items-center px-2">
                              <span className="grow">Date/Time</span>
                              <img
                                className="cursor-pointer pl-2 pr-2"
                                src={sort2Svg}
                                alt="sort"
                                onClick={() => onClickSort(SortBy.DateTime)}
                                data-tooltip-id="tooltip-date-time"
                              />
                            </div>
                          </th>
                          <th
                            className={`${StyleUtil.stickyTableHeader1} w-[9%]`}
                          >
                            Generate questions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedDocuments.map((x) => {
                          return (
                            <tr data-testid="tr-document" key={x.id}>
                              <td className="mentem-table-cell-2 mentem-title-column">
                                <div className="flex flex-row">
                                  {/*<div className="mentem-title-box">*/}
                                  {/*  {x.topic || x.name}*/}
                                  {/*</div>*/}
                                  <input
                                    type="checkbox"
                                    className="self-center"
                                    checked={checkedIds.includes(x.id)}
                                    onChange={(e) =>
                                      onChangeCheckbox(e.target.checked, x.id)
                                    }
                                  />

                                  <a
                                    className="mentem-link pl-7"
                                    href={getPreviewUrl(x)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {x.topic || '(No title)'}
                                  </a>
                                </div>
                              </td>
                              <td className="mentem-table-cell-1">
                                {hasPermission(Permission.UpdateCatalog) && (
                                  <DocModifyVal
                                    what={x.comments}
                                    updateVal={async (newVal: string) => {
                                      await updateDocument(x.id, {
                                        comments: newVal,
                                      })
                                      ToastUtil.success(`Modified successfully`)
                                    }}
                                  />
                                )}
                                {!hasPermission(Permission.UpdateCatalog) && (
                                  <div className="mentem-comment-box">
                                    {x.comments}
                                  </div>
                                )}
                              </td>
                              <td className="mentem-table-cell-2 w-auto">
                                <div className="flex items-center justify-center">
                                  {x.publishedUserName || 'Unknown'}
                                </div>
                              </td>
                              <td className="mentem-table-cell-1">
                                <div className="flex items-center justify-center">
                                  {getDateText(x.createdAt)}
                                </div>
                                <div className="flex items-center justify-center">
                                  {getTimeText(x.createdAt)}
                                </div>
                              </td>
                              <td className="mentem-table-cell-2">
                                <DocQuestions
                                  {...x}
                                  getQuestionJob={getQuestionJob}
                                  generateQuestions={generateQuestions}
                                  saveQuestionJob={saveQuestionJob}
                                  docQuestions={docQuestions}
                                  setDocQuestions={setDocQuestions}
                                />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <Tooltip
                      id="tooltip-topic"
                      className="mentem-tooltip"
                      style={getTooptipStyle(SortBy.Topic)}
                      place="top"
                      noArrow={true}
                    >
                      {getSortingText(SortBy.Topic)}
                    </Tooltip>

                    <Tooltip
                      id="tooltip-date-time"
                      className="mentem-tooltip"
                      style={getTooptipStyle(SortBy.DateTime)}
                      place="top"
                      noArrow={true}
                    >
                      {getSortingText(SortBy.DateTime)}
                    </Tooltip>

                    <Tooltip
                      id="tooltip-qview"
                      className="mentem-tooltip"
                      place="top"
                      noArrow={true}
                      style={{
                        backgroundColor: 'white',
                        fontSize: '14px',
                        color: '#000',
                        height: '44px',
                        borderRadius: '6px',
                        padding: '6px 12px',
                      }}
                    >
                      <div>
                        <div>View question(s)</div>
                        <div style={{ color: '#92929D', display: 'block' }}>
                          There are question(s) available to view
                        </div>
                      </div>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-qgen"
                      className="mentem-tooltip"
                      place="top"
                      noArrow={true}
                      style={{
                        backgroundColor: '#fff',
                        padding: '3px 6px',
                        fontSize: '14px',
                        color: '#000',
                        height: '44px',
                        borderRadius: '6px',
                      }}
                    >
                      <div>
                        <div>Generate questions</div>
                        <div style={{ color: '#92929D', display: 'block' }}>
                          There is no questions available to view
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
            </>
          )}
        </main>
      </div>
    </SidebarLayout>
  )
}

export default QuestionsPage
