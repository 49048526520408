import { useArchitectureApi } from 'providers/ArchitectureApiProvider'
import Spinner from './Spinner'
import { useCallback, useEffect, useRef, useState, KeyboardEvent } from 'react'
import {
  ArchClient,
  ArchCourse,
  ArchIntake,
  ArchModule,
  ArchProgram,
  ArchTopic,
} from 'apis/entities/architecture.entity'
import { ToastUtil } from 'utils/ToastUtil'
import { useAuth } from 'providers/AuthProvider'
import Svg, { Icon, IconClass } from 'components/Svg'
import { tooltipStyle } from 'utils/TableUtils'
import { Tooltip } from 'react-tooltip'
import Checkbox, { CheckboxSize, CheckboxTheme } from 'components/Checkbox'
import { StyleUtil } from 'utils/StyleUtil'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import React from 'react'
import ButtonSpinner from './ButtonSpinner'
import {
  ArchActionType,
  getCourseByModuleId,
  getCourseCountByIntakeId,
  getDeleteConfirmationMessage,
  getIntakeCountByProgramId,
  getModuleByTopicId,
  getModuleCountByCourseId,
  getTopicCountByModuleId,
} from 'utils/ArchUtil'
import Emitter, { Events } from 'core/emitter'
import { TextareaAutosize } from '@mui/base'

interface ActionButtons {
  upload: boolean
  add: boolean
  copy: boolean
  delete: boolean
  collapse: boolean
  // for module and topic
  up: boolean
  down: boolean
  // for topic
  move: boolean
}

interface DragAndDropData {
  moduleId: string
  topicId?: string
  // for moving to another module
  previousModuleId?: string
  nextModuleId?: string
}

// a dictionary to store the position of the data, [index] = DragAndDropData
interface DragAndDropDataDict {
  [index: string]: DragAndDropData
}

interface Props {
  client: ArchClient | null
  isLoading: boolean
  filteredProgramName?: string
  filteredIntakeName?: string
  onUpdateProgram?: () => void
  onUpdateIntake?: () => void
}

export default function ArchManageView({
  client,
  isLoading,
  filteredProgramName,
  filteredIntakeName,
  onUpdateProgram,
  onUpdateIntake,
}: Props) {
  const { isLogged, isSuperAdmin } = useAuth()
  const {
    getArchClient,
    createArchProgram,
    createArchIntake,
    createArchCourse,
    createArchModule,
    createArchTopic,
    deleteArchProgram,
    deleteArchIntake,
    deleteArchCourse,
    deleteArchModule,
    deleteArchTopic,
    updateArchProgram,
    updateArchIntake,
    updateArchCourse,
    updateArchModule,
    updateArchTopic,
    moveArchTopic,
    toggleLockedForIntake,
  } = useArchitectureApi()
  const [isFetchingClient, setIsFetchingClient] = useState(false)
  const [selectedClient, setSelectedClient] = useState<ArchClient | null>(null)
  const [collapsedProgramIds, setCollapsedProgramIds] = useState<string[]>([])
  const [collapsedIntakeIds, setCollapsedIntakeIds] = useState<string[]>([])
  const [collapsedCourseIds, setCollapsedCourseIds] = useState<string[]>([])
  const [collapsedModuleIds, setCollapsedModuleIds] = useState<string[]>([])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  let draggableIndex = 0

  const [checkedCourseIds, setCheckedCourseIds] = useState<string[]>([])
  const [checkedModuleIds, setCheckedModuleIds] = useState<string[]>([])
  const [checkedTopicIds, setCheckedTopicIds] = useState<string[]>([])
  const [checkboxSelectAll, setCheckboxSelectAll] = useState(false)

  // for single delete button
  const [singleDelete, setSingleDelete] = useState<{
    id: string | null
    type: ArchActionType | null
  }>({ id: null, type: null })
  const [isDeleting, setIsDeleting] = useState(false)

  // for client to add program
  const [showAddProgram, setShowAddProgram] = useState(false)
  const [isAddingProgram, setIsAddingProgram] = useState(false)
  // programIds for adding intake
  const [showAddIntakeIds, setShowAddIntakeIds] = useState<string[]>([])
  const [isAddingIntakeIds, setIsAddingIntakeIds] = useState<string[]>([])
  // intakeIds for adding course
  const [showAddCourseIds, setShowAddCourseIds] = useState<string[]>([])
  const [isAddingCourseIds, setIsAddingCourseIds] = useState<string[]>([])
  // courseId for adding module
  const [showAddModuleIds, setShowAddModuleIds] = useState<string[]>([])
  const [isAddingModuleIds, setIsAddingModuleIds] = useState<string[]>([])
  // moduleId for adding topic
  const [showAddTopicIds, setShowAddTopicIds] = useState<string[]>([])
  const [isAddingTopicIds, setIsAddingTopicIds] = useState<string[]>([])

  const refDragAndDropDataDict = useRef<DragAndDropDataDict>({})
  const refTimeoutDict = useRef<{ [index: string]: NodeJS.Timeout }>({})
  const timeoutForSave = 500

  const refclonedObject = useRef<{
    [key: string]: string | string[] | undefined
  }>({})

  // store the locking status of the intake
  const [lockLoading, setLockLoading] = useState<{
    [intakeId: string]: boolean
  }>({})

  enum EditPrefix {
    ProgramName = 'program-name-',
    IntakeName = 'intake-name-',
    CourseName = 'course-name-',
    ModuleName = 'module-name-',
    TopicName = 'topic-name-',
    TopicLearningOutcome = 'topic-learning-outcome-',
  }

  const [editedModeCells, setEditedModeCells] = useState<{
    [id: string]: Boolean
  }>({})

  const onKeyDownForTextArea = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
    }
    // reset clonedObject
    refclonedObject.current = {}
  }

  const onClickOrBlurTextArea = (
    prefix: string,
    targetId: string,
    value: boolean,
  ) => {
    setEditedModeCells({
      ...editedModeCells,
      [`${prefix}-${targetId}`]: value,
    })
    // reset clonedObject
    refclonedObject.current = {}
  }

  const readOnlyMode = (prefix: string, targetId: string): boolean => {
    if (
      prefix === EditPrefix.ProgramName &&
      refclonedObject.current['programId'] === targetId
    ) {
      return false
    } else if (
      prefix === EditPrefix.IntakeName &&
      refclonedObject.current['intakeId'] === targetId
    ) {
      return false
    }
    return !editedModeCells[`${prefix}-${targetId}`]
  }

  const canAutoFocus = (prefix: string, targetId: string): boolean => {
    if (
      prefix === EditPrefix.ProgramName &&
      refclonedObject.current['programId'] === targetId
    ) {
      return true
    } else if (
      prefix === EditPrefix.IntakeName &&
      refclonedObject.current['intakeId'] === targetId
    ) {
      return true
    }
    return false
  }

  const resetTimeout = (
    index: string,
    timeout?: NodeJS.Timeout | undefined,
  ) => {
    if (refTimeoutDict.current[index]) {
      clearTimeout(refTimeoutDict.current[index])
    }
    if (timeout !== undefined && timeout !== null) {
      refTimeoutDict.current[index] = timeout
    }
  }

  const emitReloadClient = useCallback(
    (updateCourseTable: boolean = false) => {
      if (!selectedClient) return
      Emitter.emit(Events.ReloadArchClient, {
        clientId: selectedClient.id,
        updateCourseTable: updateCourseTable,
      })
    },
    [selectedClient],
  )

  const updateDragAndDropDataDict = () => {
    // console.log('before update', refDragAndDropDataDict.current)

    // get the moduleIds
    const indexes = Object.keys(refDragAndDropDataDict.current).filter(
      (index) => refDragAndDropDataDict.current[index].topicId === undefined,
    )
    const moduleIds = indexes.map(
      (index) => refDragAndDropDataDict.current[index].moduleId,
    )
    // console.log('moduleIds', moduleIds)
    // set previousModuleId and nextModuleId for each module
    moduleIds.forEach((moduleId, index) => {
      const previousModuleId = index > 0 ? moduleIds[index - 1] : undefined
      const nextModuleId =
        index < moduleIds.length - 1 ? moduleIds[index + 1] : undefined
      const dictIndex = indexes.find(
        (i) => refDragAndDropDataDict.current[i].moduleId === moduleId,
      )
      if (dictIndex) {
        refDragAndDropDataDict.current[dictIndex].previousModuleId =
          previousModuleId
        refDragAndDropDataDict.current[dictIndex].nextModuleId = nextModuleId
      }
    })
    // console.log('after update', refDragAndDropDataDict.current)
  }

  const updateProgramName = useCallback(
    async (programId: string, name: string) => {
      try {
        if (!name) {
          ToastUtil.warning('Program name cannot be empty')
          return
        }
        await updateArchProgram(programId, name)
        ToastUtil.success('Program updated')
        emitReloadClient(true)
      } catch (error) {
        ToastUtil.error('Program update failed')
        console.error(error)
      }
    },
    [emitReloadClient, updateArchProgram],
  )

  const updateIntakeName = useCallback(
    async (intakeId: string, name: string) => {
      try {
        if (!name) {
          ToastUtil.warning('Intake name cannot be empty')
          return
        }
        await updateArchIntake(intakeId, name)
        ToastUtil.success('Intake updated')
        emitReloadClient(true)
      } catch (error) {
        ToastUtil.error('Intake update failed')
        console.error(error)
      }
    },
    [emitReloadClient, updateArchIntake],
  )

  const updateCourseName = useCallback(
    async (courseId: string, name: string) => {
      try {
        if (!name) {
          ToastUtil.warning('Course name cannot be empty')
          return
        }
        await updateArchCourse(courseId, name)
        ToastUtil.success('Course updated')
        emitReloadClient(true)
      } catch (error) {
        ToastUtil.error('Course update failed')
        console.error(error)
      }
    },
    [emitReloadClient, updateArchCourse],
  )

  const updateModuleName = useCallback(
    async (moduleId: string, name: string) => {
      try {
        if (!name) {
          ToastUtil.warning('Module name cannot be empty')
          return
        }
        await updateArchModule(moduleId, name)
        ToastUtil.success('Module updated')
      } catch (error) {
        ToastUtil.error('Module update failed')
        console.error(error)
      }
    },
    [updateArchModule],
  )

  const updateTopicLearningOutcome = useCallback(
    async (topicId: string, learningOutcome: string) => {
      try {
        if (!learningOutcome) {
          ToastUtil.warning('Learning outcome cannot be empty')
          return
        }
        await updateArchTopic(topicId, undefined, learningOutcome)
        ToastUtil.success('Topic updated')
      } catch (error) {
        ToastUtil.error('Topic update failed')
        console.error(error)
      }
    },
    [updateArchTopic],
  )

  const checkedActionType = (): ArchActionType | null => {
    if (checkedCourseIds.length > 0) {
      return ArchActionType.Course
    }
    if (checkedModuleIds.length > 0) {
      return ArchActionType.Module
    }
    if (checkedTopicIds.length > 0) {
      return ArchActionType.Topic
    }
    return null
  }

  const renderAllSelectText = () => {
    const actionType = checkedActionType()
    if (actionType === ArchActionType.Course) {
      return `Select all courses`
    } else if (actionType === ArchActionType.Module) {
      return `Select all modules`
    } else if (actionType === ArchActionType.Topic) {
      return `Select all topics`
    }
    return ''
  }

  const renderDeleteFromText = () => {
    const actionType = checkedActionType()
    if (actionType === ArchActionType.Course) {
      return `Delete from intake`
    } else if (actionType === ArchActionType.Module) {
      return `Delete from course`
    } else if (actionType === ArchActionType.Topic) {
      return `Delete from module`
    }
    return ''
  }

  const renderDuplicateToText = () => {
    const actionType = checkedActionType()
    if (actionType === ArchActionType.Course) {
      return `Duplicate to other intake`
    } else if (actionType === ArchActionType.Module) {
      return `Duplicate to other course`
    } else if (actionType === ArchActionType.Topic) {
      return `Duplicate to other module`
    }
    return ''
  }

  /**
   * sample 1: Are you sure you want to delete these 2 modules, including 6 topics?
   * sample 2: Are you sure you want to delete these 4 topics?
   * sample 3: Are you sure you want to delete these 6 topics?
   * sample 4: Are you sure you want to delete this course?
   * sample 5: Are you sure you want to delete this topic?
   * sample 6: Are you sure you want to delete these 2 courses, including 12 modules?
   * sample 7: Are you sure you want to delete this course, including 6 modules?
   * @returns delete confirmation text
   */
  const renderDeleteConfirmationText = (): string => {
    if (!selectedClient) {
      return ''
    }
    // check if single delete
    if (singleDelete !== null) {
      const { id, type } = singleDelete
      if (id && type) {
        if (type === ArchActionType.Program) {
          const intakeCount = getIntakeCountByProgramId(selectedClient, id)
          return getDeleteConfirmationMessage(
            'program',
            1,
            'intake',
            intakeCount,
          )
        } else if (type === ArchActionType.Intake) {
          const courseCount = getCourseCountByIntakeId(selectedClient, id)
          return getDeleteConfirmationMessage(
            'intake',
            1,
            'course',
            courseCount,
          )
        } else if (type === ArchActionType.Course) {
          const moduleCount = getModuleCountByCourseId(selectedClient, id)
          return getDeleteConfirmationMessage(
            'course',
            1,
            'module',
            moduleCount,
          )
        } else if (type === ArchActionType.Module) {
          const topicCount = getTopicCountByModuleId(selectedClient, id)
          return getDeleteConfirmationMessage('module', 1, 'topic', topicCount)
        } else if (type === ArchActionType.Topic) {
          return `Are you sure you want to delete this topic?`
        }
      }
    }

    const actionType = checkedActionType()
    if (actionType === ArchActionType.Course) {
      const courseCount = checkedCourseIds.length
      const moduleCount = selectedClient?.programs
        ?.map((program) =>
          program.intakes?.map((intake) =>
            intake.courses?.filter((course) =>
              checkedCourseIds.includes(course.id),
            ),
          ),
        )
        .flat(2)
        .filter(Boolean)
        .map((course) => course.modules?.length)
        .reduce((acc, val) => acc + val, 0)
      return getDeleteConfirmationMessage(
        'course',
        courseCount,
        'module',
        moduleCount,
      )
    } else if (actionType === ArchActionType.Module) {
      const moduleCount = checkedModuleIds.length
      const topicCount = selectedClient?.programs
        ?.map((program) =>
          program.intakes?.map((intake) =>
            intake.courses?.map((course) =>
              course.modules?.map((module) =>
                module.topics?.filter((topic) =>
                  checkedModuleIds.includes(module.id),
                ),
              ),
            ),
          ),
        )
        .flat(4)
        .filter(Boolean).length
      return getDeleteConfirmationMessage(
        'module',
        moduleCount,
        'topic',
        topicCount,
      )
    } else if (actionType === ArchActionType.Topic) {
      const topicCount = checkedTopicIds.length
      return getDeleteConfirmationMessage('topic', topicCount, '', 0)
    }
    return ''
  }

  const fetchClient = useCallback(
    async (id: string, silent: boolean = false) => {
      try {
        if (!silent) {
          setIsFetchingClient(true)
          setCollapsedProgramIds([])
          setCollapsedIntakeIds([])
          setCollapsedCourseIds([])
          setCollapsedModuleIds([])
        }

        // reset everything
        setShowDeleteConfirmation(false)
        setCheckedCourseIds([])
        setCheckedModuleIds([])
        setCheckedTopicIds([])
        setCheckboxSelectAll(false)
        // reset add input
        setShowAddProgram(false)
        setShowAddIntakeIds([])
        setShowAddCourseIds([])
        setShowAddModuleIds([])
        setShowAddTopicIds([])
        // reset single delete
        setSingleDelete({ id: null, type: null })

        const resultClient = await getArchClient(id)
        setSelectedClient(resultClient)
      } catch (error) {
        ToastUtil.error('Failed to get client')
      } finally {
        if (!silent) {
          setIsFetchingClient(false)
        }
      }
    },
    [getArchClient],
  )

  const createProgram = useCallback(
    async (programName: string) => {
      if (!selectedClient) {
        return
      }
      try {
        setIsAddingProgram(true)
        await createArchProgram(selectedClient.id, programName)
        await fetchClient(selectedClient.id, true)
        setShowAddProgram(false)
        if (onUpdateProgram) {
          onUpdateProgram()
        }
        emitReloadClient(true)
      } catch (error) {
        ToastUtil.error('Failed to create program')
        console.error(error)
      } finally {
        setIsAddingProgram(false)
      }
    },
    [
      selectedClient,
      createArchProgram,
      fetchClient,
      onUpdateProgram,
      emitReloadClient,
    ],
  )

  const createIntake = useCallback(
    async (programId: string, intakeName: string) => {
      if (!selectedClient) {
        return
      }
      try {
        setIsAddingIntakeIds([...isAddingIntakeIds, programId])
        await createArchIntake(programId, intakeName)
        await fetchClient(selectedClient.id, true)
        setShowAddIntakeIds(showAddIntakeIds.filter((id) => id !== programId))
        if (onUpdateIntake) {
          onUpdateIntake()
        }
        emitReloadClient(true)
      } catch (error) {
        ToastUtil.error('Failed to create intake')
        console.error(error)
      } finally {
        setIsAddingIntakeIds(isAddingIntakeIds.filter((id) => id !== programId))
      }
    },
    [
      selectedClient,
      isAddingIntakeIds,
      createArchIntake,
      fetchClient,
      showAddIntakeIds,
      onUpdateIntake,
      emitReloadClient,
    ],
  )

  const createCourse = useCallback(
    async (intakeId: string, courseName: string) => {
      if (!selectedClient) {
        return
      }
      try {
        setIsAddingCourseIds([...isAddingCourseIds, intakeId])
        await createArchCourse(intakeId, courseName)
        await fetchClient(selectedClient.id, true)
        setShowAddCourseIds(showAddCourseIds.filter((id) => id !== intakeId))
        emitReloadClient(true)
      } catch (error) {
        ToastUtil.error('Failed to create course')
        console.error(error)
      } finally {
        setIsAddingCourseIds(isAddingCourseIds.filter((id) => id !== intakeId))
      }
    },
    [
      createArchCourse,
      emitReloadClient,
      fetchClient,
      isAddingCourseIds,
      selectedClient,
      showAddCourseIds,
    ],
  )

  const createModule = useCallback(
    async (courseId: string, moduleName: string) => {
      if (!selectedClient) {
        return
      }
      try {
        setIsAddingModuleIds([...isAddingModuleIds, courseId])
        await createArchModule(courseId, moduleName)
        await fetchClient(selectedClient.id, true)
        setShowAddModuleIds(showAddModuleIds.filter((id) => id !== courseId))
      } catch (error) {
        ToastUtil.error('Failed to create module')
        console.error(error)
      } finally {
        setIsAddingModuleIds(isAddingModuleIds.filter((id) => id !== courseId))
      }
    },
    [
      createArchModule,
      fetchClient,
      isAddingModuleIds,
      selectedClient,
      showAddModuleIds,
    ],
  )

  const createTopic = useCallback(
    async (moduleId: string, topicName: string) => {
      if (!selectedClient) {
        return
      }
      try {
        setIsAddingTopicIds([...isAddingTopicIds, moduleId])
        await createArchTopic(moduleId, topicName)
        await fetchClient(selectedClient.id, true)
        setShowAddTopicIds(showAddTopicIds.filter((id) => id !== moduleId))
      } catch (error) {
        ToastUtil.error('Failed to create topic')
        console.error(error)
      } finally {
        setIsAddingTopicIds(isAddingTopicIds.filter((id) => id !== moduleId))
      }
    },
    [
      createArchTopic,
      fetchClient,
      isAddingTopicIds,
      selectedClient,
      showAddTopicIds,
    ],
  )

  // only support Intake currently
  const onUploadExcel = (id: string, type: ArchActionType) => {
    if (type !== ArchActionType.Intake) return
    Emitter.emit(Events.UploadArchExcel, { intakeId: id })
  }

  const onAdd = (id: string, type: ArchActionType) => {
    switch (type) {
      case ArchActionType.Client:
        setShowAddProgram(true)
        break
      case ArchActionType.Program:
        setShowAddIntakeIds([...showAddIntakeIds, id])
        break
      case ArchActionType.Intake:
        setShowAddCourseIds([...showAddCourseIds, id])
        break
      case ArchActionType.Course:
        setShowAddModuleIds([...showAddModuleIds, id])
        break
      case ArchActionType.Module:
        setShowAddTopicIds([...showAddTopicIds, id])
        break
    }
  }

  const onDelete = (id: string, type: ArchActionType) => {
    // reset all checked ids
    setCheckedCourseIds([])
    setCheckedModuleIds([])
    setCheckedTopicIds([])

    setSingleDelete({ id, type })
    setShowDeleteConfirmation(true)
  }

  const onConfirmDelete = async () => {
    if (!selectedClient) {
      return
    }
    setIsDeleting(true)

    try {
      const { id, type } = singleDelete
      if (id && type) {
        switch (type) {
          case ArchActionType.Program:
            await deleteArchProgram(id)
            emitReloadClient(true)
            break
          case ArchActionType.Intake:
            await deleteArchIntake(id)
            emitReloadClient(true)
            break
          case ArchActionType.Course:
            await deleteArchCourse(id)
            emitReloadClient(true)
            break
          case ArchActionType.Module:
            await deleteArchModule(id)
            break
          case ArchActionType.Topic:
            await deleteArchTopic(id)
            break
        }
      } else {
        // multiple delete
        const actionType = checkedActionType()
        if (actionType === ArchActionType.Course) {
          await Promise.all(
            checkedCourseIds.map((courseId) => deleteArchCourse(courseId)),
          )
        } else if (actionType === ArchActionType.Module) {
          await Promise.all(
            checkedModuleIds.map((moduleId) => deleteArchModule(moduleId)),
          )
        } else if (actionType === ArchActionType.Topic) {
          await Promise.all(
            checkedTopicIds.map((topicId) => deleteArchTopic(topicId)),
          )
        }
      }

      await fetchClient(selectedClient.id, true)
      setShowDeleteConfirmation(false)
      ToastUtil.success('Successfully deleted')
    } catch (error) {
      const e = error as unknown as { message: string }
      const message = `Failed to delete: ${e.message}`
      ToastUtil.error(message)
      console.error(error)
    } finally {
      setIsDeleting(false)
    }
  }

  // single item action
  const onDuplicate = (id: string, type: ArchActionType) => {
    Emitter.emit(Events.ShowArchCopy, { type, ids: [id] })
  }

  // multiple items action
  const onDuplicateSelectedItems = () => {
    const actionType = checkedActionType()
    if (!selectedClient || !actionType) {
      return
    }
    const ids =
      actionType === ArchActionType.Course
        ? checkedCourseIds
        : actionType === ArchActionType.Module
        ? checkedModuleIds
        : checkedTopicIds
    Emitter.emit(Events.ShowArchCopy, { type: actionType, ids })
  }

  // only support topic and module
  const onMoveUp = async (id: string, type: ArchActionType) => {
    if (!selectedClient) return
    if (type === ArchActionType.Topic) {
      // get module by topic id
      const targetModule = getModuleByTopicId(selectedClient, id)
      // console.log(module)
      if (!targetModule) return
      const topicIds = targetModule.topics.map((topic) => topic.id)
      const currentIndex = topicIds.indexOf(id)
      // skip the first item
      if (currentIndex === 0) return
      // swap the ids
      const temp = topicIds[currentIndex]
      topicIds[currentIndex] = topicIds[currentIndex - 1]
      topicIds[currentIndex - 1] = temp
      // TODO: update local state
      // save to database
      await updateArchModule(targetModule.id, undefined, topicIds)
      // TODO: remove this line for local change only
      await fetchClient(selectedClient.id, true)
    } else if (type === ArchActionType.Module) {
      // get course by module id
      const targetCourse = getCourseByModuleId(selectedClient, id)
      // console.log(targetCourse)
      if (!targetCourse) return
      const moduleIds = targetCourse.modules.map((module) => module.id)
      const currentIndex = moduleIds.indexOf(id)
      // skip the first item
      if (currentIndex === 0) {
        console.log('skip the first item')
        return
      }
      // swap the ids
      const temp = moduleIds[currentIndex]
      moduleIds[currentIndex] = moduleIds[currentIndex - 1]
      moduleIds[currentIndex - 1] = temp
      // TODO: update local state
      // save to database
      await updateArchCourse(targetCourse.id, undefined, undefined, moduleIds)
      // TODO: remove this line for local change only
      await fetchClient(selectedClient.id, true)
    }
  }

  // only support topic and module
  const onMoveDown = async (id: string, type: ArchActionType) => {
    if (!selectedClient) return
    if (type === ArchActionType.Topic) {
      // get module by topic id
      const targetModule = getModuleByTopicId(selectedClient, id)
      if (!targetModule) return
      const topicIds = targetModule.topics.map((topic) => topic.id)
      const currentIndex = topicIds.indexOf(id)
      // skip the last item
      if (currentIndex === topicIds.length - 1) return
      // swap the ids
      const temp = topicIds[currentIndex]
      topicIds[currentIndex] = topicIds[currentIndex + 1]
      topicIds[currentIndex + 1] = temp
      // TODO: update local state
      // save to database
      await updateArchModule(targetModule.id, undefined, topicIds)
      // TODO: remove this line for local change only
      await fetchClient(selectedClient.id, true)
    } else if (type === ArchActionType.Module) {
      // get course by module id
      const targetCourse = getCourseByModuleId(selectedClient, id)
      if (!targetCourse) return
      const moduleIds = targetCourse.modules.map((module) => module.id)
      const currentIndex = moduleIds.indexOf(id)
      // skip the last item
      if (currentIndex === moduleIds.length - 1) {
        console.log('skip the last item')
        return
      }
      // swap the ids
      const temp = moduleIds[currentIndex]
      moduleIds[currentIndex] = moduleIds[currentIndex + 1]
      moduleIds[currentIndex + 1] = temp
      // TODO: update local state
      // save to database
      await updateArchCourse(targetCourse.id, undefined, undefined, moduleIds)
      // TODO: remove this line for local change only
      await fetchClient(selectedClient.id, true)
    }
  }

  const onToggleCollapseProgram = (id: string) => {
    if (collapsedProgramIds.includes(id)) {
      setCollapsedProgramIds(collapsedProgramIds.filter((i) => i !== id))
    } else {
      setCollapsedProgramIds([...collapsedProgramIds, id])
    }
  }

  const onToggleCollapseIntake = (id: string) => {
    if (collapsedIntakeIds.includes(id)) {
      setCollapsedIntakeIds(collapsedIntakeIds.filter((i) => i !== id))
    } else {
      setCollapsedIntakeIds([...collapsedIntakeIds, id])
    }
  }

  const onToggleCollapseCourse = (id: string) => {
    if (collapsedCourseIds.includes(id)) {
      setCollapsedCourseIds(collapsedCourseIds.filter((i) => i !== id))
    } else {
      setCollapsedCourseIds([...collapsedCourseIds, id])
    }
  }

  const onToggleCollapseModule = (id: string) => {
    if (collapsedModuleIds.includes(id)) {
      setCollapsedModuleIds(collapsedModuleIds.filter((i) => i !== id))
    } else {
      setCollapsedModuleIds([...collapsedModuleIds, id])
    }
  }

  const onToggleCollapse = (id: string, type: ArchActionType) => {
    switch (type) {
      case ArchActionType.Program:
        onToggleCollapseProgram(id)
        break
      case ArchActionType.Intake:
        onToggleCollapseIntake(id)
        break
      case ArchActionType.Course:
        onToggleCollapseCourse(id)
        break
      case ArchActionType.Module:
        onToggleCollapseModule(id)
        break
    }
  }

  const isCollapsedProgram = (id: string) => {
    return collapsedProgramIds.includes(id)
  }

  const isCollapsedIntake = (id: string) => {
    return collapsedIntakeIds.includes(id)
  }

  const isCollapsedCourse = (id: string) => {
    return collapsedCourseIds.includes(id)
  }

  const isCollapsedModule = (id: string) => {
    return collapsedModuleIds.includes(id)
  }

  const isCollapsed = (id: string, type: ArchActionType) => {
    switch (type) {
      case ArchActionType.Program:
        return isCollapsedProgram(id)
      case ArchActionType.Intake:
        return isCollapsedIntake(id)
      case ArchActionType.Course:
        return isCollapsedCourse(id)
      case ArchActionType.Module:
        return isCollapsedModule(id)
    }
  }

  const renderActionButtons = (
    type: ArchActionType,
    id: string,
    locked: boolean = false,
    dragHandleProps?: any,
  ) => {
    let typeNameForAdd = ''
    let typeName = ''
    let actionButtons: ActionButtons = {
      upload: false,
      add: false,
      copy: false,
      delete: false,
      collapse: false,
      up: false,
      down: false,
      move: false,
    }

    switch (type) {
      case ArchActionType.Client:
        typeNameForAdd = 'program'
        typeName = 'client'
        actionButtons.add = true
        break
      case ArchActionType.Program:
        typeNameForAdd = 'intake'
        typeName = 'program'
        actionButtons.add = true
        actionButtons.copy = true
        actionButtons.delete = true
        actionButtons.collapse = true
        break
      case ArchActionType.Intake:
        typeNameForAdd = 'course'
        typeName = 'intake'
        actionButtons.upload = true
        actionButtons.add = true
        actionButtons.copy = true
        actionButtons.delete = true
        actionButtons.collapse = true
        break
      case ArchActionType.Course:
        typeNameForAdd = 'module'
        typeName = 'course'
        actionButtons.add = true
        actionButtons.copy = true
        actionButtons.delete = true
        actionButtons.collapse = true
        break
      case ArchActionType.Module:
        typeNameForAdd = 'topic'
        typeName = 'module'
        actionButtons.add = true
        actionButtons.copy = true
        actionButtons.delete = true
        actionButtons.collapse = true
        actionButtons.up = true
        actionButtons.down = true
        break
      case ArchActionType.Topic:
        typeName = 'topic'
        actionButtons.copy = true
        actionButtons.move = true
        actionButtons.delete = true
        actionButtons.up = true
        actionButtons.down = true
        break
    }

    // override action buttons for locked items
    if (locked) {
      actionButtons.upload = false
      actionButtons.add = false
      actionButtons.copy = true
      actionButtons.delete = false
      actionButtons.collapse = ArchActionType.Topic !== type
      actionButtons.up = false
      actionButtons.down = false
      actionButtons.move = false
    }

    const iconClassStroke =
      type === ArchActionType.Module
        ? IconClass.StrokeWhite
        : IconClass.StrokeBlack
    const iconClassFill =
      type === ArchActionType.Module ? IconClass.FillWhite : IconClass.Default

    return (
      <div className="flex flex-row items-center gap-[16px]">
        {actionButtons.upload && (
          <div
            data-tooltip-id="tooltip-upload-excel"
            onClick={() => onUploadExcel(id, type)}
            className="arch-action-button"
            tabIndex={-1}
          >
            <Svg icon={Icon.Import} className={IconClass.StrokeBlack} />
          </div>
        )}
        {!locked && !actionButtons.upload && (
          <div className="arch-action-button" />
        )}

        {actionButtons.add && (
          <div
            data-tooltip-id={`tooltip-add-${typeNameForAdd}`}
            onClick={() => onAdd(id, type)}
            className="arch-action-button"
            tabIndex={-1}
          >
            <Svg icon={Icon.Add} className={iconClassFill} />
          </div>
        )}
        {!locked && !actionButtons.add && (
          <div className="arch-action-button" />
        )}

        {actionButtons.copy && (
          <div
            data-tooltip-id={`tooltip-copy-${typeName}`}
            onClick={() => onDuplicate(id, type)}
            className="arch-action-button"
            tabIndex={-1}
          >
            <Svg icon={Icon.Copy} className={iconClassStroke} />
          </div>
        )}
        {!locked && !actionButtons.copy && (
          <div className="arch-action-button" />
        )}

        {actionButtons.up && (
          <div
            data-tooltip-id={`tooltip-move-up-${typeName}`}
            onClick={async () => await onMoveUp(id, type)}
            tabIndex={-1}
          >
            <Svg icon={Icon.ArrowUp} className={iconClassFill} />
          </div>
        )}

        {actionButtons.down && (
          <div
            data-tooltip-id={`tooltip-move-down-${typeName}`}
            onClick={async () => await onMoveDown(id, type)}
            tabIndex={-1}
          >
            <Svg icon={Icon.ArrowDown} className={iconClassFill} />
          </div>
        )}

        {actionButtons.move && dragHandleProps && (
          <div
            {...dragHandleProps}
            data-tooltip-id={`tooltip-move-${typeName}`}
            tabIndex={-1}
          >
            <Svg icon={Icon.Move} />
          </div>
        )}

        {actionButtons.delete && (
          <div
            data-tooltip-id={`tooltip-delete-${typeName}`}
            onClick={() => onDelete(id, type)}
            className="arch-action-button"
            tabIndex={-1}
          >
            <Svg icon={Icon.Delete} className={iconClassFill} />
          </div>
        )}
        {!locked && !actionButtons.delete && (
          <div className="arch-action-button" />
        )}

        {actionButtons.collapse && isCollapsed(id, type) && (
          <div
            data-tooltip-id={`tooltip-expand-${typeName}`}
            onClick={() => onToggleCollapse(id, type)}
            className="arch-action-button"
            tabIndex={-1}
          >
            <Svg icon={Icon.Down} className={iconClassFill} />
          </div>
        )}

        {actionButtons.collapse && !isCollapsed(id, type) && (
          <div
            data-tooltip-id={`tooltip-collapse-${typeName}`}
            onClick={() => onToggleCollapse(id, type)}
            className="arch-action-button"
            tabIndex={-1}
          >
            <Svg icon={Icon.Up} className={iconClassFill} />
          </div>
        )}

        {!actionButtons.collapse && type !== ArchActionType.Topic && (
          <div className="arch-action-button" />
        )}
      </div>
    )
  }

  const showTopicCheckbox = () => {
    return checkedActionType() !== ArchActionType.Module
  }

  const resetAllCheckboxes = (checked: boolean) => {
    // reset select all checkbox
    setCheckboxSelectAll(false)
    // reset single delete
    setSingleDelete({ id: null, type: null })

    if (checked) {
      // clear checked module ids
      setCheckedModuleIds([])
      // clear checked course ids
      setCheckedCourseIds([])
      // clear checked topic ids
      setCheckedTopicIds([])
      // reset delete confirmation
      setShowDeleteConfirmation(false)
    }
  }

  const isLockLoading = (intakeId: string) => {
    return lockLoading[intakeId] || false
  }

  const onToggleLockedForInake = async (intakeId: string, locked: boolean) => {
    // only super admin can lock/unlock
    if (!isSuperAdmin) {
      ToastUtil.warning('Only super admin can lock/unlock')
      return
    }
    if (!selectedClient) {
      ToastUtil.warning('Client not found')
      return
    }
    try {
      setLockLoading({
        ...lockLoading,
        [intakeId]: true,
      })
      await toggleLockedForIntake(intakeId, locked)
      if (locked) {
        ToastUtil.success('Intake locked')
      } else {
        ToastUtil.success('Intake unlocked')
      }
      // reload program
      await fetchClient(selectedClient.id, true)
    } catch (error) {
      if (locked) {
        ToastUtil.error('Failed to lock intake')
      } else {
        ToastUtil.error('Failed to unlock intake')
      }
      console.error(error)
    } finally {
      setLockLoading({
        ...lockLoading,
        [intakeId]: false,
      })
    }
  }

  const renderTopic = (
    topic: ArchTopic,
    moduleId: string,
    moduleIndex: number,
    topicIndex: number,
    locked: boolean,
  ) => {
    const topicNumber = `${moduleIndex + 1}.${topicIndex + 1}`
    const index = ++draggableIndex
    // store to ref
    refDragAndDropDataDict.current[index] = {
      moduleId: moduleId,
      topicId: topic.id,
    }
    return (
      <Draggable key={topic.id} draggableId={topic.id} index={index}>
        {(provided, snapshot) => (
          <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
              boxShadow: snapshot.isDragging
                ? '0px 16px 24px 0px rgba(13, 13, 13, 0.2)'
                : '',
            }}
            key={`topic-${topic.id}`}
          >
            <div className="flex flex-row w-full">
              <div className="flex flex-row items-center gap-[12px] w-full pr-[12px]">
                {!locked && showTopicCheckbox() ? (
                  <Checkbox
                    id={`checkbox-topic-${topic.id}`}
                    checked={checkedTopicIds.includes(topic.id)}
                    onChange={(checked) => {
                      resetAllCheckboxes(checked)
                      setCheckedTopicIds(
                        checked
                          ? [...checkedTopicIds, topic.id]
                          : checkedTopicIds.filter((id) => id !== topic.id),
                      )
                    }}
                    size={CheckboxSize.Small}
                    theme={CheckboxTheme.Primary}
                  />
                ) : (
                  <div className="w-[26px]" />
                )}
                <div className="arch-topic-list-item-title">{topicNumber}</div>
                <div className="min-w-[20%] max-w-[20%] flex flex-row items-center">
                  {topic.link && (
                    <a
                      className="arch-topic-list-item-link"
                      href={topic.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {topic.name}
                    </a>
                  )}
                  {!topic.link && (
                    <span className="arch-topic-list-item-title">
                      {topic.name}
                    </span>
                  )}
                </div>
                {!locked && (
                  <button
                    className="min-w-[17px]"
                    onClick={(e) => {
                      Emitter.emit(Events.ShowArchEditTopic, {
                        type: ArchActionType.Topic,
                        topic: topic,
                        // get the position of the edit button
                        position: {
                          x: e.clientX,
                          y: e.clientY,
                        },
                      })
                    }}
                  >
                    <Svg icon={Icon.Edit} className={IconClass.FillBlack2} />
                  </button>
                )}
                <span className="arch-topic-list-item-title">LO:</span>
                <TextareaAutosize
                  className={`w-full mentem-scrollbar-2 ${
                    readOnlyMode(EditPrefix.TopicLearningOutcome, topic.id)
                      ? 'arch-topic-list-item-title'
                      : 'arch-add-input-small'
                  }`}
                  placeholder="Type learning outcome here"
                  defaultValue={topic.learningOutcome}
                  readOnly={readOnlyMode(
                    EditPrefix.TopicLearningOutcome,
                    topic.id,
                  )}
                  maxRows={2}
                  onDoubleClick={() => {
                    if (locked) return
                    onClickOrBlurTextArea(
                      EditPrefix.TopicLearningOutcome,
                      topic.id,
                      true,
                    )
                  }}
                  onBlur={() =>
                    onClickOrBlurTextArea(
                      EditPrefix.TopicLearningOutcome,
                      topic.id,
                      false,
                    )
                  }
                  onKeyDown={onKeyDownForTextArea}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      const value = e.currentTarget.value.trim()
                      if (!value) {
                        ToastUtil.warning('Learning outcome cannot be empty')
                        return
                      }
                      if (value !== topic.learningOutcome) {
                        resetTimeout(`topic-learning-outcome-${topic.id}`)
                        updateTopicLearningOutcome(
                          topic.id,
                          e.currentTarget.value,
                        )
                      }
                    } else {
                      // auto save by x seconds
                      const value = e.currentTarget.value.trim()
                      if (value !== topic.learningOutcome) {
                        const timeoutId = setTimeout(() => {
                          updateTopicLearningOutcome(topic.id, value)
                        }, timeoutForSave)
                        resetTimeout(
                          `topic-learning-outcome-${topic.id}`,
                          timeoutId,
                        )
                      }
                    }
                  }}
                />
                {!locked && (
                  <button
                    className="min-w-[17px]"
                    onClick={() => {
                      // toggle editing mode
                      onClickOrBlurTextArea(
                        EditPrefix.TopicLearningOutcome,
                        topic.id,
                        readOnlyMode(EditPrefix.TopicLearningOutcome, topic.id),
                      )
                    }}
                  >
                    <Svg icon={Icon.Edit} className={IconClass.FillBlack2} />
                  </button>
                )}
              </div>
              <div className="pr-[6px]">
                {renderActionButtons(
                  ArchActionType.Topic,
                  topic.id,
                  locked,
                  provided.dragHandleProps,
                )}
              </div>
            </div>
          </li>
        )}
      </Draggable>
    )
  }

  const renderModule = (
    module: ArchModule,
    moduleIndex: number,
    locked: boolean,
  ) => {
    const index = ++draggableIndex
    // console.log(`module-${module.id} index: ${index}`)
    // store to ref
    refDragAndDropDataDict.current[index] = {
      moduleId: module.id,
      topicId: undefined,
    }
    return (
      <React.Fragment key={`module-${moduleIndex}`}>
        {/* Prevent from dragging over first module */}
        {moduleIndex === 0 && (
          <li
            key={`module-${module.id}`}
            className="flex flex-row items-center arch-module-list-item"
          >
            <div>
              <div className="pl-[28px] flex flex-row gap-[6px] w-full items-center">
                {!locked && (
                  <Checkbox
                    id={`checkbox-module-${module.id}`}
                    checked={checkedModuleIds.includes(module.id)}
                    onChange={(checked) => {
                      resetAllCheckboxes(checked)
                      setCheckedModuleIds(
                        checked
                          ? [...checkedModuleIds, module.id]
                          : checkedModuleIds.filter((id) => id !== module.id),
                      )
                    }}
                    size={CheckboxSize.Small}
                    theme={CheckboxTheme.White}
                  />
                )}
                <span className="whitespace-nowrap">
                  Module {moduleIndex + 1}:{' '}
                </span>
                <TextareaAutosize
                  className={`w-full ${
                    readOnlyMode(EditPrefix.ModuleName, module.id)
                      ? 'arch-module-list-item-read-only'
                      : 'arch-module-list-item-add-input'
                  }`}
                  placeholder="Type module name here"
                  defaultValue={module.name}
                  readOnly={readOnlyMode(EditPrefix.ModuleName, module.id)}
                  onDoubleClick={() =>
                    onClickOrBlurTextArea(
                      EditPrefix.ModuleName,
                      module.id,
                      true,
                    )
                  }
                  onBlur={() =>
                    onClickOrBlurTextArea(
                      EditPrefix.ModuleName,
                      module.id,
                      false,
                    )
                  }
                  onKeyDown={onKeyDownForTextArea}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      const value = e.currentTarget.value.trim()
                      if (!value) {
                        ToastUtil.warning('Module name cannot be empty')
                        return
                      }
                      if (value !== module.name) {
                        resetTimeout(`module-name-${module.id}`)
                        updateModuleName(module.id, value)
                      }
                    } else {
                      // auto save by x seconds
                      const value = e.currentTarget.value.trim()
                      if (value !== module.name) {
                        const timeoutId = setTimeout(() => {
                          updateModuleName(module.id, value)
                        }, timeoutForSave)
                        resetTimeout(`module-name-${module.id}`, timeoutId)
                      }
                    }
                  }}
                />
              </div>
              <div className="flex flex-row items-center gap-[12px]">
                {renderActionButtons(ArchActionType.Module, module.id, locked)}
              </div>
            </div>
          </li>
        )}
        {moduleIndex !== 0 && (
          <Draggable key={module.id} draggableId={module.id} index={index}>
            {(provided, snapshot) => (
              <li
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={{
                  ...provided.draggableProps.style,
                  boxShadow: snapshot.isDragging
                    ? '0px 16px 24px 0px rgba(13, 13, 13, 0.2)'
                    : '',
                }}
                key={`module-${module.id}`}
                className="flex flex-row items-center arch-module-list-item"
              >
                <div>
                  <div className="pl-[28px] flex flex-row gap-[6px] w-full items-center">
                    {!locked && (
                      <Checkbox
                        id={`checkbox-module-${module.id}`}
                        checked={checkedModuleIds.includes(module.id)}
                        onChange={(checked) => {
                          resetAllCheckboxes(checked)
                          setCheckedModuleIds(
                            checked
                              ? [...checkedModuleIds, module.id]
                              : checkedModuleIds.filter(
                                  (id) => id !== module.id,
                                ),
                          )
                        }}
                        size={CheckboxSize.Small}
                        theme={CheckboxTheme.White}
                      />
                    )}
                    <span className="whitespace-nowrap">
                      Module {moduleIndex + 1}:{' '}
                    </span>
                    {/* <span>{module.name}</span> */}
                    <TextareaAutosize
                      className={`w-full ${
                        readOnlyMode(EditPrefix.ModuleName, module.id)
                          ? 'arch-module-list-item-read-only'
                          : 'arch-module-list-item-add-input'
                      }`}
                      placeholder="Type module name here"
                      defaultValue={module.name}
                      readOnly={readOnlyMode(EditPrefix.ModuleName, module.id)}
                      onDoubleClick={() =>
                        onClickOrBlurTextArea(
                          EditPrefix.ModuleName,
                          module.id,
                          true,
                        )
                      }
                      onBlur={() =>
                        onClickOrBlurTextArea(
                          EditPrefix.ModuleName,
                          module.id,
                          false,
                        )
                      }
                      onKeyDown={onKeyDownForTextArea}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          const value = e.currentTarget.value.trim()
                          if (!value) {
                            ToastUtil.warning('Module name cannot be empty')
                            return
                          }
                          if (value !== module.name) {
                            resetTimeout(`module-name-${module.id}`)
                            updateModuleName(module.id, value)
                          }
                        } else {
                          // auto save by x seconds
                          const value = e.currentTarget.value.trim()
                          if (value !== module.name) {
                            const timeoutId = setTimeout(() => {
                              updateModuleName(module.id, value)
                            }, timeoutForSave)
                            resetTimeout(`module-name-${module.id}`, timeoutId)
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="flex flex-row items-center gap-[12px]">
                    {renderActionButtons(
                      ArchActionType.Module,
                      module.id,
                      locked,
                    )}
                  </div>
                </div>
              </li>
            )}
          </Draggable>
        )}

        {module.topics.length > 0 &&
          !isCollapsed(module.id, ArchActionType.Module) &&
          module.topics.map((topic, topicIndex) =>
            renderTopic(topic, module.id, moduleIndex, topicIndex, locked),
          )}

        {showAddTopicIds.includes(module.id) &&
          !isCollapsed(module.id, ArchActionType.Module) && (
            <li key="add-topic" className="arch-topic-list-item">
              <div>
                <span className="flex-none pr-1">
                  {moduleIndex + 1}.{module.topics.length + 1}:
                </span>
                <TextareaAutosize
                  data-module-id={module.id}
                  placeholder="Type topic name here"
                  className="arch-add-input w-full arch-add-topic-input"
                  autoFocus
                  onKeyDown={onKeyDownForTextArea}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      const topicName = e.currentTarget.value
                      if (!topicName) {
                        ToastUtil.warning('Topic name cannot be empty')
                        return
                      }
                      createTopic(module.id, topicName)
                    }
                  }}
                  disabled={isAddingTopicIds.includes(module.id)}
                />
                {isAddingTopicIds.includes(module.id) && <ButtonSpinner />}
              </div>
            </li>
          )}
      </React.Fragment>
    )
  }

  const renderCourse = (
    course: ArchCourse,
    courseIndex: number,
    locked: boolean,
  ) => {
    return (
      <React.Fragment key={`course-${courseIndex}`}>
        <li key={`course-${course.id}`}>
          <div>
            <div className="pl-[20px] flex flex-row gap-2 items-center w-full">
              {!locked && (
                <Checkbox
                  id={`checkbox-course-${course.id}`}
                  checked={checkedCourseIds.includes(course.id)}
                  onChange={(checked) => {
                    resetAllCheckboxes(checked)
                    setCheckedCourseIds(
                      checked
                        ? [...checkedCourseIds, course.id]
                        : checkedCourseIds.filter((id) => id !== course.id),
                    )
                  }}
                  size={CheckboxSize.Small}
                  theme={CheckboxTheme.Normal}
                />
              )}
              <span className="whitespace-nowrap">
                Course {courseIndex + 1}:
              </span>{' '}
              <TextareaAutosize
                className={`w-full ${
                  readOnlyMode(EditPrefix.CourseName, course.id)
                    ? 'arch-read-only'
                    : 'arch-add-input'
                }`}
                placeholder="Type course name here"
                defaultValue={course.name}
                readOnly={readOnlyMode(EditPrefix.CourseName, course.id)}
                onDoubleClick={() =>
                  onClickOrBlurTextArea(EditPrefix.CourseName, course.id, true)
                }
                onBlur={() =>
                  onClickOrBlurTextArea(EditPrefix.CourseName, course.id, false)
                }
                onKeyDown={onKeyDownForTextArea}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    const value = e.currentTarget.value.trim()
                    if (!value) {
                      ToastUtil.warning('Course name cannot be empty')
                      return
                    }
                    if (value !== course.name) {
                      resetTimeout(`course-name-${course.id}`)
                      updateCourseName(course.id, value)
                    }
                  } else {
                    // auto save by x seconds
                    const value = e.currentTarget.value.trim()
                    if (value !== course.name) {
                      const timeoutId = setTimeout(() => {
                        updateCourseName(course.id, value)
                      }, timeoutForSave)
                      resetTimeout(`course-name-${course.id}`, timeoutId)
                    }
                  }
                }}
              />
            </div>
            <div className="flex justify-end">
              {renderActionButtons(ArchActionType.Course, course.id, locked)}
            </div>
          </div>
        </li>

        {course.modules &&
          !isCollapsedCourse(course.id) &&
          course.modules.map((module, index) =>
            renderModule(module, index, locked),
          )}

        {showAddModuleIds.includes(course.id) &&
          !isCollapsedCourse(course.id) && (
            <li key="add-module" className="arch-module-list-item">
              <div>
                <span className="flex-none pr-1">
                  Module {course.modules.length + 1}:
                </span>
                <TextareaAutosize
                  data-course-id={course.id}
                  placeholder="Type module name here"
                  className="arch-add-input w-full arch-add-module-input"
                  autoFocus
                  onKeyDown={onKeyDownForTextArea}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      const moduleName = e.currentTarget.value
                      if (!moduleName) {
                        ToastUtil.warning('Module name cannot be empty')
                        return
                      }
                      createModule(course.id, moduleName)
                    }
                  }}
                  disabled={isAddingModuleIds.includes(course.id)}
                />
                {isAddingModuleIds.includes(course.id) && <ButtonSpinner />}
              </div>
            </li>
          )}
      </React.Fragment>
    )
  }

  const renderIntake = (intake: ArchIntake, intakeIndex: number) => {
    return (
      <React.Fragment key={`intake-${intakeIndex}`}>
        <li key={`intake-${intake.id}`}>
          <div>
            <div className="flex flex-row gap-2 items-center w-full">
              <div
                className="cursor-pointer hover:opacity-70"
                onClick={() => {
                  onToggleLockedForInake(intake.id, !intake.locked)
                }}
              >
                {isSuperAdmin && isLockLoading(intake.id) && (
                  <ButtonSpinner color="#6B69C1" />
                )}
                {isSuperAdmin && !isLockLoading(intake.id) && (
                  <Svg
                    icon={intake.locked ? Icon.Lock : Icon.Unlock}
                    color="#6B69C1"
                  />
                )}
              </div>
              <Svg icon={Icon.Intake} />
              <TextareaAutosize
                className={`w-full ${
                  readOnlyMode(EditPrefix.IntakeName, intake.id)
                    ? 'arch-read-only'
                    : 'arch-add-input'
                }`}
                placeholder="Type intake name here"
                defaultValue={intake.name}
                readOnly={readOnlyMode(EditPrefix.IntakeName, intake.id)}
                autoFocus={canAutoFocus(EditPrefix.IntakeName, intake.id)}
                onFocus={(e) => {
                  // set cursor to the end of the text
                  const value = e.currentTarget.value
                  e.currentTarget.value = ''
                  e.currentTarget.value = value
                }}
                onDoubleClick={() =>
                  onClickOrBlurTextArea(EditPrefix.IntakeName, intake.id, true)
                }
                onBlur={() =>
                  onClickOrBlurTextArea(EditPrefix.IntakeName, intake.id, false)
                }
                onKeyDown={onKeyDownForTextArea}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    const value = e.currentTarget.value.trim()
                    if (!value) {
                      ToastUtil.warning('Intake name cannot be empty')
                      return
                    }
                    if (value !== intake.name) {
                      resetTimeout(`intake-name-${intake.id}`)
                      updateIntakeName(intake.id, value)
                    }
                  } else {
                    // auto save by x seconds
                    const value = e.currentTarget.value.trim()
                    if (value !== intake.name) {
                      const timeoutId = setTimeout(() => {
                        updateIntakeName(intake.id, value)
                      }, timeoutForSave)
                      resetTimeout(`intake-name-${intake.id}`, timeoutId)
                    }
                  }
                }}
              />
            </div>
            <div className="flex justify-end">
              {renderActionButtons(
                ArchActionType.Intake,
                intake.id,
                intake.locked,
              )}
            </div>
          </div>
        </li>

        {intake.courses &&
          !isCollapsedIntake(intake.id) &&
          intake.courses.map((course, index) =>
            renderCourse(course, index, intake.locked),
          )}

        {showAddCourseIds.includes(intake.id) &&
          !isCollapsedIntake(intake.id) && (
            <li key="add-course" className="arch-course-list-item">
              <div>
                <span className="flex-none pr-1">
                  Course {intake.courses.length + 1}:
                </span>
                <TextareaAutosize
                  data-intake-id={intake.id}
                  placeholder="Type course name here"
                  className="arch-add-input w-full arch-add-course-input"
                  autoFocus
                  onKeyDown={onKeyDownForTextArea}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      const courseName = e.currentTarget.value
                      if (!courseName) {
                        ToastUtil.warning('Course name cannot be empty')
                        return
                      }
                      createCourse(intake.id, courseName)
                    }
                  }}
                  disabled={isAddingCourseIds.includes(intake.id)}
                />
                {isAddingCourseIds.includes(intake.id) && <ButtonSpinner />}
              </div>
            </li>
          )}
      </React.Fragment>
    )
  }

  const renderProgram = (program: ArchProgram, programIndex: number) => {
    // reset draggable index
    draggableIndex = 0
    const isAddingIntake = isAddingIntakeIds.includes(program.id)
    const result = (
      <React.Fragment key={`program-${programIndex}`}>
        <li key={`program-${program.id}`} className="arch-program-list-item">
          <div>
            <TextareaAutosize
              className={`w-full ${
                readOnlyMode(EditPrefix.ProgramName, program.id)
                  ? 'arch-read-only'
                  : 'arch-add-input'
              }`}
              placeholder="Type program name here"
              defaultValue={program.name}
              readOnly={readOnlyMode(EditPrefix.ProgramName, program.id)}
              autoFocus={canAutoFocus(EditPrefix.ProgramName, program.id)}
              onFocus={(e) => {
                // set cursor to the end of the text
                const value = e.currentTarget.value
                e.currentTarget.value = ''
                e.currentTarget.value = value
              }}
              onDoubleClick={() =>
                onClickOrBlurTextArea(EditPrefix.ProgramName, program.id, true)
              }
              onBlur={() =>
                onClickOrBlurTextArea(EditPrefix.ProgramName, program.id, false)
              }
              onKeyDown={onKeyDownForTextArea}
              onKeyUp={(e) => {
                // save on enter but no new line
                if (e.key === 'Enter') {
                  const value = e.currentTarget.value.trim()
                  if (!value) {
                    ToastUtil.warning('Program name cannot be empty')
                    return
                  }
                  if (value !== program.name) {
                    resetTimeout(`program-name-${program.id}`)
                    updateProgramName(program.id, value)
                  }
                } else {
                  // auto save by x seconds
                  const value = e.currentTarget.value.trim()
                  if (value !== program.name) {
                    const timeoutId = setTimeout(() => {
                      updateProgramName(program.id, value)
                    }, timeoutForSave)
                    resetTimeout(`program-name-${program.id}`, timeoutId)
                  }
                }
              }}
            />
            <div className="flex justify-end">
              {renderActionButtons(ArchActionType.Program, program.id)}
            </div>
          </div>
        </li>

        {program.intakes &&
          !isCollapsedProgram(program.id) &&
          filteredIntakeName === undefined &&
          program.intakes.map((intake, index) => renderIntake(intake, index))}

        {program.intakes &&
          !isCollapsedProgram(program.id) &&
          filteredIntakeName !== undefined &&
          program.intakes
            .filter((intake) => intake.name === filteredIntakeName)
            .map((intake, index) => renderIntake(intake, index))}

        {showAddIntakeIds.includes(program.id) && (
          <li key="add-intake" className="arch-intake-list-item">
            <div>
              <TextareaAutosize
                data-program-id={program.id}
                placeholder="Type intake name here"
                className="arch-add-input w-full arch-add-intake-input"
                autoFocus
                onKeyDown={onKeyDownForTextArea}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    const intakeName = e.currentTarget.value
                    if (!intakeName) {
                      ToastUtil.warning('Intake name cannot be empty')
                      return
                    }
                    createIntake(program.id, intakeName)
                  }
                }}
                disabled={isAddingIntake}
              />
              {isAddingIntake && <ButtonSpinner />}
            </div>
          </li>
        )}
      </React.Fragment>
    )
    updateDragAndDropDataDict()
    return result
  }

  const updateTopicPosition = async (moduleId: string, positions: string[]) => {
    if (!selectedClient) {
      return
    }
    try {
      await updateArchModule(moduleId, undefined, positions)
    } catch (error) {
      ToastUtil.error('Failed to update position')
      console.error(error)
    }
  }

  const moveTopicToModule = async (
    topicId: string,
    destinationModuleId: string,
  ) => {
    if (!selectedClient) {
      return
    }
    try {
      await moveArchTopic(topicId, destinationModuleId)
    } catch (error) {
      ToastUtil.error('Failed to move topic')
      console.error(error)
    }
  }

  const reorderTopics = (
    topicIds: string[],
    sourceIndex: number,
    targetIndex: number,
  ) => {
    // console.log(`sourceIndex`, sourceIndex)
    // console.log(`targetIndex`, targetIndex)
    // console.log(`before ids`, topicIds)
    const [removed] = topicIds.splice(sourceIndex, 1)
    // console.log(`removed`, removed)
    if (removed) {
      topicIds.splice(targetIndex, 0, removed)
    }
    // console.log(`after ids`, topicIds)
    // filter null and undefined values
    topicIds.filter((id) => id !== null && id !== undefined && id !== '')
    // console.log(`filter ids`, topicIds)
    return topicIds
  }

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return
    }
    if (!selectedClient) {
      return
    }
    // console.log('Drag end', result)
    // update the order
    const { source, destination } = result
    // find the dragged item - topic by index from ref
    const sourceData = refDragAndDropDataDict.current[source.index]
    const destinationData = refDragAndDropDataDict.current[destination.index]
    // console.log(`sourceData`, sourceData)
    // console.log(`destinationData`, destinationData)
    if (!sourceData || !destinationData) {
      return
    }
    // no same index
    if (source.index === destination.index) {
      return
    }

    const sourceModuleId = sourceData.moduleId
    let destinationModuleId = destinationData.moduleId
    if (
      destinationData.topicId === undefined &&
      source.index > destination.index &&
      destinationData.previousModuleId
    ) {
      destinationModuleId = destinationData.previousModuleId
    } else if (
      destinationData.topicId === undefined &&
      source.index < destination.index &&
      destinationData.nextModuleId
    ) {
      destinationModuleId = destinationData.nextModuleId
    }

    // check if moving topic to another module or reordering topics in the same module
    if (sourceModuleId === destinationModuleId) {
      // console.log('Reorder topics in the same module')
      // reorder topics in the same module, get the new ids order
      // current topic ids order in the module
      const module = selectedClient.programs
        ?.map((program) =>
          program.intakes?.map((intake) =>
            intake.courses?.map((course) =>
              course.modules?.find((module) => module.id === sourceModuleId),
            ),
          ),
        )
        .flat(3)
        .filter(Boolean)[0]
      if (!module) {
        return
      }
      // console.log(`module`, module)
      const topicIds = module.topics?.map((topic) => topic.id)
      // find the module index in the refDragAndDropDataDict
      let moduleIndex = Object.values(refDragAndDropDataDict.current).findIndex(
        (data) => data.moduleId === sourceData.moduleId,
      )
      if (moduleIndex === -1) {
        return
      }
      // skip the first item, so -2
      const sourceIndex = source.index - moduleIndex - 2
      const targetIndex = destination.index - moduleIndex - 2
      // console.log(`source`, source)
      // console.log(`destination`, destination)
      // console.log(`moduleIndex`, moduleIndex)
      // console.log(`sourceIndex`, sourceIndex)
      // console.log(`targetIndex`, targetIndex)
      // console.log(`before reordering topics`, topicIds)
      // reorder the topic ids
      const newTopicIds = reorderTopics(topicIds, sourceIndex, targetIndex)
      // console.log(`new topic ids`, newTopicIds)

      // update local state
      // find the source module
      const sourceModule = selectedClient.programs
        ?.map((program) =>
          program.intakes?.map((intake) =>
            intake.courses?.map((course) =>
              course.modules?.find(
                (module) => module.id === sourceData.moduleId,
              ),
            ),
          ),
        )
        .flat(3)
        .filter(Boolean)[0]
      if (!sourceModule) {
        return
      }
      // reorder the topics by newTopicIds
      // console.log('New topic ids', newTopicIds)
      // console.log('Before reordering topics', sourceModule.topics)
      sourceModule.topics.sort(
        (a, b) => newTopicIds.indexOf(a.id) - newTopicIds.indexOf(b.id),
      )
      // console.log('Reordered topics', sourceModule.topics)

      // update the order in database
      await updateTopicPosition(sourceData.moduleId, newTopicIds)
    } else {
      // move topic to another module
      // console.log('Move topic to another module')
      if (!sourceData.topicId || !destinationData.moduleId) {
        return
      }

      // update local state
      // find the source module
      const sourceModule = selectedClient.programs
        ?.map((program) =>
          program.intakes?.map((intake) =>
            intake.courses?.map((course) =>
              course.modules?.find((module) => module.id === sourceModuleId),
            ),
          ),
        )
        .flat(3)
        .filter(Boolean)[0]
      if (!sourceModule) {
        return
      }
      // find the destination module
      const destinationModule = selectedClient.programs
        ?.map((program) =>
          program.intakes?.map((intake) =>
            intake.courses?.map((course) =>
              course.modules?.find(
                (module) => module.id === destinationModuleId,
              ),
            ),
          ),
        )
        .flat(3)
        .filter(Boolean)[0]
      if (!destinationModule) {
        return
      }
      // find the dragged topic
      const topic = sourceModule.topics?.find(
        (topic) => topic.id === sourceData.topicId,
      )
      // console.log(`topic`, topic)
      if (!topic) {
        return
      }
      // add the topic to the destination module with correct positions
      const topicIds = destinationModule.topics?.map((topic) => topic.id)

      // find the module index in the refDragAndDropDataDict
      let moduleIndex = Object.values(refDragAndDropDataDict.current).findIndex(
        (data) => data.moduleId === destinationModuleId,
      )
      if (moduleIndex === -1) {
        return
      }
      // skip the first item, so -2
      const sourceIndex = source.index - moduleIndex - 2
      const destinationIndex = destination.index - moduleIndex - 2
      // insert draggedTopicId into topicIds
      if (sourceIndex < destinationIndex) {
        topicIds.push(topic.id)
      } else {
        topicIds.splice(destinationIndex, 0, topic.id)
      }

      // reorder the topic ids
      // console.log(`sourceIndex`, sourceIndex)
      // console.log(`targetIndex`, targetIndex)
      // console.log(`before reordering topics`, topicIds)
      const newTopicIds = reorderTopics(topicIds, sourceIndex, destinationIndex)
      // console.log(`after reordering topics`, newTopicIds)

      // update local state
      // add the dragged topic
      destinationModule.topics = [
        ...destinationModule.topics,
        {
          ...topic,
        },
      ]

      destinationModule.topics.sort(
        (a, b) => newTopicIds.indexOf(a.id) - newTopicIds.indexOf(b.id),
      )

      // remove the topic from the source module
      sourceModule.topics = sourceModule.topics?.filter(
        (topic) => topic.id !== sourceData.topicId,
      )

      // move topic to the destination module
      await moveTopicToModule(sourceData.topicId, destinationModuleId)
      // update module positions
      await updateArchModule(destinationModuleId, undefined, newTopicIds)
    }
  }

  const onSelectAll = (checked: boolean) => {
    setCheckboxSelectAll(checked)

    if (!checked) {
      // clear all checked ids
      setCheckedCourseIds([])
      setCheckedModuleIds([])
      setCheckedTopicIds([])
    }

    if (!selectedClient) {
      return
    }

    // selected courses, checked all courses in the same program
    if (checked && checkedCourseIds.length > 0) {
      // find the program
      const program = selectedClient.programs?.find((program) =>
        program.intakes?.find((intake) =>
          intake.courses?.find((course) => course.id === checkedCourseIds[0]),
        ),
      )
      if (program) {
        const courseIds = program.intakes
          ?.map((intake) => intake.courses?.map((course) => course.id))
          .flat()
          .filter(Boolean)
        setCheckedCourseIds(courseIds)
      }
    } else if (checked && checkedModuleIds.length > 0) {
      // selected modules, checked all modules in the same course
      const lastModuleId = checkedModuleIds[checkedModuleIds.length - 1]
      const course = selectedClient.programs
        ?.find((program) =>
          program.intakes?.find((intake) =>
            intake.courses?.find((course) =>
              course.modules?.find((module) => module.id === lastModuleId),
            ),
          ),
        )
        ?.intakes?.find((intake) =>
          intake.courses?.find((course) =>
            course.modules?.find((module) => module.id === lastModuleId),
          ),
        )
        ?.courses?.find((course) =>
          course.modules?.find((module) => module.id === lastModuleId),
        )
      if (course) {
        const moduleIds = course.modules?.map((module) => module.id)
        setCheckedModuleIds(moduleIds)
      }
    } else if (checked && checkedTopicIds.length > 0) {
      // selected topics, checked all topics in the same module
      const lastTopicId = checkedTopicIds[checkedTopicIds.length - 1]
      const module = selectedClient.programs
        ?.find((program) =>
          program.intakes?.find((intake) =>
            intake.courses?.find((course) =>
              course.modules?.find((module) =>
                module.topics?.find((topic) => topic.id === lastTopicId),
              ),
            ),
          ),
        )
        ?.intakes?.find((intake) =>
          intake.courses?.find((course) =>
            course.modules?.find((module) =>
              module.topics?.find((topic) => topic.id === lastTopicId),
            ),
          ),
        )
        ?.courses?.find((course) =>
          course.modules?.find((module) =>
            module.topics?.find((topic) => topic.id === lastTopicId),
          ),
        )
        ?.modules?.find((module) =>
          module.topics?.find((topic) => topic.id === lastTopicId),
        )
      if (module) {
        const topicIds = module.topics?.map((topic) => topic.id)
        setCheckedTopicIds(topicIds)
      }
    }
  }

  const onUpdatedArchTopic = useCallback(
    (data: { topic: ArchTopic }) => {
      // TODO: update the topic in the list locally
      if (!selectedClient) {
        return
      }
      fetchClient(selectedClient.id, true)
    },
    [fetchClient, selectedClient],
  )

  const onReloadClient = useCallback(() => {
    if (!selectedClient) return
    fetchClient(selectedClient.id, true)
  }, [fetchClient, selectedClient])

  const onCopiedObject = useCallback(
    async (data: {
      clientId?: string
      programId?: string
      intakeId?: string
      courseId?: string
      courseIds?: string[]
      moduleId?: string
      moduleIds?: string[]
      topicId?: string
      topicIds?: string[]
    }) => {
      // console.log(`onCopiedObject`, data)
      if (!selectedClient || !data.clientId) return
      if (selectedClient.id === data.clientId) {
        // set copied object data to refClonedObject
        refclonedObject.current = data

        await fetchClient(selectedClient.id, true)
        // console.log('Copied object is in the same client')
      } else {
        // reset
        refclonedObject.current = {}
      }
    },
    [fetchClient, selectedClient],
  )

  const onSaveAndExit = useCallback(async () => {
    // find all program add input by id
    const addInput = document.getElementById('arch-add-program-input')
    // check if there is any not empty value, if yes, save it
    if (addInput) {
      // convert to html input element
      const addInputEl = addInput as HTMLInputElement
      const value = addInputEl.value.trim()
      if (value) {
        createProgram(value)
      }
    }

    // find all intake add input by class name, filter no id
    const addIntakeInputs = document.getElementsByClassName(
      'arch-add-intake-input',
    )
    // check if there is any not empty value, if yes, save it
    for (let i = 0; i < addIntakeInputs.length; i++) {
      const addIntakeInput = addIntakeInputs[i] as HTMLInputElement
      const value = addIntakeInput.value.trim()
      if (value) {
        const programId = addIntakeInput.dataset.programId
        if (programId) {
          // console.log(`programId`, programId)
          createIntake(programId, value)
        }
      }
    }

    // find all course add input by class name, filter no id
    const addCourseInputs = document.getElementsByClassName(
      'arch-add-course-input',
    )
    // check if there is any not empty value, if yes, save it
    for (let i = 0; i < addCourseInputs.length; i++) {
      const addCourseInput = addCourseInputs[i] as HTMLInputElement
      const value = addCourseInput.value.trim()
      if (value) {
        const intakeId = addCourseInput.dataset.intakeId
        if (intakeId) {
          // console.log(`intakeId`, intakeId)
          createCourse(intakeId, value)
        }
      }
    }

    // find all module add input by class name, filter no id
    const addModuleInputs = document.getElementsByClassName(
      'arch-add-module-input',
    )
    // check if there is any not empty value, if yes, save it
    for (let i = 0; i < addModuleInputs.length; i++) {
      const addModuleInput = addModuleInputs[i] as HTMLInputElement
      const value = addModuleInput.value.trim()
      if (value) {
        const courseId = addModuleInput.dataset.courseId
        if (courseId) {
          // console.log(`courseId`, courseId)
          createModule(courseId, value)
        }
      }
    }

    // find all topic add input by class name, filter no id
    const addTopicInputs = document.getElementsByClassName(
      'arch-add-topic-input',
    )
    // check if there is any not empty value, if yes, save it
    for (let i = 0; i < addTopicInputs.length; i++) {
      const addTopicInput = addTopicInputs[i] as HTMLInputElement
      const value = addTopicInput.value.trim()
      if (value) {
        const moduleId = addTopicInput.dataset.moduleId
        if (moduleId) {
          // console.log(`moduleId`, moduleId)
          createTopic(moduleId, value)
        }
      }
    }
  }, [createCourse, createIntake, createModule, createProgram, createTopic])

  useEffect(() => {
    if (isLogged && client) {
      fetchClient(client.id, false)
    }
  }, [isLogged, fetchClient, client])

  useEffect(() => {
    Emitter.on(Events.UpdatedArchTopic, onUpdatedArchTopic)
    Emitter.on(Events.ReloadArchClient, onReloadClient)
    Emitter.on(Events.CopiedArchObject, onCopiedObject)
    Emitter.on(Events.ArchSaveAndExit, onSaveAndExit)
    return () => {
      Emitter.off(Events.UpdatedArchTopic, onUpdatedArchTopic)
      Emitter.off(Events.ReloadArchClient, onReloadClient)
      Emitter.off(Events.CopiedArchObject, onCopiedObject)
      Emitter.off(Events.ArchSaveAndExit, onSaveAndExit)
    }
  }, [onCopiedObject, onReloadClient, onSaveAndExit, onUpdatedArchTopic])

  return (
    <>
      <div className="flex-1">
        {(isFetchingClient || isLoading) && <Spinner />}
        {!isFetchingClient && selectedClient && (
          <>
            {/* Confirmation */}
            <div className="flex flex-row gap-[12px] items-center min-h-[32px] pb-1">
              {showDeleteConfirmation &&
                (checkedActionType() !== null || singleDelete !== null) && (
                  <>
                    <span className="mentem-action-text">
                      {renderDeleteConfirmationText()}
                    </span>
                    <button
                      className={StyleUtil.buttonPrimary}
                      onClick={() => {
                        onConfirmDelete()
                      }}
                      disabled={isDeleting}
                    >
                      {isDeleting ? <ButtonSpinner /> : 'Yes'}
                    </button>
                    <button
                      className="mentem-action-cancel"
                      onClick={() => setShowDeleteConfirmation(false)}
                    >
                      Cancel
                    </button>
                    {checkedActionType() !== null && (
                      <>
                        <span className="mentem-action-or">or</span>
                        <button
                          className="mentem-action-clear"
                          onClick={() => {
                            setShowDeleteConfirmation(false)
                            setCheckedCourseIds([])
                            setCheckedModuleIds([])
                            setCheckedTopicIds([])
                          }}
                        >
                          clear selection(s)
                        </button>
                      </>
                    )}
                  </>
                )}
              {!showDeleteConfirmation && checkedActionType() !== null && (
                <>
                  <Checkbox
                    id="checkbox-arch-select-all"
                    checked={checkboxSelectAll}
                    onChange={(checked) => onSelectAll(checked)}
                    size={CheckboxSize.Medium}
                    theme={CheckboxTheme.Normal}
                  />
                  <label
                    htmlFor="checkbox-arch-select-all"
                    className="mentem-action-text ml-[-8px]"
                    onClick={() => onSelectAll(!checkboxSelectAll)}
                  >
                    {renderAllSelectText()}
                  </label>
                  <span
                    className="mentem-action-delete"
                    onClick={() => setShowDeleteConfirmation(true)}
                  >
                    {renderDeleteFromText()}
                  </span>
                  <span
                    className="arch-duplicate-text"
                    onClick={onDuplicateSelectedItems}
                  >
                    {renderDuplicateToText()}
                  </span>
                </>
              )}
            </div>
            {/* Architecture editor list */}
            <div className="overflow-y-auto mentem-scrollbar-2 max-h-[50vh]">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="arch-list">
                  {(provided) => (
                    <ul
                      className="arch-editor-list divide-slate-200 divide-y"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <li key="programs" className="arch-editor-list-title">
                        <div>
                          <div className="w-[70%]">Program(s):</div>
                          <div className="flex justify-end w-[30%]">
                            {renderActionButtons(
                              ArchActionType.Client,
                              selectedClient.id,
                            )}
                          </div>
                        </div>
                      </li>
                      {selectedClient.programs &&
                        filteredProgramName === undefined &&
                        selectedClient.programs.map((program, index) =>
                          renderProgram(program, index),
                        )}
                      {selectedClient.programs &&
                        filteredProgramName !== undefined &&
                        selectedClient.programs
                          .filter(
                            (program) => program.name === filteredProgramName,
                          )
                          .map((program, index) =>
                            renderProgram(program, index),
                          )}
                      {showAddProgram && (
                        <li
                          key="add-program"
                          className="arch-program-list-item"
                        >
                          <div>
                            <TextareaAutosize
                              id="arch-add-program-input"
                              placeholder="Type program name here"
                              className="arch-add-input w-full"
                              autoFocus
                              onKeyDown={onKeyDownForTextArea}
                              onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                  const programName = e.currentTarget.value
                                  if (!programName) {
                                    ToastUtil.warning(
                                      'Program name cannot be empty',
                                    )
                                    return
                                  }
                                  createProgram(programName)
                                }
                              }}
                              disabled={isAddingProgram}
                            />
                            {isAddingProgram && <ButtonSpinner />}
                          </div>
                        </li>
                      )}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </>
        )}
      </div>
      {/* Tooltips */}
      <Tooltip
        id="tooltip-upload-excel"
        className="mentem-tooltip"
        style={tooltipStyle}
        place="top"
        noArrow={true}
      >
        Upload excel
      </Tooltip>
      <Tooltip
        id="tooltip-move-up-topic"
        className="mentem-tooltip"
        style={tooltipStyle}
        place="top"
        noArrow={true}
      >
        Move up
      </Tooltip>
      <Tooltip
        id="tooltip-move-down-topic"
        className="mentem-tooltip"
        style={tooltipStyle}
        place="top"
        noArrow={true}
      >
        Move down
      </Tooltip>
      <Tooltip
        id="tooltip-move-up-module"
        className="mentem-tooltip"
        style={tooltipStyle}
        place="top"
        noArrow={true}
      >
        Move up
      </Tooltip>
      <Tooltip
        id="tooltip-move-down-module"
        className="mentem-tooltip"
        style={tooltipStyle}
        place="top"
        noArrow={true}
      >
        Move down
      </Tooltip>
      <Tooltip
        id="tooltip-move-topic"
        className="mentem-tooltip"
        style={tooltipStyle}
        place="top"
        noArrow={true}
      >
        Move topic
      </Tooltip>

      {[
        ArchActionType.Program,
        ArchActionType.Intake,
        ArchActionType.Course,
        ArchActionType.Module,
        ArchActionType.Topic,
      ].map((type, index) => (
        <React.Fragment key={index}>
          <Tooltip
            id={`tooltip-add-${type}`}
            className="mentem-tooltip"
            style={tooltipStyle}
            place="top"
            noArrow={true}
          >
            Add {type}
          </Tooltip>
          <Tooltip
            id={`tooltip-copy-${type}`}
            className="mentem-tooltip"
            style={tooltipStyle}
            place="top"
            noArrow={true}
          >
            Duplicate {type}
          </Tooltip>
          <Tooltip
            id={`tooltip-delete-${type}`}
            className="mentem-tooltip"
            style={tooltipStyle}
            place="top"
            noArrow={true}
          >
            Delete {type}
          </Tooltip>
        </React.Fragment>
      ))}
    </>
  )
}
