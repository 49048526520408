import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StyleUtil } from 'utils/StyleUtil'
import { useApi } from 'providers/ApiProvider'
import { Quiz, QuizStatus } from 'apis/entities/quiz.entity'
import Spinner from 'views/Spinner'
import sortSvg from 'images/sort.svg'
import sort2Svg from 'images/sort2.svg'
import excelSvg from 'images/excel.svg'
import { Tooltip } from 'react-tooltip'
import { Permission, Role, useAuth } from 'providers/AuthProvider'
import { ToastUtil } from 'utils/ToastUtil'
import DocModifyVal from 'components/DocModifyVal'
import { useNavigate } from 'react-router-dom'
import Path from 'routes/Path'
import DropDownMenu, { DropDownItem } from './DropDownMenu'
import SearchInput from './SearchInput'
import { getDateText, getTimeText } from 'utils/StringUtil'
import ButtonSpinner from './ButtonSpinner'

enum SortBy {
  Name = 'name',
  UpdatedBy = 'updatedByUserName',
  LastUpdated = 'updatedAt',
  Status = 'status',
}

type Direction = 'ascending' | 'descending' | ''

const tooltipStyle = {
  backgroundColor: '#fff',
  color: '#000',
  borderRadius: '6px',
}

const tooltipStyleForStatus = {
  backgroundColor: '#fff',
  color: '#000',
  borderRadius: '6px',
  height: 'auto',
}

const tooltipStyleReverse = {
  backgroundColor: '#6b69c1',
  color: '#fff',
  borderRadius: '6px',
}

const updatedByOptionLabel = {
  id: '0',
  name: 'Updated by',
  value: '0',
  isLabel: true,
}

const updatedByOptionShowAll = {
  id: '1',
  name: 'Show all',
  value: '',
  isLabel: false,
}

type StatusOptions = {
  draft: boolean
  sent: boolean
  reviewed: boolean
  approved: boolean
  deleted: boolean
}

type ManageQuizzesProps = {
  setShowCreateWidget: (show: boolean) => void
}

const ManageQuizzes = ({ setShowCreateWidget }: ManageQuizzesProps) => {
  const navigate = useNavigate()
  const {
    isLogged,
    getRole,
    permissions,
    auth0User: user,
    getAuth0UserDetails,
  } = useAuth()

  const defaultStatusFilter: StatusOptions =
    getRole() === Role.SuperAdmin || getRole() === Role.Admin
      ? {
          draft: true,
          sent: true,
          reviewed: true,
          approved: true,
          deleted: false,
        }
      : {
          draft: true,
          sent: true,
          reviewed: true,
          approved: true,
          deleted: false,
        }

  const {
    getQuizzes,
    updateQuiz,
    exportQuiz,
    getQuizUpdatedBy,
    batchDeleteQuizzes,
    batchRestoreQuizzes,
  } = useApi()
  const [quizzes, setQuizzes] = useState<Quiz[]>([])
  const [isFetched, setIsFetched] = useState<boolean>(false)
  const [sortConfig, setSortConfig] = useState<{
    key: SortBy
    direction: Direction
  } | null>(null)
  const [orderByName, setOrderByName] = useState<Direction>('')
  const [orderByUpdatedBy, setOrderByUpdatedBy] = useState<Direction>('')
  const [orderByLastUpdated, setOrderByLastUpdated] = useState<Direction>('')
  const [orderByStatus, setOrderByStatus] = useState<Direction>('')
  // default render rows
  const [renderRowCount, setRenderRowCount] = useState<number>(20)
  const ROW_COUNT = 20

  const [isSearching, setIsSearching] = useState<boolean>(false)
  const refSearchTextInput = useRef<string>('')
  const [updatedByOptions, setUpdatedByOptions] = useState<any>([
    updatedByOptionLabel,
  ])
  const refUpdatedBy = useRef<string>('')
  const refStartDate = useRef<Date | undefined>(undefined)
  const [statusFilter, setStatusFilter] =
    useState<StatusOptions>(defaultStatusFilter)
  const [isClickDelete, setIsClickDelete] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isRestoring, setIsRestoring] = useState<boolean>(false)
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [showActiveQuizzes, setShowActiveQuizzes] = useState<boolean>(true)

  const hasPermission = useCallback(
    (permission: string, createdAuth0Id?: string): boolean => {
      // https://horizonsfl.atlassian.net/browse/HLP-1465
      if (getRole() === Role.SuperAdmin || getRole() === Role.Admin) return true
      if (
        permissions &&
        permissions.includes(permission) &&
        user?.sub === createdAuth0Id
      ) {
        // same user
        return true
      }
      // Author can only edit
      if (permissions && permissions.includes(permission)) {
        return true
      }
      return false
    },
    [getRole, permissions, user?.sub],
  )

  const isFilteredByDeleted = (): boolean => {
    return (
      statusFilter.deleted &&
      !statusFilter.draft &&
      !statusFilter.sent &&
      !statusFilter.reviewed &&
      !statusFilter.approved
    )
  }

  const getTooptipStyle = (key: SortBy): any => {
    switch (key) {
      case SortBy.Name:
        return orderByName === 'ascending' ? tooltipStyleReverse : tooltipStyle
      case SortBy.UpdatedBy:
        return orderByUpdatedBy === 'ascending'
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.LastUpdated:
        return orderByLastUpdated === 'ascending'
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.Status:
        return orderByStatus === 'ascending'
          ? tooltipStyleReverse
          : tooltipStyle
      default:
        return tooltipStyle
    }
  }

  const getSortingText = (key: SortBy): string => {
    switch (key) {
      case SortBy.Name:
        if (orderByName === 'ascending') return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.UpdatedBy:
        if (orderByUpdatedBy === 'ascending') return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.LastUpdated:
        if (orderByLastUpdated === 'ascending') return 'Sort new to old'
        return 'Sort old to new'
      case SortBy.Status:
        if (orderByStatus === 'ascending') return 'Sort approved to draft'
        return 'Sort draft to approved'
      default:
        return ''
    }
  }

  const onClickSort = (key: SortBy) => {
    let ascending = true
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      ascending = false
    }

    switch (key) {
      case SortBy.Name:
        setOrderByName(ascending ? 'ascending' : 'descending')
        break
      case SortBy.UpdatedBy:
        setOrderByUpdatedBy(ascending ? 'ascending' : 'descending')
        break
      case SortBy.LastUpdated:
        setOrderByLastUpdated(ascending ? 'ascending' : 'descending')
        break
      case SortBy.Status:
        setOrderByStatus(ascending ? 'ascending' : 'descending')
        break
      default:
        break
    }

    setSortConfig({ key, direction: ascending ? 'ascending' : 'descending' })
  }

  const hasFilter = useCallback((): boolean => {
    return (
      statusFilter.draft ||
      statusFilter.sent ||
      statusFilter.reviewed ||
      statusFilter.approved ||
      statusFilter.deleted
    )
  }, [statusFilter])

  const sortedQuizzes = useMemo(() => {
    let sortableItems = quizzes?.length ? [...quizzes] : []
    // console.log('sortedQuizzes', sortableItems.length)
    // apply filter
    if (hasFilter()) {
      sortableItems = sortableItems.filter((item) => {
        if (
          statusFilter.draft &&
          item.status === QuizStatus.Draft &&
          item.approved !== true &&
          item.deletedAt === null
        )
          return true
        if (
          statusFilter.sent &&
          item.status === QuizStatus.Sent &&
          item.approved !== true &&
          item.deletedAt === null
        )
          return true
        if (
          statusFilter.reviewed &&
          item.status === QuizStatus.Reviewed &&
          item.approved !== true &&
          item.deletedAt === null
        )
          return true
        if (statusFilter.approved && item.approved) return true
        if (statusFilter.deleted && item.deletedAt) return true
        return false
      })
    } else {
      // if no filter, no items
      sortableItems = []
    }

    if (sortConfig !== null) {
      sortableItems.sort((a: Quiz, b: Quiz) => {
        if (sortConfig.key === SortBy.Status) {
          // sort by order: approved, deleted, reviewed, sent, draft
          if (a.approved && !b.approved) {
            return -1
          }
          if (!a.approved && b.approved) {
            return 1
          }

          if (a.deletedAt && !b.deletedAt) {
            return -1
          }
          if (!a.deletedAt && b.deletedAt) {
            return 1
          }

          if (
            a.status === QuizStatus.Reviewed &&
            b.status !== QuizStatus.Reviewed
          ) {
            return -1
          }
          if (
            a.status !== QuizStatus.Reviewed &&
            b.status === QuizStatus.Reviewed
          ) {
            return 1
          }
          if (a.status === QuizStatus.Sent && b.status !== QuizStatus.Sent) {
            return -1
          }
          if (a.status !== QuizStatus.Sent && b.status === QuizStatus.Sent) {
            return 1
          }
          if (a.status === QuizStatus.Draft && b.status !== QuizStatus.Draft) {
            return -1
          }
          if (a.status !== QuizStatus.Draft && b.status === QuizStatus.Draft) {
            return 1
          }
          return 0
        }

        // @ts-ignore
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        // @ts-ignore
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }

    if (sortConfig?.key === SortBy.Status) {
      if (sortConfig.direction === 'descending') {
        sortableItems.reverse()
      }
    }
    return sortableItems
  }, [hasFilter, quizzes, sortConfig, statusFilter])

  const fetchUpdatedBy = useCallback(async () => {
    const updatedBy = await getQuizUpdatedBy()
    const allOptions = [updatedByOptionLabel]
    updatedBy.forEach((i) => {
      allOptions.push({
        id: i.updatedByAuth0Id,
        name: i.updatedByUserName,
        value: i.updatedByAuth0Id,
        isLabel: false,
      })
    })
    allOptions.push(updatedByOptionShowAll)
    setUpdatedByOptions(allOptions)
  }, [getQuizUpdatedBy])

  const fetchQuizzes = useCallback(async () => {
    if (!isLogged) return
    setIsFetched(false)
    const quizzes = await getQuizzes(
      undefined,
      undefined,
      undefined,
      undefined,
      hasPermission(Permission.DeleteQuizzes),
    )
    setQuizzes(quizzes)

    // update updatedBy options
    fetchUpdatedBy()

    setIsFetched(true)
  }, [fetchUpdatedBy, getQuizzes, hasPermission, isLogged])

  const onClickStatus = useCallback(
    (quiz: Quiz) => {
      navigate(`${Path.quizzes.path}/${quiz.id}`)
    },
    [navigate],
  )

  const handleScroll = useCallback(
    (e: Event) => {
      // check if scrolling to bottom
      const target = e.target as HTMLElement
      if (
        Math.ceil(target.scrollTop + target.clientHeight) >=
        Math.floor(target.scrollHeight)
      ) {
        // console.log('scroll to bottom')
        // get the number of rows
        const rows = document.querySelectorAll('tbody tr')
        const startIndex = rows.length
        // render 10 more rows if available
        const endIndex = Math.min(startIndex + ROW_COUNT, sortedQuizzes.length)
        if (endIndex <= startIndex) return
        // console.log('renderRows', startIndex, endIndex, renderRowCount)
        setRenderRowCount(endIndex)
      }
    },
    [sortedQuizzes],
  )

  const getStatusStyle = (quiz: Quiz): string => {
    const { approved, status, deletedAt } = quiz
    if (approved) return 'quiz-status-approved'
    if (deletedAt) return 'quiz-status-deleted'
    switch (status) {
      case QuizStatus.Draft:
        return 'quiz-status-draft'
      case QuizStatus.Reviewed:
        return 'quiz-status-reviewed'
      case QuizStatus.Sent:
        return 'quiz-status-sent'
      default:
        return ''
    }
  }

  const getStatusText = useCallback((quiz: Quiz): string => {
    const { approved, status, deletedAt } = quiz
    if (approved) return 'Approved'
    if (deletedAt) return 'Deleted'
    switch (status) {
      case QuizStatus.Draft:
        return 'Draft'
      case QuizStatus.Reviewed:
        return 'Reviewed'
      case QuizStatus.Sent:
        return 'Sent on'
      default:
        return status
    }
  }, [])

  const canEdit = useCallback(
    (quiz: Quiz): boolean => {
      if (quiz.status === QuizStatus.Sent || quiz.approved === true)
        return false
      if (hasPermission(Permission.UpdateQuizzes)) return true
      return false
    },
    [hasPermission],
  )

  const onClickExport = useCallback(
    async (quizId: string) => {
      try {
        const blob = await exportQuiz(quizId)
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        // filename with quiz name and human readable date
        const quiz = quizzes.find((q) => q.id === quizId)
        const quizName = quiz?.name.replace(/ /g, '_')
        const date = new Date()
        const dateStr = date.toISOString().split('T')[0]
        const filename = `${quizName}_${dateStr}.xlsx`

        link.setAttribute('download', filename)
        document.body.appendChild(link)

        // Trigger the download
        link.click()

        // Cleanup
        link.parentNode?.removeChild(link)
        window.URL.revokeObjectURL(url)
      } catch (error) {
        ToastUtil.error('Failed to export quiz')
      }
    },
    [exportQuiz, quizzes],
  )

  const onChangeQuizCheckbox = useCallback(
    (checked: boolean, id: string) => {
      setIsClickDelete(false)
      if (checked) {
        setCheckedIds([...checkedIds, id])
      } else {
        setCheckedIds(checkedIds.filter((y) => y !== id))
      }
    },
    [checkedIds],
  )

  const searchQuzzies = useCallback(async () => {
    if (!isLogged) return
    const updatedBy = refUpdatedBy.current
    const search = refSearchTextInput.current
    const dateStart = refStartDate.current
    const dateEnd = dateStart ? new Date() : undefined
    const withDeleted = hasPermission(Permission.DeleteQuizzes)
    setIsSearching(true)
    const quizzes = await getQuizzes(
      search,
      updatedBy,
      dateStart,
      dateEnd,
      withDeleted,
    )
    if (quizzes) {
      setQuizzes(quizzes)
    }
    setIsSearching(false)
  }, [getQuizzes, hasPermission, isLogged])

  const updateQuizWithUser = useCallback(
    async (quizId: string, name?: string, description?: string) => {
      const user = getAuth0UserDetails()
      const auth0Id = user?.id
      const username = user?.username
      const firstName = user?.firstName
      const email = user?.email
      await updateQuiz(
        quizId,
        name,
        description,
        undefined,
        auth0Id,
        username,
        firstName,
        email,
      )
    },
    [getAuth0UserDetails, updateQuiz],
  )

  // exmaple 1: 1 review complete, 2 outstanding - lorem.ipsum@cba.com.au, abcdefg@cba.com.au
  // exmaple 2: 2 out of 3 have reviewed, 1 outstanding - lorem.ipsum@cba.com.au
  const renderReviewerStatus = useCallback((quiz: Quiz): JSX.Element => {
    let { reviewers } = quiz
    reviewers = reviewers.filter((r) => r.version === quiz.version)
    if (reviewers && reviewers.length > 0) {
      const reviewedEmails = reviewers.filter((r) => r.reviewedAt)
      let outstandingEmails = reviewers.filter((r) => !r.reviewedAt)
      // sort by alphabetical order
      outstandingEmails = outstandingEmails.sort((a, b) => {
        if (a.email < b.email) return -1
        if (a.email > b.email) return 1
        return 0
      })
      const reviewedCount = reviewedEmails.length
      const outstandingCount = outstandingEmails.length
      let reviewedText = ''
      let outstandingText = ''
      if (reviewedCount === 0) {
        reviewedText = `0 reviews complete`
      } else if (reviewedCount === 1) {
        reviewedText = `1 review complete`
      } else if (reviewedCount > 1) {
        reviewedText = `${reviewedCount} out of ${reviewers.length} have reviewed`
      }
      if (outstandingCount >= 1) {
        outstandingText = `${outstandingCount} outstanding -`
      }
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>
            {reviewedText}, {outstandingText}
          </span>
          {outstandingEmails.map((r, i) => (
            <span key={i}>
              {r.email}
              {i < outstandingEmails.length - 1 ? ',' : ''}
            </span>
          ))}
        </div>
      )
    }
    return <></>
  }, [])

  const renderIndicatorEmpty = () => {
    return (
      <svg
        width="38"
        height="38"
        viewBox="-6 -5 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="30" height="30" transform="translate(5.5 1)" fill="none" />
        <g filter="url(#filter0_dddd_3208_77264)">
          <circle
            cx="20.5"
            cy="16"
            r="14.5"
            fill="url(#paint0_linear_3208_77264)"
            stroke="url(#paint1_linear_3208_77264)"
          />
        </g>
        <defs>
          <filter
            id="filter0_dddd_3208_77264"
            x="0.5"
            y="0"
            width="40"
            height="49"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_3208_77264"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="1.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_3208_77264"
              result="effect2_dropShadow_3208_77264"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="7" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_dropShadow_3208_77264"
              result="effect3_dropShadow_3208_77264"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="13" />
            <feGaussianBlur stdDeviation="2.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
            />
            <feBlend
              mode="normal"
              in2="effect3_dropShadow_3208_77264"
              result="effect4_dropShadow_3208_77264"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect4_dropShadow_3208_77264"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_3208_77264"
            x1="7"
            y1="10.5"
            x2="35.5"
            y2="19.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3208_77264"
            x1="9.5"
            y1="6"
            x2="30.5"
            y2="27"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0061FF" />
            <stop offset="1" stopColor="#BD69FE" />
          </linearGradient>
        </defs>
      </svg>
    )
  }

  const renderIndicator = useCallback((percentage: number, quizId: string) => {
    if (percentage === 0) {
      return renderIndicatorEmpty()
    }

    // Calculate angle based on the given percentage (0-100)
    const angle = (1 - percentage / 100) * 360
    // Calculate the end point of the arc
    const startX = 20.5 + 19.5 * Math.cos((-90 * Math.PI) / 180) // Starting point at the top of the circle
    const startY = 20.5 + 19.5 * Math.sin((-90 * Math.PI) / 180)
    const endX = 20.5 + 19.5 * Math.cos((-90 + angle) * (Math.PI / 180))
    const endY = 20.5 + 19.5 * Math.sin((-90 + angle) * (Math.PI / 180))

    // for mask unique id
    const index = quizId

    return (
      <svg
        width="30"
        height="30"
        viewBox="0 -4 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id={`fraction-mask-${index}`}>
          <path
            d={`M${startX},${startY} A 19.5 19.5 0 ${
              angle > 180 ? 0 : 1
            } 0 ${endX},${endY} L20.5 20.5 Z`}
            fill="white"
          />
        </mask>
        <defs>
          <filter
            id="filter0_dddd_3187_59658"
            x="0.5"
            y="0"
            width="40"
            height="49"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_3187_59658"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="1.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_3187_59658"
              result="effect2_dropShadow_3187_59658"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="7" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_dropShadow_3187_59658"
              result="effect3_dropShadow_3187_59658"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="13" />
            <feGaussianBlur stdDeviation="2.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
            />
            <feBlend
              mode="normal"
              in2="effect3_dropShadow_3187_59658"
              result="effect4_dropShadow_3187_59658"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect4_dropShadow_3187_59658"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_3187_59658"
            x1="7"
            y1="10.5"
            x2="35.5"
            y2="19.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2862CC" />
            <stop offset="0.645" stopColor="#BD69FE" />
            <stop offset="1" stopColor="#BD69FE" />
          </linearGradient>
        </defs>
        <g
          mask={`url(#fraction-mask-${index})`}
          filter="url(#filter0_dddd_3187_59658)"
        >
          <path
            d="M20.5 1C9.41828 1 1 9.41828 1 20.5C1 31.5817 9.41828 40 20.5 40C31.5817 40 40 31.5817 40 20.5C40 9.41828 31.5817 1 20.5 1Z"
            fill="url(#paint0_linear_3187_59658)"
          />
        </g>
      </svg>
    )
  }, [])

  const renderRow = useCallback(
    (quiz: Quiz, _index: number) => {
      return (
        <tr data-testid="tr-document" key={quiz.id}>
          <td className="mentem-table-cell-2">
            {canEdit(quiz) && (
              <div className="flex flex-row items-center">
                {hasPermission(Permission.DeleteQuizzes) && (
                  <input
                    type="checkbox"
                    className="self-center"
                    disabled={quiz.approved}
                    data-id="checkbox"
                    checked={checkedIds.includes(quiz.id)}
                    onChange={(e) =>
                      onChangeQuizCheckbox(e.target.checked, quiz.id)
                    }
                  />
                )}
                <div className="pl-[6px]">
                  <DocModifyVal
                    what={quiz.name.trim()}
                    placeholder="Type quiz name here"
                    updateVal={async (newVal: string) => {
                      if (newVal.trim() === '') {
                        ToastUtil.warning('Quiz name cannot be empty')
                        return
                      }
                      await updateQuizWithUser(quiz.id, newVal)
                      await searchQuzzies()
                      ToastUtil.success(`Modified successfully`)
                    }}
                    className="resize-none"
                  />
                </div>
              </div>
            )}
            {!canEdit(quiz) && (
              <div className="flex flex-row">
                {hasPermission(Permission.DeleteQuizzes) && (
                  <input
                    type="checkbox"
                    className="self-center"
                    disabled={quiz.approved}
                    data-id="checkbox"
                    checked={checkedIds.includes(quiz.id)}
                    onChange={(e) =>
                      onChangeQuizCheckbox(e.target.checked, quiz.id)
                    }
                  />
                )}
                <div className="pl-[6px]">
                  <div className="mentem-title-box">{quiz.name}</div>
                </div>
              </div>
            )}
          </td>
          <td className="mentem-table-cell-1">
            {canEdit(quiz) && (
              <DocModifyVal
                what={quiz.description}
                updateVal={async (newVal: string) => {
                  if (newVal.trim() === '') {
                    ToastUtil.warning('Description cannot be empty')
                    return
                  }
                  await updateQuizWithUser(quiz.id, undefined, newVal)
                  await searchQuzzies()
                  ToastUtil.success(`Modified successfully`)
                }}
              />
            )}
            {!canEdit(quiz) && (
              <div className="mentem-comment-box">{quiz.description}</div>
            )}
          </td>
          <td className="mentem-table-cell-2 w-auto">
            <div className="flex items-center justify-center">
              {quiz.updatedByUserName || 'Unknown'}
            </div>
          </td>
          <td className="mentem-table-cell-1 w-auto">
            <div className="flex items-center justify-center">
              {getDateText(quiz.updatedAt)}
            </div>
            <div className="flex items-center justify-center">
              {getTimeText(quiz.updatedAt)}
            </div>
          </td>
          <td className="mentem-table-cell-2 w-auto">
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={() => onClickExport(quiz.id)}
            >
              <img src={excelSvg} alt="excel" />
            </div>
          </td>
          <td className="mentem-table-cell-1 w-auto">
            {quiz.status !== QuizStatus.Sent && (
              <div
                className={getStatusStyle(quiz)}
                onClick={() => onClickStatus(quiz)}
              >
                {getStatusText(quiz)}
              </div>
            )}
            {quiz.status === QuizStatus.Sent && quiz.deletedAt != null && (
              <div
                className={getStatusStyle(quiz)}
                onClick={() => onClickStatus(quiz)}
              >
                {getStatusText(quiz)}
              </div>
            )}
            {quiz.status === QuizStatus.Sent && quiz.deletedAt === null && (
              <div
                data-tooltip-id={`tooltip-sent-${quiz.id}`}
                className="flex flex-row items-center justify-center pl-[12px] pr-[18px]"
              >
                <div className="w-[40px] flex flex-row items-center justify-end">
                  {renderIndicator(getPercentageOfReviewed(quiz), quiz.id)}
                </div>
                <div className="flex flex-col flex-1">
                  <div
                    className={getStatusStyle(quiz)}
                    onClick={() => onClickStatus(quiz)}
                  >
                    {getStatusText(quiz)}
                  </div>
                  <div
                    className={getStatusStyle(quiz)}
                    onClick={() => onClickStatus(quiz)}
                  >
                    {getDateText(quiz.sentAt)}
                  </div>
                </div>
                <Tooltip
                  id={`tooltip-sent-${quiz.id}`}
                  className="mentem-tooltip"
                  style={tooltipStyleForStatus}
                  place="top"
                  noArrow={true}
                >
                  {renderReviewerStatus(quiz)}
                </Tooltip>
              </div>
            )}
          </td>
        </tr>
      )
    },
    [
      canEdit,
      checkedIds,
      getStatusText,
      hasPermission,
      onChangeQuizCheckbox,
      onClickExport,
      onClickStatus,
      renderIndicator,
      renderReviewerStatus,
      searchQuzzies,
      updateQuizWithUser,
    ],
  )

  const onInputChangedSearch = (value: string) => {
    refSearchTextInput.current = value
  }

  const onEnterSearch = async () => {
    await searchQuzzies()
  }

  const onSelectDate = async (item: DropDownItem) => {
    const value = item.value
    // console.log('onSelectDate', value)
    if (value === undefined) {
      // show all
      refStartDate.current = undefined
    } else if (value > 0) {
      const startDate = new Date()
      // caculate start date by value which is number of days
      startDate.setDate(startDate.getDate() - value)
      // console.log('startDate', startDate)
      refStartDate.current = startDate
    }
    await searchQuzzies()
  }

  const onSelectUpdatedBy = async (item: DropDownItem) => {
    refUpdatedBy.current = item.value
    await searchQuzzies()
  }

  const onSelectStatus = async (item: DropDownItem) => {
    const value = item.value
    // console.log('onSelectStatus', value)
    if (value === 0) {
      // select active, but not deleted
      setStatusFilter({
        draft: true,
        sent: true,
        reviewed: true,
        approved: true,
        deleted: false,
      })
      setShowActiveQuizzes(true)
    } else if (value === 1) {
      // select deleted
      setStatusFilter({
        draft: false,
        sent: false,
        reviewed: false,
        approved: false,
        deleted: true,
      })
      setShowActiveQuizzes(false)
    }
  }

  const onClickDelete = () => {
    if (checkedIds.length === 0) {
      ToastUtil.warning('Please select at least one quiz')
    } else {
      setIsClickDelete(true)
    }
  }

  const onConfirmDelete = async () => {
    setIsDeleting(true)
    await batchDeleteQuizzes(checkedIds)
    await searchQuzzies()
    setIsDeleting(false)
    ToastUtil.success(`Deleted successfully`)
    setCheckedIds([])
    setIsClickDelete(false)
  }

  const onClickRestore = async () => {
    if (checkedIds.length === 0) {
      ToastUtil.warning('Please select at least one quiz')
      return
    }
    setIsRestoring(true)
    await batchRestoreQuizzes(checkedIds)
    await searchQuzzies()
    setIsRestoring(false)
    ToastUtil.success(`Restored successfully`)
    setCheckedIds([])
  }

  const filterOptions = useMemo(() => {
    const userFilter = ['Draft', 'Sent', 'Reviewed', 'Approved']
    const adminFilter = [...userFilter, 'Deleted']
    return hasPermission(Permission.DeleteQuizzes) ? adminFilter : userFilter
  }, [hasPermission])

  const getCheckboxDisabled = (item: string): boolean => {
    if (showActiveQuizzes && item.toLowerCase() === 'deleted') {
      return true
    }
    if (!showActiveQuizzes && item.toLowerCase() !== 'deleted') {
      return true
    }
    return false
  }

  const getPercentageOfReviewed = (quiz: Quiz): number => {
    let { reviewers } = quiz
    reviewers = reviewers.filter((r) => r.version === quiz.version)
    if (reviewers && reviewers.length > 0) {
      const reviewedEmails = reviewers.filter((r) => r.reviewedAt)
      const reviewedCount = reviewedEmails.length
      const percentage = (reviewedCount / reviewers.length) * 100
      return percentage
    }
    return 0
  }

  useEffect(() => {
    fetchQuizzes()
  }, [fetchQuizzes])

  // detect scroll to bottom by page parent
  useEffect(() => {
    const parent = document.getElementById('manage-quiz-container')
      ?.parentElement?.parentElement?.parentElement
    if (parent) {
      parent.addEventListener('scroll', handleScroll)
      return () => {
        parent?.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll, sortedQuizzes])

  return (
    <div className="w-full" id="manage-quiz-container">
      {!isFetched && <Spinner />}
      {isFetched && (
        <>
          <div className="flex flex-row items-center">
            <div className="w-1/3">
              <SearchInput
                onInputChanged={onInputChangedSearch}
                onEnter={onEnterSearch}
                isSearching={isSearching}
                placeholder="Search quiz by name or key words"
              />
            </div>
            <div className="w-10" />
            <DropDownMenu
              items={[
                {
                  id: '0',
                  name: 'Date',
                  value: undefined,
                  isLabel: true,
                },
                { id: '1', name: 'Last 7 days', value: 7 },
                { id: '2', name: 'Last 30 days', value: 30 },
                { id: '3', name: 'Last 6 months', value: 183 },
                { id: '4', name: 'Show all', value: undefined },
              ]}
              onSelected={onSelectDate}
            />
            <div className="w-20" />
            <DropDownMenu
              items={updatedByOptions}
              onSelected={onSelectUpdatedBy}
            />
            <div className="grow" />
            <DropDownMenu
              items={[
                {
                  id: '0',
                  name: 'Show active quizzes',
                  value: 0,
                },
                { id: '1', name: 'Show deleted quizzes', value: 1 },
              ]}
              onSelected={onSelectStatus}
            />
          </div>
          <div className="flex flex-row items-center mt-4">
            <div className="flex flex-wrap">
              {filterOptions.map((item) => {
                const onChangeCheckbox = (item: string, val: boolean) => {
                  // disable to uncheck deleted if show deleted quizzes
                  if (!showActiveQuizzes && item.toLowerCase() === 'deleted') {
                    return
                  }

                  const newStatusFilter = {
                    ...statusFilter,
                    ...{ [item.toLowerCase()]: val },
                  }
                  setStatusFilter(newStatusFilter)
                }
                const onClickLabel = (e: React.MouseEvent<HTMLElement>) => {
                  try {
                    const target = e.target as HTMLElement
                    const input = target.parentElement
                      ?.children[0] as HTMLInputElement
                    // toggle checkbox
                    input.click()
                  } catch (e) {
                    console.error(e)
                  }
                }

                return (
                  <div
                    key={item + Math.random()}
                    className="mr-6 flex items-center"
                  >
                    <input
                      type="checkbox"
                      data-id={item + '-checkbox'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const target = e.target as HTMLInputElement
                        onChangeCheckbox(item, target.checked)
                      }}
                      checked={
                        statusFilter[item.toLowerCase() as keyof StatusOptions]
                      }
                      // className={getCheckboxStyle(item)}
                      disabled={getCheckboxDisabled(item)}
                    />
                    <div
                      className="w-[8px] cursor-pointer"
                      onClick={onClickLabel}
                    >
                      &nbsp;
                    </div>
                    <label
                      className="checkbox-label cursor-pointer"
                      onClick={onClickLabel}
                    >
                      {item}
                    </label>
                  </div>
                )
              })}
            </div>
            <div className="grow" />
            <button
              className={StyleUtil.buttonPrimary}
              onClick={() => {
                setShowCreateWidget(true)
              }}
            >
              <span className="pl-1 pr-1">Create new</span>
            </button>
          </div>
          {isFetched && sortedQuizzes.length === 0 && (
            <div className="flex flex-row items-center justify-start mt-8">
              <p className="quiz-no-quiz-found">No quiz found.</p>
            </div>
          )}
          {hasPermission(Permission.DeleteQuizzes) && (
            <div className="flex flex-row justify-start mt-4 h-[20px]">
              <div className="flex items-center">
                {isClickDelete && (
                  <>
                    <span className="mr-4 text-[16px]">Are you sure?</span>
                    <button
                      data-testid="btn-delete-from-quiz-yes"
                      onClick={onConfirmDelete}
                      className={StyleUtil.buttonPrimary}
                      disabled={isDeleting}
                    >
                      {isDeleting ? <ButtonSpinner /> : 'Yes'}
                    </button>
                    <span
                      data-testid="btn-delete-from-quiz-no"
                      onClick={() => {
                        setIsClickDelete(false)
                        return false
                      }}
                      className="ml-4 cursor-pointer text-[16px] text-blue-800 underline hover:opacity-[0.8]"
                    >
                      Cancel
                    </span>
                  </>
                )}
                {!isClickDelete && sortedQuizzes.length > 0 && (
                  <div className="flex flex-row gap-4 items-center">
                    {!isFilteredByDeleted() && (
                      <span
                        data-testid="btn-delete"
                        onClick={onClickDelete}
                        className="mentem-link-underline"
                      >
                        Delete quiz
                      </span>
                    )}
                    {!isRestoring && isFilteredByDeleted() && (
                      <span
                        data-testid="btn-restore"
                        onClick={onClickRestore}
                        className="quiz-restore-text"
                      >
                        Restore
                      </span>
                    )}
                    {isRestoring && <Spinner />}
                  </div>
                )}
              </div>
            </div>
          )}
          {isFetched && sortedQuizzes.length > 0 && (
            <div className="mt-6">
              <table className="mentem-table">
                <thead>
                  <tr>
                    <th
                      className={`${StyleUtil.stickyTableHeader1} h-[55px] w-[22%] min-w-[200px]`}
                    >
                      <div className="flex flex-row items-center px-2">
                        <span className="grow">Quiz name</span>
                        <img
                          className="cursor-pointer"
                          src={sortSvg}
                          alt="sort"
                          onClick={() => onClickSort(SortBy.Name)}
                          data-tooltip-id="tooltip-name"
                        />
                      </div>
                    </th>
                    <th
                      className={`${StyleUtil.stickyTableHeader2} w-[22%] min-w-[200px]`}
                    >
                      Description
                    </th>
                    <th
                      className={`${StyleUtil.stickyTableHeader1} w-[17%] min-w-[150px]`}
                    >
                      <div className="flex flex-row items-center px-2">
                        <span className="grow">Editor</span>
                        <img
                          className="cursor-pointer"
                          src={sortSvg}
                          alt="sort"
                          onClick={() => onClickSort(SortBy.UpdatedBy)}
                          data-tooltip-id="tooltip-updated-by"
                        />
                      </div>
                    </th>
                    <th
                      className={`${StyleUtil.stickyTableHeader2} w-[18%] min-w-[160px]`}
                    >
                      <div className="flex flex-row items-center px-2">
                        <span className="grow">Last updated</span>
                        <img
                          className="cursor-pointer pl-2 pr-2"
                          src={sort2Svg}
                          alt="sort"
                          onClick={() => onClickSort(SortBy.LastUpdated)}
                          data-tooltip-id="tooltip-last-updated"
                        />
                      </div>
                    </th>
                    <th
                      className={`${StyleUtil.stickyTableHeader1} w-[8%] min-w-[70px]`}
                    >
                      Export
                    </th>
                    <th
                      className={`${StyleUtil.stickyTableHeader2} w-[13%] min-w-[120px]`}
                    >
                      <div className="flex flex-row items-center px-2">
                        <span className="grow">Status</span>
                        <img
                          className="cursor-pointer pl-2 pr-2"
                          src={sort2Svg}
                          alt="sort"
                          onClick={() => onClickSort(SortBy.Status)}
                          data-tooltip-id="tooltip-status"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedQuizzes
                    .slice(0, Math.min(sortedQuizzes.length, renderRowCount))
                    .map((quiz: Quiz, index: number) => {
                      return renderRow(quiz, index)
                    })}
                </tbody>
              </table>
              <Tooltip
                id="tooltip-name"
                className="mentem-tooltip"
                style={getTooptipStyle(SortBy.Name)}
                place="top"
                noArrow={true}
              >
                {getSortingText(SortBy.Name)}
              </Tooltip>
              <Tooltip
                id="tooltip-updated-by"
                className="mentem-tooltip"
                style={getTooptipStyle(SortBy.UpdatedBy)}
                place="top"
                noArrow={true}
              >
                {getSortingText(SortBy.UpdatedBy)}
              </Tooltip>
              <Tooltip
                id="tooltip-last-updated"
                className="mentem-tooltip"
                style={getTooptipStyle(SortBy.LastUpdated)}
                place="top"
                noArrow={true}
              >
                {getSortingText(SortBy.LastUpdated)}
              </Tooltip>
              <Tooltip
                id="tooltip-status"
                className="mentem-tooltip"
                style={getTooptipStyle(SortBy.Status)}
                place="top"
                noArrow={true}
              >
                {getSortingText(SortBy.Status)}
              </Tooltip>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ManageQuizzes
